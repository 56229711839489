import React, { useState, useEffect } from "react";
import Login from "../components/Login";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../shared/components/Firebase";
import {
  withCurrentUser,
  withClaims,
} from "../../../shared/components/Session";
import { compose } from "recompose";
import NotificationManager from "react-notifications/lib/NotificationManager";

function LoginContainer(props) {
  const [loginCred, setLoginCred] = useState({
    customId: "",
    password: "",
  });
  const [showPass, setShowPass] = useState(false);
  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleInputChange = (e, inputType) => {
    loginCred[inputType] = e.target.value;

    setLoginCred({ ...loginCred });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const loginData = await ApiHandler.login(loginCred);
      setLoginCred({
        customId: "",
        password: "",
      });
      props.firebase.doSignInWithCustomToken(loginData.results);
    } catch (error) {
      NotificationManager.error(
        "Error loggin in, Please retry or check the credentials",
        "",
        2000
      );
    }
  };

  useEffect(() => {
    if (props.currentUser) {
      props.history.replace("/tool/inventory");
    }
  }, [props.currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (window.screen.width <= 1200 && props.claimsData.role === "admin") {
          return props.history.replace("/mobile/view/request/list");
        } else if (
          window.screen.width > 1200 &&
          props.claimsData.role === "admin"
        ) {
          return props.history.replace("/tool/inventory");
        } else if (props.claimsData.role === "supervisor") {
          return props.history.replace("/mobile/view/request/list");
        } else {
          return props.history.replace("/tool/inventory");
        }
      } catch (error) {}
    };

    if (props.claimsData) {
      fetchData();
    }
  }, [props.claimsData]);

  return (
    <Login
      loginCred={loginCred}
      handleInputChange={handleInputChange}
      handleSignIn={handleSignIn}
      showPass={showPass}
      handleShowPass={handleShowPass}
    />
  );
}

export default compose(
  withRouter,
  withFirebase,
  withCurrentUser,
  withClaims
)(LoginContainer);
