import React, { Fragment, useState } from "react";
import "../styles.scss";
import ProgressBar from "../../ProgressBar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import icons from "../../../assets/icons/Icons";
import DetailedRequestTable from "../containers/DetailedRequestTableContainer";
import DetailedRequestTable2 from "../containers/DetailedRequestTable2Container";
import DetailedRequestTable3 from "../containers/DetailedRequestTable3Container";
function DetailedRequestView({
  values,
  handleStatusSubmit,
  handleQuantityChange,
  apiResults,
  handleApproveClick,
  handleShipClick,
  data,
  status,
  returnData,
  handleAcceptReturn,
  handleReceivedClick,
  handleCompleteRequestClick,
  handleDescriptionChange,
  decription,
  handleAddNotes,
  activityLog,
  userRole,
  handleUpdateShipClick,
  appData,
  shipBtn,
  handleDeleteReq,
  handleBack,
  createPurchase,
  btnStatus,
  handleClose,
  handleShow,
  show,
  itemsDetails,
  editValId,
}) {
  return (
    <div className="detailed-request-view-outer-cover">
      <div className="detailed-request-view-inner-cover">
        <div className="detailed-request-opt-container">
          <div className="detailed-req-arrw-container">
            <div
              className="detailed-req-arrw-wrapper"
              onClick={() => handleBack()}
            >
              {icons.backArrow}
            </div>
          </div>

          {(userRole === "super_admin" || userRole === "admin") &&
            (status === "a_approved" || status === "rejected") && (
              <div
                className="req-del-btn-wrapper"
                onClick={() => {
                  window.confirm("Confirm delete request ?") &&
                    handleDeleteReq();
                }}
              >
                DELETE REQUEST
              </div>
            )}
        </div>

        {(status == "a_approval" ||
          status == "open" ||
          status === "request_completed") && (
          <DetailedRequestTable
            data={appData}
            status={status}
            userRole={userRole}
          />
        )}
        {(status === "a_approved" ||
          status === "shipped" ||
          status === "received") && (
          <DetailedRequestTable2
            data={data}
            status={status}
            userRole={userRole}
          />
        )}
        {(status === "return_requested" ||
          status === "return_approved" ||
          status === "return_initiated" ||
          status === "return_completed") && (
          <DetailedRequestTable3
            data={returnData}
            status={status}
            userRole={userRole}
          />
        )}
        <div className="approve-button-cover">
          {userRole === "admin" && status === "a_approval" && (
            <div
              onClick={(e) => {
                window.confirm("Confirm Approve Items?") &&
                  handleApproveClick(e);
              }}
              className="approve-button"
            >
              Approve Items
            </div>
          )}

          {userRole === "store" && status === "a_approved" && (
            <div>
              <div
                onClick={(e) => {
                  window.confirm("Confirm update?") && handleUpdateShipClick(e);
                }}
                className="update-button"
              >
                Update
              </div>
              {userRole === "store" &&
                status === "a_approved" &&
                shipBtn &&
                shipBtn.length > 0 && (
                  <div
                    onClick={(e) => {
                      window.confirm("Confirm Shipping?") && handleShipClick(e);
                    }}
                    className="ship-button"
                  >
                    Ship Items
                  </div>
                )}
            </div>
          )}
          {userRole === "store" && status === "return_requested" && (
            <div
              onClick={(e) => {
                window.confirm("Confirm Accept Return?") &&
                  handleAcceptReturn(e);
              }}
              className="approve-button"
            >
              Accept Return
            </div>
          )}
          {userRole === "store" && status === "return_initiated" && (
            <div
              onClick={(e) => {
                window.confirm("Confirm Received?") && handleReceivedClick(e);
              }}
              className="approve-button"
            >
              Complete Return Request
            </div>
          )}
          {userRole === "store" && status === "return_completed" && (
            <div
              onClick={(e) => {
                window.confirm("Confirm Complete Request?") &&
                  handleCompleteRequestClick(e);
              }}
              className="approve-button"
            >
              Complete Request
            </div>
          )}

          {userRole === "purchase" && status === "a_approved" && (
            <div className="create-purchase-btn">
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={handleShow}
              >
                Create Purchase order
              </button>
            </div>
          )}

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Approved Quantity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Approved Quantity</th>
                    <th>{editValId ? "Edit Approved Quantity" : ""}</th>
                  </tr>
                </thead>
                <tbody>{itemsDetails()}</tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={createPurchase}>
                Done
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="activity-notes-cover">
          <div className="activity">
            <ProgressBar data={activityLog} />
          </div>
          <div className="description-cover">
            <div className="header">Notes</div>
            <textarea
              style={{ resize: "none" }}
              className="profile-detail-input-textarea"
              name="comment"
              form="usrform"
              placeholder="Enter your notes here"
              onChange={(e) => {
                handleDescriptionChange(e.target.value);
              }}
              value={decription}
            ></textarea>

            <Button
              onClick={(e) => {
                handleAddNotes(e);
              }}
              type="submit"
              variant="outline-success"
              size="md"
              className="add"
              block
            >
              {" "}
              add
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailedRequestView;
