import React, { useState } from "react";
import MobApproved from "../components/MobApproved";

function MobApprovedContainer(props) {
  const [btn, setBtn] = useState(1);

  const handleBtn = (id) => {
    setBtn(id);
  };

  const handleReceiveStatus = async (data1, data2) => {
    await props.handleReceiveStatus(data1, data2);
  };

  return (
    <MobApproved
      btn={btn}
      handleBtn={handleBtn}
      reqProcessData={props.reqProcessData}
      loader={props.loader}
      handleReceiveStatus={handleReceiveStatus}
    />
  );
}

export default MobApprovedContainer;
