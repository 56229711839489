import Login from "./features/Login";
import sideNavBar from "./features/SideNavBar";
import DetailedView from "./features/ItemDetailedView";
import MobileDashboard from "./features/MobileView/MobileDashboard";

export const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/tool",
    component: sideNavBar,
  },

  {
    path: "/mobile/view",
    component: MobileDashboard,
  },
];
