import React from "react";
import "../styles.scss";
import UserDetails from "../containers/UserDetailsContainer";
import AddCompany from "../containers/AddCompanyContainer";
import InventoryDetails from "../containers/InventoryDetailsContainer";
import SupplierDetails from "../containers/SupplierDetailsContainer";
import SupplierList from "../containers/SupplierListContainer";
import PurchaseList from "../containers/PurchaseListContainer";

function Settings({ userRole }) {
  return (
    <div className="settings-outer-cover">
      <div className="settings-inner-cover">
        <div className="setting-title">SETTINGS</div>
        <div className="setting-data-wrapper">
          <UserDetails />
          <div className="user-detail-other-info-container">
            {userRole === "admin" && <AddCompany />}
            {(userRole === "store" || userRole === "purchase") && (
              <InventoryDetails />
            )}
            {/* {(userRole === "store" || userRole === "purchase") && (
              <SupplierDetails />
            )} */}
          </div>
          {(userRole === "store" || userRole === "purchase") && (
            <SupplierList />
          )}
          {userRole === "super_admin" && <PurchaseList />}
        </div>
      </div>
    </div>
  );
}

export default Settings;
