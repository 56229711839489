import React, { useState, useEffect } from "react";
import EmployeeView from "../components/EmployeeView";
import { useParams } from "react-router-dom";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import moment from "moment";
import { withRouter } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { compose } from "recompose";
import { withClaims } from "../../../shared/components/Session";
import Loader from "react-loader-spinner";

function EmployeeViewContainer(props) {
  const [modalShow, setModalShow] = useState(false);
  const [role, setRole] = useState(props.claimsData && props.claimsData.role);
  const [grantAccess, setGrantAccess] = useState("");
  const [values, setValues] = useState({
    employeeId: "",
    joinDate: "",
    name: "",
    jobRole: "",
    accessRole: "",
  });
  const [activity, setActivity] = useState("");
  const [status, setStatus] = useState(false);
  const [apiResults, setApiResults] = useState(null);
  const [createdAt, setCreatedAt] = useState("");
  const handleOpen = (status) => {
    setModalShow(status);
  };
  const handleClose = (status) => {
    setModalShow(status);
  };
  const handleBack = () => {
    props.history.replace("/tool/employees");
  };

  const handleChange = (changedValue, type) => {
    values[type] = changedValue;
    setValues({ ...values });
  };
  let { id } = useParams();

  const [grantValues, setGrantValues] = useState({
    password: "",
    role: "",
  });
  const handleGrantChange = (value, field) => {
    grantValues[field] = value;
    setGrantValues({ ...grantValues });
  };

  const submitGrantRequest = async (empID) => {
    const modifiedData = {
      customId: empID,
      password: grantValues.password,
      role: grantValues.role,
    };

    if (modifiedData.password.length > 4) {
      try {
        const response = await ApiHandler.grantAccessForTool(modifiedData);
        NotificationManager.success("Grant Access Successfully", "", 2000);
        setModalShow(false);
        setStatus(!status);
      } catch (error) {
        NotificationManager.error("Error loading data", "", 2000);
      }
    } else {
      NotificationManager.warning(
        "Password Must Contain Atleast 4 Characters",
        "",
        2000
      );
    }
  };
  const handleActivity = async () => {
    try {
      const response = await ApiHandler.PostActivity(id);
      setStatus(!status);
      NotificationManager.success(
        "Employee Status Changed To Inactive Successfully",
        "",
        2000
      );
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getIndividualEmployeeDetails(id);
      setApiResults(response.results);
      setGrantAccess(response.results.hasToolAccess);
      setActivity(response.results.isActive);
      setCreatedAt(moment(response.results.createdAt).format("MM/DD/YYYY"));

      const apiValues = response.results;
      const prefilledValues = {
        employeeId: apiValues.customId,
        joinDate: apiValues.doj
          ? moment(apiValues.doj).format("YYYY-MM-DD")
          : "-",
        name: apiValues.name,
        jobRole: apiValues.jobRole,
        accessRole: apiValues.role,
      };
      setValues(prefilledValues);
    };
    if (props.claimsData) fetchData();
  }, [props.claimsData, status]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await ApiHandler.postIndividualEmployeeDetails(id, {
        name: values.name,
        jobRole: values.jobRole,
        doj: values.joinDate,
      });
      NotificationManager.success("updated Successfully", "", 2000);
      props.history.replace("/tool/employees");
    } catch (error) {
      NotificationManager.error("Error loading data", "", 2000);
    }
  };

  return (
    <>
      {apiResults ? (
        <EmployeeView
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          modalShow={modalShow}
          handleOpen={handleOpen}
          handleClose={handleClose}
          activeUserRole={props.claimsData && props.claimsData.role}
          grantValues={grantValues}
          handleGrantChange={handleGrantChange}
          submitGrantRequest={submitGrantRequest}
          role={role}
          handleBack={handleBack}
          createdAt={createdAt}
          grantAccess={grantAccess}
          handleActivity={handleActivity}
          activity={activity}
        />
      ) : (
        <div className="loader-style">
          <Loader type="ThreeDots" color="#7764e4" height={100} width={100} />
        </div>
      )}
    </>
  );
}

export default compose(withRouter, withClaims)(EmployeeViewContainer);
