import React from "react";
import "../styles.scss";

function InventoryDetails({
  handleChange,
  inventoryData,
  addLocation,
  addUnit,
}) {
  return (
    <div className="user-detail-add-company-wrapper">
      <div className="user-detail-add-company-title">ADD INVENTORY DETAILS</div>
      <div className="user-details-add-company-body">
        <div className="user-details-add-company-container">
          <div className="user-details-add-company-name">
            Inventory Location
          </div>
          <input
            type="text"
            className="user-details-company-name-input"
            value={inventoryData.location}
            onChange={(e) => handleChange(e.target.value, "location")}
          />
          <div className="user-details-add-btn" onClick={() => addLocation()}>
            ADD
          </div>
        </div>
        <div className="user-details-add-company-container">
          <div className="user-details-add-company-name">Unit</div>
          <input
            type="text"
            className="user-details-company-name-input"
            value={inventoryData.unit}
            onChange={(e) => handleChange(e.target.value, "unit")}
          />
          <div className="user-details-add-btn" onClick={() => addUnit()}>
            ADD
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryDetails;
