import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";
import { Resource } from "../../../shared/constants/ResourceVariables";
import icons from "../../../assets/icons/Icons";
import ProgressBar from "../../ProgressBar";
import Select from "react-select";
import moment from "moment";

function ProjectsView({
  data,
  columns,
  options,
  values,
  handleChange,
  handleSubmit,
  handleFileSubmit,
  handleBack,
  handleFileDelete,
  header,
  handleAddNotes,
  projActivityLog,
  uploadStatus,
  poColumns,
  poData,
  manHoursData,
  manHoursColumns,
  customStyles,
  currentOptions,
  userRole,
  viewFile,
  poTotal,
  apiResults,
}) {
  return (
    <div className="projects-view-outer-cover">
      <div className="projects-view-inner-cover">
        <div className="item-inuse-detail-cover">
          <div className="item-name">
            <div
              onClick={(e) => handleBack(e)}
              className="detailed-po-topbar-arrw-wrapper"
            >
              {icons.backArrow}
            </div>
            <span style={{ paddingLeft: "10px" }}>
              {header.companyInfo.name} - {header.name} - {header.customId}
            </span>
          </div>
          <div className="projects-table-cover">
            <MUIDataTable
              title={"Item In Use Details"}
              data={data}
              columns={columns}
              options={options}
            />
          </div>

          <div className="progressbar-cover">
            <ProgressBar data={projActivityLog} />
          </div>
          <div className="projects-table-cover">
            <MUIDataTable
              title={
                <div className="pt-3">
                  <h6 style={{ fontSize: "1.25rem" }}>
                    Purchase Order Details
                  </h6>
                  <p>Total : {poTotal}</p>
                </div>
              }
              data={poData}
              columns={poColumns}
              options={options}
            />
          </div>
          <div className="projects-table-cover">
            <MUIDataTable
              title={"Man Hour Table"}
              data={manHoursData}
              columns={manHoursColumns}
              options={options}
            />
          </div>
        </div>
        <div className="project-detail-cover">
          <div className="project-header-details">ITEM FIELDS</div>
          <form className="form-project-detail-cover">
            <div className="fields-project-label-cover">
              <label className="profile-project-label" htmlFor="projectName">
                Project Name
              </label>

              <input
                className="profile-project-input"
                type="text"
                id="projectName"
                name="projectName"
                onChange={(e) => handleChange(e.target.value, "projectName")}
                value={values.projectName}
              />
            </div>
            <div className="fields-project-label-cover">
              <label
                className="profile-project-label"
                htmlFor="projectSupervisor"
              >
                Project Supervisor
              </label>

              <Select
                onChange={(selectedOption) => {
                  handleChange(selectedOption, "projectSupervisor");
                }}
                styles={customStyles}
                isMulti={true}
                options={currentOptions}
                value={values.projectSupervisor}
              />
            </div>

            <div className="fields-project-label-cover">
              <label className="profile-project-label" htmlFor="company">
                Company
              </label>

              <input
                className="profile-project-input"
                type="text"
                id="company"
                disabled
                name="company"
                onChange={(e) => handleChange(e.target.value, "company")}
                value={values.company}
              />
            </div>
            <div className="fields-project-label-cover">
              <label className="profile-project-label" htmlFor="scope">
                Project Scope
              </label>

              <textarea
                className="profile-project-input scope-text-area"
                type="text"
                id="scope"
                placeholder="Scope of the project..."
                name="scope"
                onChange={(e) => handleChange(e.target.value, "scope")}
                value={values.scope}
              />
            </div>
            <div className="fields-project-label-cover">
              <label className="profile-project-label" htmlFor="status">
                Status
              </label>

              <select
                style={{ width: "65%" }}
                onChange={(e) => {
                  handleChange(e.target.value, "status");
                }}
                value={values.status}
                required
                disabled={
                  apiResults.status === "inactive" ||
                  apiResults.status === "completed"
                    ? true
                    : false
                }
              >
                <option value="">Please Select...</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div className="date-project-label-cover">
              <label className="start-date-project-label" htmlFor="startDate">
                Start Date
              </label>

              <input
                className="start-date-project-input"
                type="date"
                id="startDate"
                name="startDate"
                onChange={(e) => handleChange(e.target.value, "startDate")}
                value={values.startDate}
              />
              <label className="start-date-project-label" htmlFor="endDate">
                End Date
              </label>

              <input
                className="start-date-project-input"
                type="date"
                id="endDate"
                name="endDate"
                onChange={(e) => handleChange(e.target.value, "endDate")}
                value={values.endDate}
              />
            </div>

            <div className="buttons-cover">
              {(userRole === "super_admin" || userRole === "admin") && (
                <div
                  className="update"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Update
                </div>
              )}
            </div>
          </form>
          <div className="notes-header">NOTES</div>
          <div className="description-cover">
            <textarea
              style={{ resize: "none" }}
              className="profile-detail-input-textarea"
              name="comment"
              form="usrform"
              placeholder="Enter your notes here"
              onChange={(e) => {
                handleChange(e.target.value, "description");
              }}
              value={values.description}
            ></textarea>
            <div className="add-buttons-cover">
              <div
                className="add"
                onClick={(e) => {
                  handleAddNotes(e);
                }}
              >
                ADD
              </div>
            </div>
          </div>
          <div className="document-header">DOCUMENTS</div>

          <div className="fields-button-project-label-cover">
            <label className="select-label" htmlFor="select">
              Upload Document
            </label>
            <input
              className="select"
              type="file"
              id="select"
              name="select"
              onChange={(e) => {
                handleChange(e.target.files[0], "file");
              }}
            />
            <div
              className="upload"
              onClick={(e) => {
                if (!uploadStatus) handleFileSubmit(e);
              }}
            >
              {uploadStatus === false ? "Upload" : "Uploading..."}
            </div>
          </div>
          <div className="all-documents-cover">
            <div className="document-list">
              {values.documents.length > 0 ? (
                values.documents.map((document) => {
                  return (
                    <div key={document._id} className="document-row">
                      <div className="document-ext">
                        {document.name.split(".")[1]}
                      </div>
                      <div className="document-name-date-wrapper">
                        <div>
                          <span
                            onClick={() => viewFile(document._id)}
                            style={{ color: "black", cursor: "pointer" }}
                            target="_blank"
                          >
                            {document.name}
                          </span>
                        </div>
                        <div>
                          {moment(document.createdAt).format("DD/MM/YYYY")}
                        </div>
                      </div>

                      <div className="doc-del-container">
                        <div
                          className="doc-del"
                          onClick={() => handleFileDelete(document._id)}
                        >
                          {icons.delete}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span style={{ color: "red" }}>
                  "No documents available,please upload"
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsView;
