const firebaseConfig = {
  apiKey: "AIzaSyAGU0C5bHZ0VB5WIf6-oQNhah6z8b4SBrA",
  authDomain: "nd-sons.firebaseapp.com",
  databaseURL: "https://nd-sons.firebaseio.com",
  projectId: "nd-sons",
  storageBucket: "nd-sons.appspot.com",
  messagingSenderId: "633604567560",
  appId: "1:633604567560:web:f715afaf3a7f08f15497bd",
  measurementId: "G-SBWWK9ECW4"
};
export default firebaseConfig;
