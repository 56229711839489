import React, { Fragment } from "react";
import "../styles.scss";
import { Table } from "react-bootstrap";
import MobileDashboard from "../../MobileDashboard";
import { withRouter, Link } from "react-router-dom";
import Loader from "../../Loader";

function MobileRequest({
  reqListData,
  childrenProps,
  handleClick,
  loader,
  yearOptions,
  year,
  handleInputChange,
}) {
  const statusField = {
    open: "Open",
    a_approval: "Sent for Approval",
    a_approved: "Admin Approved",
    rejected: "rejected",
    shipped: "shipped",
    received: "received",
    return_requested: "Sent for Return Request",
    return_approved: "Return Request Approved",
    return_initiated: "Supervisor initiated Return",
    return_completed: "Returned",
    request_completed: "Completed",
  };
  return (
    <Fragment>
      <div className="mob-req-container">
        {childrenProps}
        <div className="mob-req-btn-container">
          <Link
            to="/mobile/view/request/create"
            className="mob-req-btn"
            style={{ textDecoration: "none" }}
          >
            <div className="mob-req-btn">Create New Request</div>
          </Link>
        </div>
        <div className="mob-req-list-container">
          <div className="mob-req-year-container">
            <span
              style={{
                fontSize: "14px",
                paddingRight: "5px",
                fontWeight: "bold",
              }}
            >
              Select Financial Year:
            </span>
            <select
              style={{
                width: "100px",
                border: "1px solid black",
                textDecoration: "none",
              }}
              value={year}
              onChange={(e) => handleInputChange(e.target.value)}
            >
              <option></option>
              {yearOptions &&
                yearOptions.length > 0 &&
                yearOptions.map((year, i) => {
                  return (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="mob-req-header">Request List</div>
          <div className="mob-req-table-container">
            {loader ? (
              <Loader />
            ) : (
              <table>
                <tbody>
                  <tr className="mob-tab-header-wrapper">
                    <th className="mob-tab-header">Req No</th>
                    <th className="mob-tab-header">Project</th>
                    <th className="mob-tab-header">Supervisor</th>
                    <th className="mob-tab-header">Status</th>
                  </tr>

                  {reqListData &&
                    reqListData.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="mob-tab-data-container"
                          onClick={() => handleClick(data._id, data.status)}
                        >
                          <td className="mob-tab-data-style">
                            {data.customId ? data.customId : "N/A"}
                          </td>
                          <td className="mob-tab-data-style">
                            {data.projectInfo.name}
                          </td>
                          <td className="mob-tab-data-style">
                            {data.requestedBy.name}
                          </td>
                          <td className="mob-tab-data-style">
                            {statusField[data.status]}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(MobileRequest);
