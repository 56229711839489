import React, { useEffect, useState } from "react";
import SupplerList from "../components/SupplierList";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import icons from "../../../assets/icons/Icons";
import { NotificationManager } from "react-notifications";

function SupplierListContainer() {
  const [supList, setSupList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const columns = [
    "Supplier ID",
    "Suppler Name",
    "Supplier Address",
    "Edit",
    "Delete",
  ];
  const [refresh, setRefresh] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [supParams, setSupParams] = useState(null);

  const supData = {
    name: "",
    code: "",
    address: "",
  };

  const [supInfo, setSupInfo] = useState(supData);
  const [supId, setSupId] = useState(null);

  const deleteField = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "red",
            width: "25px",
            height: "25px",
            cursor: "pointer",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icons.delete}
        </div>
      </div>
    );
  };

  const editField = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "green",
            width: "25px",
            height: "25px",
            cursor: "pointer",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icons.edit}
        </div>
      </div>
    );
  };

  //delete the supplier
  const handleDelete = async (id) => {
    try {
      const del = await ApiHandler.deleteSupplier(id);
      NotificationManager.success("Deleted the supplier", "", 2000);
      setRefresh(!refresh);
    } catch (error) {
      NotificationManager.error(error, "", 2000);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await ApiHandler.supplierList();
        setSupList(data.results);
      } catch (error) {}
    };
    fetch();
  }, [refresh]);
  useEffect(() => {
    try {
      const data =
        supList &&
        supList.map((element, i) => [
          element.code,
          element.name,
          <span style={{ fontSize: "12px" }}>{element.address}</span>,
          editField(),

          deleteField(),
        ]);
      setTableData(data);
    } catch (error) {}
  }, [supList]);

  const options = {
    rowsPerPageOptions: [6, 5, 4],
    rowsPerPage: 5,
    selectableRows: false,
    onCellClick: (data, index) => {
      if (index.colIndex === 4) {
        window.confirm("Are you sure you want to delete this supplier ?") &&
          handleDelete(supList[index.dataIndex]._id);
      }
      if (index.colIndex === 3) {
        supInfo["name"] = supList[index.dataIndex].name;
        supInfo["code"] = supList[index.dataIndex].code;
        supInfo["address"] = supList[index.dataIndex].address;
        setSupId(supList[index.dataIndex]._id);
        setSupInfo({ ...supInfo });
        setModalShow(true);
        setSupParams("edit");
      }
    },
  };

  const handleModal = () => {
    setModalShow(false);
    setSupId(null);
    setSupInfo(supData);
    setSupParams(null);
  };

  const showModal = () => {
    setModalShow(true);
    setSupParams("add");
  };

  const handleChange = (type, value) => {
    supInfo[type] = value;
    setSupInfo({ ...supInfo });
  };

  const validate = () => {
    if (!supInfo.code) {
      NotificationManager.warning("Supplier code cannot be empty", "", 2000);
      return;
    } else if (!supInfo.name) {
      NotificationManager.warning("Supplier name cannot be empty", "", 2000);
      return;
    } else if (!supInfo.address) {
      NotificationManager.warning("Supplier Address cannot be empty", "", 2000);
      return;
    }
  };
  const handleEdit = async () => {
    try {
      validate();
      if (supParams) {
        if (supInfo.name && supInfo.code && supInfo.address) {
          if (supParams === "edit" && supId) {
            setModalShow(false);
            const sub = await ApiHandler.editSupplier(supId, supInfo);
            setRefresh(!refresh);
            setSupId(null);
            setSupInfo(supData);
            setSupParams(null);
            NotificationManager.success(sub.message, "", 2000);
            return;
          }
          if (supParams === "add") {
            setModalShow(false);
            const add = await ApiHandler.addSupplier(supInfo);
            setRefresh(!refresh);
            setSupId(null);
            setSupInfo(supData);
            setSupParams(null);

            NotificationManager.success(add.message, "", 2000);
            return;
          }
        }
      }
    } catch (error) {
      setModalShow(false);
      setSupId(null);
      setSupInfo(supData);
      setSupParams(null);

      NotificationManager.error(error, "", 2000);
    }
  };

  return (
    <SupplerList
      tableData={tableData}
      columns={columns}
      options={options}
      modalShow={modalShow}
      handleModal={handleModal}
      supInfo={supInfo}
      handleEdit={handleEdit}
      handleChange={handleChange}
      showModal={showModal}
    />
  );
}

export default SupplierListContainer;
