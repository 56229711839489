import React, { useState, useEffect, Fragment } from "react";
import MobileDashboard from "../components/MobileDashboard";
import { Route, withRouter, useRouteMatch, Link } from "react-router-dom";
import { compose } from "recompose";
import {
  withAuthorization,
  withClaims,
} from "../../../../shared/components/Session";
import MobileRequest from "../../MobileRequest";
import MobileCreateRequest from "../../MobileCreateRequest";
import MobileAddReq from "../../MobileAddReq";
import MobReqProcess from "../../MobReqProcess";
import MobileProject from "../../MobileProject";
import MobProjectAddHour from "../../MobProjectAddHour";
import MobileSetting from "../../MobileSetting";

function MobileDashboardContainer(props) {
  let mobReq = useRouteMatch("/mobile/view/request");
  let mobProject = useRouteMatch("/mobile/view/projects");
  let settingPath = useRouteMatch("/mobile/view/setting");

  const handleBackBtn = () => {
    if (mobReq) {
      props.history.replace("/mobile/view/request/list");
    }

    if (mobProject) {
      props.history.replace("/mobile/view/projects/info");
    }
    if (settingPath) {
      props.history.replace("/mobile/view/request/list");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.claimsData.role === "supervisor") {
          return props.history.replace("/mobile/view/request/list");
        } else if (
          window.screen.width < 1200 &&
          props.claimsData.role === "admin"
        ) {
          return props.history.replace("/mobile/view/request/list");
        } else {
          return props.history.replace("/tool/inventory");
        }
      } catch (error) {}
    };

    if (props.claimsData) {
      fetchData();
    }
  }, [props.claimsData]);

  const renderTopBar = <MobileDashboard handleBackBtn={handleBackBtn} />;

  return (
    <Fragment>
      <Route
        path="/mobile/view/request/list"
        render={(props) => (
          <MobileRequest {...props}>{renderTopBar}</MobileRequest>
        )}
      />
      <Route
        path="/mobile/view/request/create"
        render={(props) => (
          <MobileCreateRequest {...props}>{renderTopBar}</MobileCreateRequest>
        )}
      />
      <Route
        path="/mobile/view/request/add"
        render={(props) => (
          <MobileAddReq {...props}>{renderTopBar}</MobileAddReq>
        )}
      />
      <Route
        path="/mobile/view/request/process"
        render={(props) => (
          <MobReqProcess {...props}>{renderTopBar}</MobReqProcess>
        )}
      />
      <Route
        path="/mobile/view/projects/info"
        render={(props) => (
          <MobileProject {...props}>{renderTopBar}</MobileProject>
        )}
      />
      <Route
        path="/mobile/view/projects/add/hours"
        render={(props) => (
          <MobProjectAddHour {...props}>{renderTopBar}</MobProjectAddHour>
        )}
      />
      <Route
        path="/mobile/view/setting"
        render={(props) => (
          <MobileSetting {...props}>{renderTopBar}</MobileSetting>
        )}
      />
    </Fragment>
  );
}

const condition = (authUser) => authUser != null;

export default compose(
  withClaims,
  withRouter,
  withAuthorization(condition)
)(MobileDashboardContainer);
