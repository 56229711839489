import React, { useState, useEffect } from "react";
import MobActivity from "../components/MobActivity";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";

function MobActivityContainer(props) {
  const [mobActData, setMobActData] = useState([]);

  useEffect(() => {
    let clean = true;
    const fetchData = async () => {
      try {
        const data = await ApiHandler.reqActivity(props.requestId);
        if (clean) {
          setMobActData(data.results);
        }
      } catch (error) {}
    };
    fetchData();
    return () => (clean = false);
  }, [props.noteRefresh, props.refresh]);

  return <MobActivity mobActData={mobActData} />;
}

export default MobActivityContainer;
