import React from "react";
import "../styles.scss";
import icons from "../../../assets/icons/Icons";
import { withRouter } from "react-router-dom";

function TopBar(props) {
  const UserRole = {
    store: "Store Manager",
    admin: "Admin",
    super_admin: "Super Admin",
    purchase: "Purchase Manager",
  };
  return (
    <div className="topbar-outer-cover">
      <div
        className="icons-cover"
        onClick={() => {
          props.history.push("/tool/settings");
        }}
      >
        <div className="user-styles">{icons.userCircle}</div>
        <div className="topbar-user-info-wrapper">
          <div>{props.name}</div>
          <div className="role">{UserRole[props.role]}</div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TopBar);
