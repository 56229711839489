const images = {
  NdSonsLogo: require("../../assets/images/ndson.png"),
  dashboard: require("../../assets/images/dashboard.png"),
  inventory: require("../../assets/images/inventory.png"),
  requests: require("../../assets/images/requests.png"),
  purchase: require("../../assets/images/purchase.png"),
  projects: require("../../assets/images/projects.png"),
  employess: require("../../assets/images/employess.png"),
  settings: require("../../assets/images/settings.png"),
  loader: require("../../assets/images/loader.gif"),
  loader2: require("../../assets/images/loader2.gif"),
  offer: require("../../assets/images/offer.svg"),
  audit: require("../../assets/images/audit.svg"),
};
export default images;
