import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";

function PurchaseOrderTable({ orders, columns, options }) {
  return (
    <MUIDataTable
      data={orders}
      columns={columns}
      options={options}
      className="pointer"
    />
  );
}

export default PurchaseOrderTable;
