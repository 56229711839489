import React from "react";
import SelectSearch from "react-select-search";
import "../styles.scss";

function MobileCreateRequest({
  childrenProps,
  handleSubmit,
  projectList,
  projectData,
  handleChange,
}) {
  return (
    <div className="mob-create-req-container">
      {childrenProps}
      <div className="mob-create-req-wrapper">
        <div className="mob-req-title">Create New Request</div>
        <div className="mob-req-input-wrapper">
          <div className="mob-input-container">
            <div className="mob-input-txt">Project Name</div>
            <SelectSearch
              className="select-search-box"
              name="project"
              id="project"
              placeholder="Search Project"
              search
              onChange={({ value }) => handleChange(value, "projectname")}
              value={projectData.projectname}
              options={
                projectList &&
                projectList.map((proj, i) => {
                  return {
                    name: proj.name,
                    value: proj._id,
                  };
                })
              }
            />
          </div>
          <div className="mob-input-container">
            <div className="mob-input-txt">Expected Date</div>
            <input
              placeholder="Date"
              type="date"
              className="mob-input-style extra-style"
              onChange={(e) => handleChange(e.target.value, "date")}
            />
          </div>
          <div className="mob-req-submit-container">
            <div className="mob-req-sub-btn" onClick={() => handleSubmit()}>
              Create
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileCreateRequest;
