import React from "react";
import "../styles.scss";
import icons from "../../../assets/icons/Icons";
import moment from "moment";
import { Resource } from "../../../shared/constants/ResourceVariables";

function SMFile({
  handleChange,
  handleFileSubmit,
  fileToUpload,
  fileList,
  deleteFile,
  viewFile,
}) {
  return (
    <div className="sm-file-container">
      <div className="sm-file-title">DOCUMENTS</div>
      <div className="sm-file-body">
        <div className="sm-file-input-wrapper">
          <div className="sm-file-input-title">Upload Document</div>
          <input
            type="file"
            className="sm-file-input"
            onChange={(e) => handleChange(e.target.files[0])}
          />
          <div className="file-submit-btn" onClick={(e) => handleFileSubmit(e)}>
            Upload
          </div>
        </div>
        <div className="sm-file-list-wrapper">
          {fileList.documents &&
            fileList.documents.length > 0 &&
            fileList.documents.map((file, i) => {
              return (
                <div className="sm-file-list-container" key={i}>
                  <div className="sm-file-list-box">
                    {file.name.split(".")[1]}
                  </div>
                  <div className="sm-file-list-name-date-box">
                    <div className="sm-file-list-name">
                      <span
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={() => viewFile(file._id)}
                      >
                        {file.name}
                      </span>
                    </div>
                    <div className="sm-file-list-date">
                      {moment(file.date).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <div className="sm-file-list-delete-box">
                    <div
                      className="sm-file-list-delete"
                      onClick={() => deleteFile(file._id)}
                    >
                      {icons.delete}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default SMFile;
