import { Resource } from "../constants/ResourceVariables";
import { apiAuth } from "./AuthenticateApi";
import axios from "axios";

axios.defaults.baseURL = Resource.url.baseUrl;

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

//client
export const ApiHandler = {
  login: async function (loginCred) {
    try {
      const response = await apiAuth.postApiwithoutAuth(
        Resource.url.login,
        loginCred
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  supervisorListWithAccess: async () => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.supervisorListWithAccess
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //inventory
  getItemList: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.itemList);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  inventoryDetails: async function (modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.postInventoryDetails,
        modifiedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getItemdetailedView: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.ItemdetailedView(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getToolsInUse: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.toolsInUse(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postUpdate: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.update(id),
        modifiedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postNotes: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.notes(id),
        modifiedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getActivityLog: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.activityLog(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  itemListWithLessPayload: async () => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.itemListWithLessPayload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //unit
  getUnitList: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.unitList);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addUnit: async (data) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addUnit,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //supplier
  supplierList: async () => {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.supplierList);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addSupplier: async (data) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addSupplier,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteSupplier: async (id) => {
    try {
      const del = await apiAuth.deleteApiwithAuth(
        Resource.url.deleteSupplier(id)
      );
      return del.data;
    } catch (error) {
      throw error;
    }
  },

  editSupplier: async (id, data) => {
    try {
      const edit = await apiAuth.putApiwithAuth(
        Resource.url.editSupplier(id),
        data
      );
      return edit.data;
    } catch (error) {
      throw error;
    }
  },

  //employee

  totalActiveEmployees: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.totalActiveEmployees
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  totalActiveEmployeesWithAccess: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.totalActiveEmployeesWithAccess
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  setEmployeeGraph: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.employeeList);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  setEmployeeRoleGraph: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.employeeRoleList
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postEmployeeDetails: async function (modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.EmployeeDetails,
        modifiedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getEmployeeTableDetails: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.employeeTableDetails
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getIndividualEmployeeDetails: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.individualEmployeeDetails(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getSupervisorList: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.supervisorList
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  grantAccessForTool: async function (modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.grantAccess,
        modifiedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postIndividualEmployeeDetails: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.edittedEmployeeDetails(id),
        modifiedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  PostActivity: async function (id) {
    try {
      const response = await apiAuth.postApiwithAuth(Resource.url.activity(id));

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //company
  getCompanyList: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.companyList);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addCompany: async (addData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addCompany,
        addData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //inventory-location
  addInventoryLoc: async (data) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addInventoryLoc,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //project

  projectStatusList: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.projectStatusList
      );
      return response.data;
    } catch (error) {}
  },
  projectWorkhrsList: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.projectWorkhrsList
      );
      return response.data;
    } catch (error) {}
  },

  setProjectGraph: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.projectList);
      return response.data;
    } catch (error) {}
  },

  setProjectLocGraph: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.projectLoc);
      return response.data;
    } catch (error) {}
  },
  PostProjectDetails: async function (modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.ProjectDetails,
        modifiedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProjectDetails: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.ReceivedProjectDetails
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProjectFormDetails: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.ProjectFormDetails(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  postProjectDetailsUpdate: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.ProjectDetailsUpdate(id),
        modifiedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  supervisorProjectList: async (supId) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.supervisorProjectList(supId)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addHourToProject: async (projectId, hourData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addHourToProject(projectId),
        hourData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProjectItemsInUse: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.projectItemsInUse(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postProjectNotes: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.projectNotes(id),
        modifiedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProjectActivityLog: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.projectActivityLog(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poProjFileUpload: async (pid, formData) => {
    try {
      const response = await apiAuth.putApiwithAuth(
        Resource.url.projFileUpload(pid),
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poProjFileDelete: async (pid, docId) => {
    try {
      const response = await apiAuth.deleteApiwithAuth(
        Resource.url.ProjFileDelete(pid, docId)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProjectPo: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.projectPo(id));
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteManHour: async (projectId, logId) => {
    try {
      const response = await apiAuth.deleteApiwithAuth(
        Resource.url.deleteManHour(projectId, logId)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //Request

  setRequestGraph: async function () {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.reqStatics);
      return response.data;
    } catch (error) {}
  },

  getRequestList: async function (year) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.requestList(year)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getEachRequestList: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.eachRequestList(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  requestStatus: async (reqId, statusData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.requestStatus(reqId),
        statusData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postApprovedQuantity: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.approvedQuantity(id),
        modifiedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addRequest: async (requestdata) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addRequest,
        requestdata
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addRequestItem: async (reqId, ItemData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addRequestItem(reqId),
        ItemData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  changeApproveStatus: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.approveStatus(id),
        modifiedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getAssignTableData: async function (Id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.assignTableData(Id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  editRequestedItmQty: async (reqId, rowId, editQty) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.editRequestedItmQty(reqId, rowId),
        editQty
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postShippedQuantity: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.shippedQuantity(id),
        modifiedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postShipSuccess: async function (id) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.shipSuccess(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  supervisorRequestList: async (supId, year) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.supervisorRequestList(supId, year)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteReqItem: async (reqId, rowId) => {
    try {
      const response = await apiAuth.deleteApiwithAuth(
        Resource.url.deleteReqItem(reqId, rowId)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getReturnData: async function (id, status) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.returnData(id, status)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  reqSendStatus: async (reqId, statusData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.reqReceivedStatus(reqId),
        { receivedFrom: statusData }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postAcceptedReturnQuantity: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.acceptedReturnQuantity(id),
        modifiedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  reqItmReturnBySup: async (reqId, returnData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.reqItmReturnBySup(reqId),
        returnData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postReturnCompleted: async function (id) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.returnCompleted(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  reqNote: async (reqId, noteData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.reqNote(reqId),
        noteData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  reqActivity: async (reqId) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.reqActivity(reqId)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  reqReturnApprovedList: async (reqId) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.reqReturnApprovedList(reqId)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  reqReturnInitiate: async (reqId, returnData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.reqReturnInitiate(reqId),
        returnData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postRequestCompleted: async function (id) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.requestCompleted(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postRequestNotes: async function (id, modifiedData) {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.requestNotes(id),
        modifiedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getRequestActivityLog: async function (id) {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.requestActivityLog(id)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delReq: async (id) => {
    try {
      const response = await apiAuth.deleteApiwithAuth(Resource.url.delReq(id));
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  reqActionCount: async (role) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.reqActionCount(role)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //purchase order

  poItemsCost: async () => {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.poItemsCost);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  setPoGraph: async () => {
    try {
      const response = await apiAuth.getApiwithAuth(Resource.url.poList);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  setPoCostGraph: async () => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.purchaseCostList
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  purchaseOrderInfo: async (pid) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.purchaseOrderInfo(pid)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  purchaseOrderList: async (year) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.purchaseOrderList(year)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addPurchaseOrder: async (purchaseData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addPurchaseOrder,
        purchaseData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deletePurchaseOrder: async (pid) => {
    try {
      const response = await apiAuth.deleteApiwithAuth(
        Resource.url.deletePurchaseOrder(pid)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addPurchaseItem: async (pid, addItemData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.addPurchaseItem(pid),
        addItemData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  editPurchaseItmUnitPrice: async (pid, editData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.editPurchaseItmUnitPrice(pid),
        editData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  editReqPurchaseQuantity: async (id, editData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.editReqPurchaseQuantity(id),
        editData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  poStatus: async (pid, statusData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.poStatus(pid),
        statusData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poItemDelete: async (pid, rowId) => {
    try {
      const response = await apiAuth.deleteApiwithAuth(
        Resource.url.poItemDelete(pid, rowId)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poReceivedQtyEdit: async (pid, receivedData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.poReceivedQtyEdit(pid),
        receivedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poNotes: async (pid, poData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.poNotes(pid),
        poData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poActivity: async (pid) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.poActivity(pid)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poFileUpload: async (pid, formData) => {
    try {
      const response = await apiAuth.putApiwithAuth(
        Resource.url.poFileUpload(pid),
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poFileDelete: async (pid, docId) => {
    try {
      const response = await apiAuth.deleteApiwithAuth(
        Resource.url.poFileDelete(pid, docId)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poSupplierAdd: async (pid, data) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.poSupplierAdd(pid),
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poItemExport: async (pid) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.poItemExport(pid)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  poActionCount: async (role) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.poActionCount(role)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createOffer: async (offerData) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.createOffer,
        offerData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAllOffers: async (year) => {
    try {
      const response = await apiAuth.getApiwithoutAuth(
        Resource.url.getAllOffers(year)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  offerFileUpload: async (offerNum, formData) => {
    try {
      const response = await apiAuth.putApiwithoutAuth(
        Resource.url.offerFileUpload(offerNum),
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateRequest: async (reqId, data) => {
    try {
      const response = await apiAuth.putApiwithoutHeader(
        Resource.url.updateRequest(reqId),
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateDimension: async (reqId, data) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.updateDimension(reqId),
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  mergeRequestPo: async (poId, reqId) => {
    try {
      const response = await apiAuth.postApiwithAuth(
        Resource.url.mergeRequestPo(poId),
        reqId
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAllOrdersForAudit: async (fy) => {
    try {
      const response = await apiAuth.getApiwithAuth(
        Resource.url.getAllOrdersForAudit(fy)
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
