import React, { useEffect, useState } from "react";
import UserDetails from "../components/UserDetails";
import { withFirebase } from "../../../shared/components/Firebase";
import { withClaims } from "../../../shared/components/Session";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { compose } from "recompose";

function UserDetailsContainer(props) {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await ApiHandler.getIndividualEmployeeDetails(
        props.claimsData.employeeId
      );
      setUserData(data.results);
      try {
      } catch (error) {}
    };
    if (props.claimsData) {
      fetchData();
    }
  }, [props.claimsData]);

  const logout = async () => {
    await props.firebase.doSignOut();
  };
  return <UserDetails logout={logout} userData={userData} />;
}

export default compose(withFirebase, withClaims)(UserDetailsContainer);
