import React, { useState, useEffect } from "react";
import TopBar from "../components/TopBar";
import { withClaims } from "../../../shared/components/Session";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";

function TopBarContainer(props) {
  const [role, setRole] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (props.claimsData) {
      setRole(props.claimsData.role);
    }
  }, [props.claimsData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiHandler.getIndividualEmployeeDetails(
          props.claimsData.employeeId
        );
        setName(data.results.name);
      } catch (error) {}
    };
    if (props.claimsData) {
      fetchData();
    }
  }, [props.claimsData]);

  return <TopBar role={role} name={name} />;
}
export default withClaims(TopBarContainer);
