import React, { useState, useEffect, Fragment } from "react";
import Settings from "../components/Settings";
import { withFirebase } from "../../../shared/components/Firebase";
import { compose } from "recompose";
import { withClaims } from "../../../shared/components/Session";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";

function SettingsContainer(props) {
  const [settingData, setSettingData] = useState(null);

  const userRole = props.claimsData && props.claimsData.role;

  return (
    <Fragment>
      <Settings userRole={userRole} />
    </Fragment>
  );
}

export default compose(withFirebase, withClaims)(SettingsContainer);
