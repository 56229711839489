import React, { Fragment } from "react";
import Loader from "../../Loader";
import "../styles.scss";
import moment from "moment";

function MobReturn({ btn, handleBtn, loader, returnDataList, handleReturn }) {
  const store = [];
  const project = [];

  const statusField = {
    open: "Open",
    a_approval: "Sent for Approval",
    a_approved: "Admin Approved",
    rejected: "rejected",
    shipped: "shipped",
    return_requested: "Sent for Return Request",
    received: "received",
    return_approved: "Return Request Approved",
    return_initiated: "Supervisor initiated Return",
    return_completed: "Returned",
    request_completed: "Completed",
  };
  return (
    <div className="mob-approved-container">
      <div className="mob-approved-header-container">
        <div
          className={
            btn === 1 ? "mob-approved-header selected" : "mob-approved-header"
          }
          onClick={() => handleBtn(1)}
        >
          RETURN TO STORE
        </div>
        <div
          className={
            btn === 2
              ? "mob-approved-header mob-header-ext selected"
              : "mob-approved-header mob-header-ext"
          }
          onClick={() => handleBtn(2)}
        >
          RETURN TO OTHER PROJECT
        </div>
      </div>
      {btn === 1 && (
        <div className="mob-approved-data-list-container">
          {loader ? (
            <Loader />
          ) : (
            <table>
              <tbody>
                <tr className="mob-approved-data-table-title-container">
                  <th className="mob-approved-data-table-title width-lg">
                    ITEM NAME
                  </th>
                  <th className="mob-approved-data-table-title width-sm">
                    QTY
                  </th>
                </tr>
                {returnDataList.ongoingRequestData &&
                  returnDataList.ongoingRequestData.items &&
                  returnDataList.ongoingRequestData.items.length > 0 &&
                  returnDataList.ongoingRequestData.items.map((data, i) => {
                    if (data.toStore.quantity > 0) {
                      store.push("true");
                    }
                    if (data.toProjects && data.toProjects.length > 0) {
                      project.push("true");
                    }
                    return (
                      <tr className="mob-approved-table-data-container" key={i}>
                        {data.toStore.quantity > 0 ? (
                          <Fragment>
                            <td className="mob-approved-table-data">
                              {data.itemInfo.name}
                            </td>
                            <td className="mob-approved-table-data">
                              {data.toStore.quantity}
                            </td>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <td className="mob-approved-table-data">
                              {data.itemInfo.name}
                            </td>
                            <td className="mob-approved-table-data">
                              No return items
                            </td>
                          </Fragment>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      )}

      {btn === 2 && (
        <div className="mob-approved-data-list-container">
          <table>
            <tbody>
              <tr className="mob-approved-data-table-title-container">
                <th className="mob-approved-data-table-title width-sm">
                  ITEM NAME
                </th>

                <th className="mob-approved-data-table-title width-lg">
                  Project Name - Qty
                </th>
              </tr>
              {returnDataList.ongoingRequestData &&
                returnDataList.ongoingRequestData.items &&
                returnDataList.ongoingRequestData.items.length > 0 &&
                returnDataList.ongoingRequestData.items.map((data, i) => {
                  if (data.toProjects && data.toProjects.length > 0) {
                    project.push("true");
                  }
                  if (data.toStore.quantity > 0) {
                    store.push("true");
                  }
                  return (
                    <tr className="mob-approved-table-data-container" key={i}>
                      {data.toProjects && data.toProjects.length > 0 ? (
                        <Fragment>
                          <td className="mob-approved-table-data">
                            {data.itemInfo.name}
                          </td>
                          <td className="mob-approved-table-data">
                            {data.toProjects.map((prj, i) => {
                              return (
                                <div style={{ padding: "2px" }} key={i}>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      paddingRight: "2px",
                                    }}
                                  >
                                    {prj.projectInfo.name}
                                  </span>
                                  -->
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      paddingLeft: "2px",
                                    }}
                                  >
                                    {prj.quantity}
                                  </span>
                                </div>
                              );
                            })}
                          </td>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <td className="mob-approved-table-data">
                            No return items
                          </td>
                          <td className="mob-approved-table-data"></td>
                          <td className="mob-approved-table-data"></td>
                        </Fragment>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      <div className="mob-approved-staus-container">
        {((returnDataList.requestData &&
          returnDataList.requestData.status === "return_approved") ||
          (returnDataList.requestData &&
            returnDataList.requestData.status === "return_initiated") ||
          (returnDataList.requestData &&
            returnDataList.requestData.status === "return_completed") ||
          (returnDataList.requestData &&
            returnDataList.requestData.status === "request_completed")) && (
          <div className="mob-approved-status">
            Status:
            <span style={{ color: "#7764e4" }}>
              {
                statusField[
                  returnDataList.requestData &&
                    returnDataList.requestData.status
                ]
              }
            </span>
          </div>
        )}
        {((returnDataList.requestData &&
          returnDataList.requestData.status === "return_approved") ||
          (returnDataList.requestData &&
            returnDataList.requestData.status === "return_initiated")) && (
          <div className="mob-approved-date">
            Date:
            {moment(returnDataList.requestData.updatedAt).format("DD/MM/YYYY")}
          </div>
        )}
        <div className="mob-approved-btn-container">
          {btn === 1 &&
            returnDataList.requestData &&
            (returnDataList.requestData.status === "return_approved" ||
              returnDataList.requestData.status === "return_initiated") &&
            returnDataList.ongoingRequestData &&
            returnDataList.ongoingRequestData.returnedTo &&
            !returnDataList.ongoingRequestData.returnedTo.store &&
            store.length > 0 && (
              <div
                className="mob-approved-btn"
                onClick={() =>
                  window.confirm("Are u sure you want to return") &&
                  handleReturn(returnDataList.ongoingRequestData._id, "store")
                }
              >
                Return
              </div>
            )}
          {btn === 2 &&
            returnDataList.requestData &&
            (returnDataList.requestData.status === "return_approved" ||
              returnDataList.requestData.status === "return_initiated") &&
            returnDataList.ongoingRequestData &&
            returnDataList.ongoingRequestData.returnedTo &&
            !returnDataList.ongoingRequestData.returnedTo.project &&
            project.length > 0 && (
              <div
                className="mob-approved-btn"
                onClick={() =>
                  window.confirm("Are u sure you want to return") &&
                  handleReturn(returnDataList.ongoingRequestData._id, "project")
                }
              >
                Return
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
export default MobReturn;
