import React, { useState, useEffect, createContext } from "react";
import PurchaseOrder from "../components/PurchaseOrder";
// import PurchaseOrderContext from "../PurchaseOrderContext";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { withClaims } from "../../../shared/components/Session";

function PurchaseOrderContainer(props) {
  const [orders, setOrders] = useState([]);
  const [projects, setProjects] = useState([]);
  const [items, setItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [tableRefresh, setTableRefresh] = useState(false);

  let fy = new Date().getFullYear();
  let month = new Date().getMonth();
  let yearVal;
  if (month <= 2) {
    yearVal = fy;
  } else {
    yearVal = fy + 1;
  }
  const [yearOptions, setYearOptions] = useState(
    Array.from(new Array(5), (val, index) => yearVal - index)
  );

  const [year, setYear] = useState(yearOptions[0]);

  const handleYearChange = async (year) => {
    await setYear(year);
  };

  const userRole = props.claimsData && props.claimsData.role;
  const newOrderValuesObj = {
    projectId: "",
    employeeId: "",
  };
  const [newOrderValues, setNewOrderValues] = useState(newOrderValuesObj);
  useEffect(() => {
    const getAllPurchaseOrders = async () => {
      const response = await ApiHandler.purchaseOrderList(year);
      setOrders(response?.results ?? []);
    };
    if (year) {
      getAllPurchaseOrders();
    }
  }, [tableRefresh, year]);

  const handleInputChange = (newValue, type) => {
    newOrderValues[type] = newValue;
    setNewOrderValues({ ...newOrderValues });
  };

  const validate = () => {
    if (!newOrderValues.employeeId) {
      NotificationManager.warning("Please select the employee", "", 2000);
      return;
    }
    if (!newOrderValues.projectId) {
      NotificationManager.warning("Please select the project", "", 2000);
      return;
    }
  };

  const handleAddOrder = async () => {
    try {
      validate();
      if (newOrderValues.employeeId && newOrderValues.projectId) {
        const newOrderData = {
          purchasedFor: newOrderValues.employeeId,
          projectId: newOrderValues.projectId,
          createdBy: props.claimsData.employeeId,
        };
        const insertionResponse = await ApiHandler.addPurchaseOrder(
          newOrderData
        );
        setTableRefresh(!tableRefresh);
        setNewOrderValues(newOrderValuesObj);
        setModalShow(false);
        if (insertionResponse.status === "OK") {
          NotificationManager.success(
            "New purchase ordered created succesfully",
            "",
            2000
          );
        } else {
          NotificationManager.warning(insertionResponse.message, "", 2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const getDDData = async () => {
      try {
        const projectPromise = ApiHandler.getProjectDetails();
        const employeePromise = ApiHandler.getEmployeeTableDetails();

        const [projectResponse, employeeResponse] = await Promise.all([
          projectPromise,
          employeePromise,
        ]);
        setProjects(projectResponse.results);
        setEmployees(employeeResponse.results);
      } catch (error) {}
    };
    getDDData();
  }, []);
  const [modalShow, setModalShow] = useState(false);
  const handleOpen = (status) => {
    setModalShow(status);
  };
  const handleClose = (status) => {
    setModalShow(status);
    setNewOrderValues({ ...newOrderValuesObj });
  };

  return (
    // <PurchaseOrderContext.Provider value={orders}>
    <PurchaseOrder
      modalShow={modalShow}
      projects={projects}
      items={items}
      employees={employees}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleInputChange={handleInputChange}
      newOrderValues={newOrderValues}
      handleAddOrder={handleAddOrder}
      userRole={userRole}
      handleYearChange={handleYearChange}
      year={year}
      yearOptions={yearOptions}
      orders={orders}
    />
    // </PurchaseOrderContext.Provider>
  );
}

export default withClaims(PurchaseOrderContainer);
