import React from "react";
import "../styles.scss";

function MobNotes({ handleNote, note, sendNote }) {
  return (
    <div className="mob-notes-wrapper">
      <textarea
        placeholder="Add your notes here"
        className="mob-notes-textarea-wrapper"
        value={note}
        onChange={(e) => handleNote(e)}
      ></textarea>
      <div className="mob-notes-btn-container">
        <div className="mob-notes-btn" onClick={() => sendNote()}>
          ADD
        </div>
      </div>
    </div>
  );
}

export default MobNotes;
