import React, { useState, useEffect } from "react";
import MobileAddReq from "../components/MobileAddReq";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import icons from "../../../../assets/icons/Icons";

function MobileAddReqContainer(props) {
  const addDataVar = {
    itemName: "",
    qty: 0,
    description: "",
    type: "",
    itemId: "",
  };
  const [addData, setAddData] = useState(addDataVar);
  const [refresh, setRefresh] = useState(false);
  const requestId = props.location.state.RequestId;
  const [itemList, setItemList] = useState([]);
  const [itemDetails, setItemDetails] = useState("");

  const addDataListVar = {
    itemNameList: [],
    typeList: [],
    descriptionList: [],
  };

  const [addDataList, setAddDataList] = useState(addDataListVar);

  const handleChange = (value, type) => {
    if (type === "itemName") {
      const findData =
        itemList &&
        itemList.length > 0 &&
        itemList.filter((itm) => itm.name === value.name);

      addDataList["typeList"] = findData;
      addDataList["descriptionList"] = [];

      setItemDetails(findData[0]);
      setAddDataList({ ...addDataList });
      addData["itemName"] = value.value;
      addData["type"] = [];
      addData["description"] = [];
      addData["itemId"] = [];

      if (findData.length === 1 && !findData[0].type) {
        addData["itemId"] = findData[0]._id;
      }

      setAddData({ ...addData });
    } else if (type === "type") {
      const findData =
        addDataList.typeList &&
        addDataList.typeList.length > 0 &&
        addDataList.typeList.filter((itm) => itm.type === value.name);

      addData["type"] = value.value;
      addData["description"] = [];
      addData["itemId"] = [];

      addDataList["descriptionList"] = findData;
      addData["description"] = [];
      if (findData.length === 1) {
        addData["itemId"] = findData[0]._id;
      }
      setAddData({ ...addData });

      setAddDataList({ ...addDataList });
    } else if (type === "qty") {
      addData["qty"] = value.value;
      setAddData({ ...addData });
    } else if (type === "description") {
      addData["description"] = value.value;
      addData["itemId"] = value.value;
      setAddData({ ...addData });
    } else {
    }
  };

  const validate = () => {
    if (!addData.itemId) {
      NotificationManager.warning("Please select the Item", "", 2000);
      return;
    }
    if (!addData.qty) {
      NotificationManager.warning("Please enter the qunatity", "", 2000);
      return;
    }
  };

  const handleSubmit = async () => {
    validate();

    if (addData.itemId && addData.qty) {
      const itemReqData = {
        itemId: addData.itemId,
        quantity: addData.qty,
      };
      const req = await ApiHandler.addRequestItem(requestId, itemReqData);
      setRefresh(!refresh);
      setAddData(addDataVar);
      setAddDataList(addDataListVar);
      setItemDetails("");
      if (req.status === "OK") {
        NotificationManager.success(
          "Item added for the request list",
          "",
          2000
        );
      } else {
        NotificationManager.warning(req.message, "", 2000);
      }
    }
  };

  useEffect(() => {
    let clean = true;
    try {
      const itemListSets = new Set();
      const fetchData = async () => {
        const data = await ApiHandler.itemListWithLessPayload();
        if (clean) {
          setItemList(data.results);
          data.results &&
            data.results.length > 0 &&
            data.results.map((item, i) => {
              itemListSets.add(item.name);
            });

          let itemArry = Array.from(itemListSets);

          const filteredItmArry =
            itemArry &&
            itemArry.map((itm, i) => {
              return {
                itemName: itm,
                _id: (i + 1).toString(),
              };
            });
          addDataList["itemNameList"] = filteredItmArry;
          addDataList["typeList"] = [];
          addDataList["descriptionList"] = [];

          setAddDataList({ ...addDataList });
        }
      };
      fetchData();
      return () => {
        clean = false;
      };
    } catch (error) {
      throw error;
    }
  }, [refresh]);

  const handleDeleteReq = async () => {
    try {
      const del = await ApiHandler.delReq(requestId);
      if (del.status === "OK") {
        NotificationManager.success("Requested deleted", "", 2000);
        props.history.replace("/mobile/view/request/list");
      } else {
        NotificationManager.warning(del.message, "", 2000);
      }
    } catch (error) {}
  };

  const [dimension, setDimension] = useState({
    width: "",
    length: "",
    thickness: "",
    qty: "",
  });
  const { width, length, thickness, qty } = dimension;

  const handleDimension = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setDimension({ ...dimension, [name]: Number(value) });
  };

  const quantityField = () => {
    if (
      itemDetails.name !== "MS PLATE" &&
      itemDetails.name !== "SS PLATE" &&
      itemDetails.name !== "MS PLATE CHEQUERED" &&
      itemDetails.name !== "SS PLATE CHEQUERED"
    ) {
      return (
        <div style={{ display: "flex" }}>
          <input
            type="number"
            placeholder="Qty"
            className="mob-add-qty"
            value={addData.qty}
            onChange={(e) =>
              handleChange({ value: e.target.valueAsNumber }, "qty")
            }
          />
          <span className="mob-add-btn-container">
            <div className="mob-add-btn" onClick={() => handleSubmit()}>
              {icons.add}
            </div>
          </span>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex" }}>
          <input
            type="number"
            className="mob-add-qty"
            placeholder="Width"
            name="width"
            value={width || ""}
            onChange={(e) => handleDimension(e)}
          />

          <input
            type="number"
            placeholder="Length"
            className="mob-add-qty"
            name="length"
            value={length || ""}
            onChange={(e) => handleDimension(e)}
          />
          <input
            type="number"
            placeholder="Thickness"
            className="mob-add-qty"
            name="thickness"
            value={thickness || ""}
            onChange={(e) => handleDimension(e)}
          />
          <input
            type="number"
            placeholder="Quantity"
            className="mob-add-qty"
            name="qty"
            value={qty || ""}
            onChange={(e) => handleDimension(e)}
          />

          <span className="mob-add-btn-container">
            <div className="mob-add-btn" onClick={() => submitDimension()}>
              {icons.add}
            </div>
          </span>
        </div>
      );
    }
  };

  const dimensionValidate = () => {
    if (!addData.itemId) {
      NotificationManager.warning("Please select the Item", "", 2000);
      return;
    }
    if (!dimension.width) {
      NotificationManager.warning("Please enter the width", "", 2000);
      return;
    }
    if (!dimension.length) {
      NotificationManager.warning("Please enter the length", "", 2000);
      return;
    }
    if (!dimension.thickness) {
      NotificationManager.warning("Please enter the thickness", "", 2000);
      return;
    }
    if (!dimension.qty) {
      NotificationManager.warning("Please enter the qunatity", "", 2000);
      return;
    }
  };
  const submitDimension = async () => {
    const result =
      dimension.width *
      dimension.length *
      dimension.thickness *
      dimension.qty *
      8;
    let data = {
      itemId: addData.itemId,
      quantity: result,
      dimension,
    };
    dimensionValidate();
    if (
      addData.itemId &&
      dimension.qty &&
      dimension.length &&
      dimension.width &&
      dimension.thickness
    ) {
      const dimData = await ApiHandler.updateDimension(requestId, data);
      setRefresh(!refresh);
      setDimension({ width: "", length: "", thickness: "", qty: "" });
      setAddData(addDataVar);
      setAddDataList(addDataListVar);
      setItemDetails({});

      if (dimData.status === "OK") {
        NotificationManager.success("Dimension added successfully", "", 2000);
      } else {
        NotificationManager.warning("Error adding dimension", "", 2000);
      }
    }
  };

  return (
    <MobileAddReq
      childrenProps={props.children}
      itemList={itemList}
      handleChange={handleChange}
      addData={addData}
      handleSubmit={handleSubmit}
      requestId={requestId}
      refresh={refresh}
      handleDeleteReq={handleDeleteReq}
      quantityField={quantityField}
      addDataList={addDataList}
    />
  );
}

export default MobileAddReqContainer;
