import React from "react";
import "../styles.scss";

function SMNotes({ note, handleChange, handleNote }) {
  return (
    <div className="sm-notes-container">
      <div className="sm-notes-title">ADD NOTES</div>
      <div className="sm-notes-input-wrapper">
        <textarea
          type="textarea"
          className="sm-notes-input"
          placeholder="Add notes here..."
          value={note}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className="sm-notes-submit">
        <div className="sm-notes-btn" onClick={() => handleNote()}>
          ADD
        </div>
      </div>
    </div>
  );
}

export default SMNotes;
