import React from "react";
import "../styles.scss";

import MUIDataTable from "mui-datatables";

function InventoryConsumableTable({ columns, options, tableData, setReload }) {
  return (
    tableData && (
      <MUIDataTable columns={columns} options={options} data={tableData} className="mt-4 pointer"/>
    )
  );
}

export default InventoryConsumableTable;
