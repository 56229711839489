import React, { useState } from "react";
import MobNotes from "../components/MobNotes";
import NotificationManager from "react-notifications/lib/NotificationManager";

function MobNotesContainer(props) {
  const [note, setNote] = useState("");

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  const sendNote = async () => {
    if (note) {
      await props.noteSubmit(note);
      setNote("");
    } else {
      NotificationManager.warning("Notes can not be empty!!!", "", 2000);
    }
  };

  return <MobNotes handleNote={handleNote} note={note} sendNote={sendNote} />;
}

export default MobNotesContainer;
