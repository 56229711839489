import React from "react";
import "../styles.scss";
import moment from "moment";
import icons from "../../../../assets/icons/Icons";
import Loader from "../../Loader";

function MobProjectAddHour({
  childrensProps,
  projData,
  handleChange,
  handleSubmit,
  addData,
  loader,
  projectName,
  handleDelete,
}) {
  return (
    <div className="mob-proj-add-hrs-container">
      {childrensProps}
      <div className="mob-proj-add-hrs-project-name">{projectName}</div>
      <div className="mob-proj-add-hrs-wrapper">
        <div className="mob-proj-add-hrs-title">ADD MAN HOURS</div>

        <div className="mob-proj-add-hrs-input-wrapper">
          <div className="mob-proj-add-hrs-input-container">
            <div className="mob-proj-add-hrs-input-title">Date</div>
            <input
              type="date"
              placeholder="select date"
              className="mob-proj-add-hrs-input"
              value={addData.date}
              onChange={(e) => handleChange(e.target.value, "date")}
            />
          </div>
          <div className="mob-proj-add-hrs-input-container">
            <div className="mob-proj-add-hrs-input-title">Man Hours</div>
            <input
              type="number"
              placeholder="add man hours"
              className="mob-proj-add-hrs-input"
              value={addData.hrs}
              onChange={(e) => handleChange(e.target.valueAsNumber, "hrs")}
            />
          </div>
          <div className="mob-proj-add-hrs-input-container">
            <div className="mob-proj-add-hrs-input-title">OT Hours</div>
            <input
              type="number"
              placeholder="add man hours"
              className="mob-proj-add-hrs-input"
              value={addData.otHrs}
              onChange={(e) => handleChange(e.target.valueAsNumber, "otHrs")}
            />
          </div>
          <div className="mob-proj-add-hrs-btn-container">
            <div
              className="mob-proj-add-hrs-btn"
              onClick={() => handleSubmit()}
            >
              ADD
            </div>
          </div>
        </div>
      </div>
      <div className="mob-proj-man-hrs-list-container">
        <div className="mob-proj-hrs-list-header">
          <div className="mob-proj-list-header-style">List of man hours</div>
          <div className="mob-proj-list-header-style">
            Total man hrs: {projData && projData.totalHrs}
          </div>
        </div>

        <div className="mob-proj-list-data-container">
          {loader ? (
            <Loader />
          ) : (
            <table>
              <tbody>
                <tr className="mob-proj-tab-title-container">
                  <th className="mob-proj-tab-title width-lg">Date</th>
                  <th className="mob-proj-tab-title width-md">Hours</th>
                  <th className="mob-proj-tab-title width-md">OT Hours</th>
                  <th className="mob-proj-tab-title width-sm">Delete</th>
                </tr>
                {projData &&
                  projData.logs.map((element, i) => (
                    <tr className="mob-proj-tab-data-wrapper" key={i}>
                      <td className="mob-proj-tab-data">
                        {moment(element.date).format("DD/MM/YYYY")}
                      </td>
                      <td className="mob-proj-tab-data">{element.hrs}</td>
                      <td className="mob-proj-tab-data">
                        {element.otHrs ? element.otHrs : 0}
                      </td>
                      <td className="mob-proj-tab-data">
                        <div
                          className="mob-proj-tab-hrs-del"
                          onClick={() =>
                            window.confirm("Confirm Delete") &&
                            handleDelete(element._id)
                          }
                        >
                          {icons.delete}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobProjectAddHour;
