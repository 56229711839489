import React from "react";
import "../styles.scss";
import { useRouteMatch, withRouter, Link } from "react-router-dom";
import icons from "../../../../assets/icons/Icons";

function MobileDashboard(props) {
  let mobReq = useRouteMatch("/mobile/view/request");
  let mobProject = useRouteMatch("/mobile/view/projects");

  let reqPath = useRouteMatch("/mobile/view/request/list");
  let projectPath = useRouteMatch("/mobile/view/projects/info");
  let settingPath = useRouteMatch("/mobile/view/setting");

  return (
    <div className="mob-dash-container">
      <div className="mob-topbar-container">
        {reqPath || projectPath ? null : (
          <div
            className="mob-back-arw-icn"
            onClick={() => props.handleBackBtn()}
          >
            {icons.backArrowLg}
          </div>
        )}
        <div
          className="mob-icon-user"
          onClick={() => props.history.push("/mobile/view/setting")}
        >
          {icons.userCircle}
        </div>
      </div>
      {!settingPath && (
        <div className="mob-tab-container">
          <Link
            to="/mobile/view/request/list"
            style={{ textDecoration: "none", color: "none" }}
            className={mobReq ? "mob-tabs mob-tabs-selected" : "mob-tabs"}
          >
            <div className="mob-tabs">Requests</div>
          </Link>
          <Link
            to="/mobile/view/projects/info"
            style={{ textDecoration: "none", color: "none" }}
            className={mobProject ? "mob-tabs mob-tabs-selected" : "mob-tabs"}
          >
            <div className="mob-tabs">Projects</div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default withRouter(MobileDashboard);
