import React, { useEffect, useState } from "react";
import InventoryConsumableTable from "../components/InventoryConsumableTable";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";

function InventoryConsumableTableContainer(props) {
  const [tableData, setTableData] = useState(null);
  const [tableId, setTableId] = useState(null);
  const columns = [
    "Item Name",
    "Size",
    "Item Code",
    "Category",
    "Description",
    "Location",
    "In Stock",
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getItemList();
      setTableId(response.results);
      const tempTableData = [];
      response.results.map((element, index) => {
        const data = [
          element.name,
          element.type,
          element.code,
          element?.category ? element.category : "N/A",
          element.description,
          element.location,
          element.itemInStock,
        ];
        tempTableData.push(data);
      });
      setTableData(tempTableData);
    };
    fetchData();
  }, [props.status]);

  const options = {
    rowsPerPageOptions: [5, 4, 3],
    rowsPerPage: 5,
    onRowClick: (data, index) => {
      props.history.push(`/tool/itemview/${tableId[index.dataIndex]._id}`);
    },
    selectableRows: false,
  };
  return (
    <div>
      {tableData ? (
        <InventoryConsumableTable
          columns={columns}
          options={options}
          tableData={tableData}
        />
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="#7764e4"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </div>
      )}
    </div>
  );
}

export default compose(
  withRouter,
  withClaims
)(InventoryConsumableTableContainer);
