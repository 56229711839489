import React, { useEffect, useState } from "react";
import RequestTable from "../components/RequestTable";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Loader from "react-loader-spinner";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";
function RequestTableConatiner(props) {
  const [tableData, setTableData] = useState([]);
  const [apiLoad, setAPILoad] = useState(false);
  const [tableId, setTableId] = useState([]);

  const userRole = props.claimsData && props.claimsData.role;
  //const userRole = "store"; //["admin","super_admin",store]
  const columns = [
    "Request ID",
    "Supervisor",
    "Project",
    "Created Date",
    "Status",
  ];

  const statusView = (status) => {
    if (status == "a_approval") {
      return (
        <div className={userRole == "admin" ? "red" : "yellow"}>
          <b>New Request</b>
        </div>
      );
    } else if (status == "shipped") {
      return (
        <div className="yellow">
          <b>{status}</b>
        </div>
      );
    } else if (status == "open") {
      return (
        <div className="red">
          <b>{status}</b>
        </div>
      );
    } else if (status == "received") {
      return (
        <div className="green">
          <b>Delivered</b>
        </div>
      );
    } else if (status == "return_requested") {
      return (
        <div className={userRole == "store" ? "red" : "yellow"}>
          <b>New Return Request</b>
        </div>
      );
    } else if (status == "return_approved") {
      return (
        <div className={"yellow"}>
          <b>Return Request Processing</b>
        </div>
      );
    } else if (status == "return_initiated") {
      return (
        <div className="return-initiated">
          <b>Return Initiated</b>
        </div>
      );
    } else if (status == "return_completed") {
      return (
        <div className="green">
          <b>Return Request completed</b>
        </div>
      );
    } else if (status == "request_completed") {
      return (
        <div className="blue">
          <b> Request completed</b>
        </div>
      );
    } else if (status == "a_approved") {
      return (
        <div className={userRole == "store" ? "red" : "yellow"}>
          <b>Admin Approved</b>
        </div>
      );
    } else {
      return (
        <div className="grey">
          <b>Rejected</b>
        </div>
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getRequestList(Number(props.year));
      const data =
        response.results &&
        response.results.filter((data, i) => data.status != "open");
      setTableId(data);
      setAPILoad(true);
    };
    if (props.claimsData && props.year) {
      fetchData();
    }
  }, [props.claimsData, props.year]);

  useEffect(() => {
    const data =
      tableId &&
      tableId.map((req, i) => [
        req.customId,
        req.requestedBy.name,
        req.projectInfo.name,
        moment(req.createdAt).format("DD-MM-YYYY  hh:mm a"),
        statusView(req.status),
      ]);
    setTableData(data);
  }, [tableId]);

  const options = {
    rowsPerPageOptions: [6, 5, 4],
    rowsPerPage: 5,
    onRowClick: (data, index) => {
      props.history.push(`/tool/requestview/${tableId[index.dataIndex]._id}`);
    },
    selectableRows: false,
  };

  return (
    <div>
      {apiLoad ? (
        <RequestTable data={tableData} columns={columns} options={options} />
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="#7764e4"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </div>
      )}
    </div>
  );
}

export default compose(withRouter, withClaims)(RequestTableConatiner);
