import React, { Fragment } from "react";
import "../styles.scss";
import icons from "../../../../assets/icons/Icons";
import Modal from "react-bootstrap/Modal";
import SelectSearch from "react-select-search";
import Loader from "../../Loader";

function MobileAddTable({
  reqList,
  handleSubmit,
  handleShow,
  handleClose,
  show,
  editOption,
  handleChange,
  handleEdit,
  deleteItem,
  loader,
}) {
  return (
    <Fragment>
      <Modal show={show} onHide={handleClose} size="md" animation={true}>
        <Modal.Header
          style={{
            backgroundColor: "#7764E4",
            color: "white",
            fontFamily: "Poppins",
          }}
          closeButton
        >
          <Modal.Title>Edit Item Quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mob-modal-inp-container">
            <div className="mob-modal-inp-wrapper">
              <div className="mob-modal-title">Item Name</div>
              <input
                className="mob-modal-ip-cover"
                value={editOption.itemName}
                type="text"
                disabled
              />
            </div>
            <div className="mob-modal-inp-wrapper">
              <div className="mob-modal-title">Quantity</div>
              <input
                type="number"
                value={editOption.qty}
                onChange={(e) => handleChange(e.target.valueAsNumber, "qty")}
                className="mob-modal-ip-cover"
              />
            </div>
            <div className="mob-modal-sub-btn-container">
              <div className="mob-modal-sub-btn" onClick={() => handleEdit()}>
                Submit
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="mob-add-table-container">
        <div className="mob-add-table-header">ITEM LIST</div>
        <div className="mob-add-table-list-wrapper">
          {loader ? (
            <Loader />
          ) : (
            <table>
              <tbody>
                <tr className="mob-add-table-title">
                  <th className="mob-add-table-title-style">ITEM NAME</th>
                  <th className="mob-add-table-title-style">DESCRIPTION</th>
                  <th className="mob-add-table-title-style ">QTY</th>
                  <th className="mob-add-table-title-style ">EDIT</th>
                  <th className="mob-add-table-title-style ">DELETE</th>
                </tr>
                {reqList.items &&
                  reqList.items.length > 0 &&
                  reqList.items.map((data, i) => {
                    return (
                      <tr key={i} className="mob-add-data-container">
                        {!data.dimension ? (
                          <td className="mob-add-data-style">
                            {data.itemInfo.concatName}
                          </td>
                        ) : (
                          <td className="mob-add-data-style">
                            {data.itemInfo.concatName} &nbsp;
                            {data.dimension.width}&nbsp; x &nbsp;
                            {data.dimension.length}&nbsp; x &nbsp;
                            {data.dimension.thickness}&nbsp;--&nbsp;
                            {data.dimension.qty} Quantity
                          </td>
                        )}
                        <td className="mob-add-data-style">
                          {data?.itemInfo?.description}
                        </td>

                        <td className="mob-add-data-style">{data.quantity}</td>
                        <td className="mob-add-data-style">
                          <div
                            className="mob-add-edit-style"
                            onClick={() =>
                              handleShow(
                                data._id,
                                data.itemId,
                                data.itemInfo.concatName,
                                data.quantity
                              )
                            }
                          >
                            {icons.edit}
                          </div>
                        </td>
                        <td className="mob-add-data-style">
                          <div
                            className="mob-add-edit-style delete"
                            onClick={() =>
                              window.confirm(
                                "Are you sure you want to delete the item"
                              ) && deleteItem(data._id)
                            }
                          >
                            {icons.delete}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        {!loader &&
          reqList.items &&
          reqList.items.length > 0 &&
          reqList.status === "open" && (
            <div className="mob-add-submit-wrapper">
              <div
                className="mob-add-sub-btn"
                onClick={() =>
                  window.confirm(
                    "Are you sure you want to send for request approval"
                  ) && handleSubmit()
                }
              >
                Request item
              </div>
            </div>
          )}
      </div>
    </Fragment>
  );
}

export default MobileAddTable;
