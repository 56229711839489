import React, { useState, useEffect } from "react";
import ProjectTable from "../components/ProjectTable";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { withRouter } from "react-router-dom";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";
import Loader from "react-loader-spinner";

function ProjectTableContainer(props) {
  const columns = [
    "Project ID",
    "Project Name",
    "Company",
    "Supervisor",
    "Location",
    "Man Hours",
  ];
  const [tableData, setTableData] = useState([]);
  const [tableId, setTableId] = useState(null);
  const [apiLoad, setAPILoad] = useState(false);
  const options = {
    rowsPerPageOptions: [6, 5, 4],
    rowsPerPage: 5,
    onRowClick: (data, index) => {
      props.history.push(`/tool/projectsview/${tableId[index.dataIndex]._id}`);
    },
    selectableRows: false,
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getProjectDetails();
      setTableId(response.results);
      const tempTableData = [];
      response.results.map((proj, index) => {
        const supervisorList = proj.supervisorInfo.map((element) => {
            return element.name;
          }),
          data = [
            proj.customId,
            proj.name,
            proj.companyInfo.name,
            supervisorList.join(),
            proj.location,
            proj.work.totalHrs,
          ];
        tempTableData.push(data);
      });
      setTableData(tempTableData);
      setAPILoad(true);
    };
    if (props.claimsData) {
      fetchData();
    }
  }, [props.reload, props.claimsData]);

  return (
    <div>
      {apiLoad ? (
        <ProjectTable columns={columns} data={tableData} options={options} />
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="#7764e4"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </div>
      )}
    </div>
  );
}

export default compose(withRouter, withClaims)(ProjectTableContainer);
