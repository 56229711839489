import React, { useEffect, useState } from "react";
import Dashboard from "../components/Dashboard";
import { compose } from "recompose";
import {
  withAuthorization,
  withClaims,
} from "../../../shared/components/Session";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import Employees from "../../Employees/components/Employees";

function DashboardContainer(props) {
  const [purchase, setPurchase] = useState([]);
  const [purchaseCost, setPurchaseCost] = useState([]);
  const [request, setRequest] = useState([]);
  const [project, setProject] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [employeeRole, setEmployeeRole] = useState([]);
  const [projWorkHrs, setProjWorkHrs] = useState({});
  const [totalActiveEmp, setTotalActiveEmp] = useState({
    emp: 0,
    activeEmp: 0,
  });

  const [projectStatus, setProjectStatus] = useState({
    active: [],
    completed: [],
  });

  const [projectItemCost, setProjectItemCost] = useState([]);

  //purchase
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await ApiHandler.setPoGraph();
        const response2 = await ApiHandler.setPoCostGraph();
        const response3 = await ApiHandler.poItemsCost();
        Promise.all([response1, response2, response3]).then((res) => {
          setPurchase(res[0].results);
          setPurchaseCost(res[1].results);

          res[2].results &&
            res[2].results.length > 0 &&
            res[2].results.map((res, i) => {
              let value = {};
              value["name"] = res._id.projectName;
              res.itemCats.length > 0 &&
                res.itemCats.map((data, i) => {
                  if (data.itemCat === "MATERIAL") {
                    value["MATERIAL"] = data.totalCatCost;
                  } else if (data.itemCat === "CONSUMABLE") {
                    value["CONSUMABLE"] = data.totalCatCost;
                  } else {
                  }
                });
              projectItemCost.push(value);
            });
          setProjectItemCost([...projectItemCost]);
        });
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, []);

  //Project
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await ApiHandler.setProjectGraph();
        const response2 = await ApiHandler.projectStatusList();
        const response3 = await ApiHandler.projectWorkhrsList();
        Promise.all([response1, response2, response3]).then((res) => {
          setProject(res[0].results);
          setProjWorkHrs(res[2].results);

          res[1].results &&
            res[1].results.length > 0 &&
            res[1].results.map((proj) => {
              if (proj.status === "active") {
                projectStatus["active"].push(proj.name);
              } else if (proj.status === "completed") {
                projectStatus["completed"].push(proj.name);
              } else {
              }
            });
          setProjectStatus({ ...projectStatus });
        });
      } catch (error) {}
    };
    fetchData();
  }, []);

  //Employee
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await ApiHandler.setEmployeeGraph();
        const response2 = await ApiHandler.setEmployeeRoleGraph();
        const response3 = await ApiHandler.totalActiveEmployees();
        const response4 = await ApiHandler.totalActiveEmployeesWithAccess();

        Promise.all([response1, response2, response3, response4]).then(
          (res) => {
            setEmployee(res[0].results);
            setEmployeeRole(res[1].results);
            totalActiveEmp["emp"] = res[2].results;
            totalActiveEmp["activeEmp"] = res[3].results;
            setTotalActiveEmp({ ...totalActiveEmp });
          }
        );
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiHandler.setRequestGraph();
        setRequest(response.results);
      } catch (error) {}
    };
    fetchData();
  }, []);

  //graph for request made per month

  const reqGraphData = {
    open: [],
    reject: [],
    request_completed: [],
    a_approval: [],
    a_approved: [],
    shipped: [],
    received: [],
    return_requested: [],
    return_completed: [],
    return_initiated: [],
    return_approved: [],
  };
  const reqGraphDate = [];
  request &&
    request.length > 0 &&
    request.map((req, i) => {
      reqGraphDate.push(`${req._id.month}-${req._id.year}`);
      req &&
        req.status.map((status, k) => {
          reqGraphData[status.name][i] = status.count;
        });
    });
  const req_data = {
    labels: reqGraphDate,
    barPercentage: 0.5,
    minBarLength: 5,

    datasets: [
      {
        label: "SENT FOR ADMIN APPROVAL",
        data: reqGraphData.a_approval,
        backgroundColor: "#00a3a6",
      },
      {
        label: "ADMIN APPROVED",
        data: reqGraphData.a_approved,
        backgroundColor: "#ffa500",
      },
      {
        label: "REJECTED",
        data: reqGraphData.reject,
        backgroundColor: "#ff6347",
      },
      {
        label: "OPEN",
        data: reqGraphData.open,
        backgroundColor: "#30BC74",
      },
      {
        label: "SHIPPED",
        data: reqGraphData.shipped,
        backgroundColor: "#6644F3",
      },
      {
        label: "RECEIVED",
        data: reqGraphData.received,
        backgroundColor: "#C7ECD9",
      },
      {
        label: "RETURN REQUESTED",
        data: reqGraphData.return_requested,
        backgroundColor: "#B3ACE5",
      },
      {
        label: "RETURN REQUEST APPROVED",
        data: reqGraphData.return_approved,
        backgroundColor: "#A6CEE3",
      },
      {
        label: "SUPERVISOR RETURN INITIATED",
        data: reqGraphData.return_initiated,
        backgroundColor: "#844462",
      },
      {
        label: "RETURN REQUEST COMPLETED",
        data: reqGraphData.return_completed,
        backgroundColor: "#55E6C1",
      },
      {
        label: "REQUEST COMPLETED",
        data: reqGraphData.request_completed,
        backgroundColor: "#CAD3C8",
      },
    ],
  };
  const req_option = {
    title: {
      display: true,
      text: "Number of Requests per month",
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };

  //graph for purchase made per month

  const total_purchase = [];
  const date_purchase = [];

  purchase &&
    purchase.length > 0 &&
    purchase.map((x, id) => {
      date_purchase.push(`${x._id.month}-${x._id.year}`);
      total_purchase.push(x.total_purchase);
    });

  const purchase_data = {
    labels: date_purchase,
    datasets: [
      {
        data: total_purchase,
        backgroundColor: "#30BC74",
        barPercentage: 0.5,
        minBarLength: 5,
      },
    ],
  };

  const purchase_option = {
    title: {
      display: true,
      text: "Total Delivered Purchase Orders per month",
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };

  const total_purchase_cost = [];
  const date_purchase_cost = [];

  purchaseCost &&
    purchaseCost.length > 0 &&
    purchaseCost.map((x, id) => {
      date_purchase_cost.push(`${x._id.month}-${x._id.year}`);
      total_purchase_cost.push((x.totalCost / 100000).toFixed(2));
    });

  const purchase_sample_data = {
    labels: date_purchase_cost,

    datasets: [
      {
        data: total_purchase_cost,
        backgroundColor: "#30BC74",
        barPercentage: 0.5,
        minBarLength: 5,
      },
    ],
  };

  const purchase_sample_option = {
    title: {
      display: true,
      text: "Total Delivered Purchase Order cost per month in lakhs",
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };
  //graph for projects made per month

  const total_project = [];
  const date_project = [];

  project &&
    project.length > 0 &&
    project.map((x, id) => {
      date_project.push(`${x._id.month}-${x._id.year}`);
      total_project.push(x.totalWrkPerMonth);
    });
  const project_data = {
    labels: date_project,
    datasets: [
      {
        data: total_project,
        backgroundColor: [
          "#7764E4",
          "#6491E4",
          "#DEDEDE",
          "#172B4D",
          "#1F78B4",
          "#A6CEE3",
        ],
      },
    ],
  };

  const project_option = {
    title: {
      display: true,
      text: "Over all man hours of Projects per month in hours",
    },
    legend: {
      display: false,
    },
  };

  // graph for employee

  const employee_id = [];
  const total_employees = [];

  employeeRole &&
    employeeRole.length > 0 &&
    employeeRole.map((x, id) => {
      employee_id.push(x._id);
      total_employees.push(x.total_employees);
    });
  const employee_role_data = {
    labels: employee_id,
    datasets: [
      {
        data: total_employees,
        backgroundColor: [
          "#7764E4",
          "#50F3FF",
          "#1F78B4",
          "#A6CEE3",
          "#30BC74",
          "#C7ECD9",
          "#B3ACE5",
          "#6491E4",
          "#172B4D",
        ],
      },
    ],
  };

  const employee_role_option = {
    title: {
      display: true,
      text: "No of Employees with Job Role",
    },
    legend: {
      display: false,
    },
  };

  const employee_type = [];
  const no_of_employees = [];

  employee &&
    employee.length > 0 &&
    employee.map((x, id) => {
      employee_type.push(x._id);
      no_of_employees.push(x.number_of_employees);
    });
  const employee_data = {
    labels: employee_type,
    datasets: [
      {
        data: no_of_employees,
        backgroundColor: [
          "#7764E4",
          "#1F78B4",
          "#A6CEE3",
          "#30BC74",
          "#C7ECD9",
          "#B3ACE5",
        ],
      },
    ],
  };

  const employee_option = {
    title: {
      display: true,
      text: "No of Employees with Access Role",
    },
    legend: {
      display: false,
    },
  };

  return (
    <Dashboard
      purchase_data={purchase_data}
      purchase_option={purchase_option}
      req_data={req_data}
      req_option={req_option}
      project_data={project_data}
      project_option={project_option}
      employee_data={employee_data}
      employee_option={employee_option}
      employee_role_data={employee_role_data}
      employee_role_option={employee_role_option}
      purchase_sample_data={purchase_sample_data}
      purchase_sample_option={purchase_sample_option}
      totalActiveEmp={totalActiveEmp}
      projectStatus={projectStatus}
      projectItemCost={projectItemCost}
      projWorkHrs={projWorkHrs}
    />
  );
}
const condition = (authUser) => authUser != null;
export default compose(
  withClaims,
  withAuthorization(condition)
)(DashboardContainer);
