import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";

function DetailedRequestTable3({ columns, data, options }) {
  return (
    <MUIDataTable
      data={data}
      columns={columns}
      options={options}
      title={"Item List"}
    />
  );
}

export default DetailedRequestTable3;
