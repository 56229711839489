import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";
import Button from "react-bootstrap/Button";
import ProgressBar from "../../ProgressBar";
import SelectSearch from "react-select-search";
import icons from "../../../assets/icons/Icons";
import { defaultProps } from "recompose";

function DetailedView({
  tableData,
  category,
  columns,
  options,
  values,
  handleChange,
  handleSubmit,
  handleBack,
  concatName,
  toolsInUse,
  handleAddNotes,
  activityLog,
  status,
  userRole,
  units,
}) {
  return (
    <div className="inventory-detailed-view-outer-cover">
      <div className="inventory-detailed-view-inner-cover">
        <div className="item-inuse-detail-cover">
          <div className="item-name">
            <div
              onClick={() => handleBack()}
              className="detailed-po-topbar-arrw-wrapper"
            >
              {icons.backArrow}
            </div>
            <span style={{ paddingLeft: "10px" }}>{concatName} Details</span>
          </div>
          {category === "MACHINE" || category === "TOOLS" ? (
            <div className="table-cover">
              <MUIDataTable
                title={
                  <span>
                    Tools in use:{" "}
                    <span style={{ color: "green", fontSize: "14px" }}>
                      {toolsInUse}
                    </span>
                  </span>
                }
                data={tableData.length ? tableData : []}
                columns={columns}
                options={options}
              />
            </div>
          ) : null}

          <div className="progressbar-cover">
            <ProgressBar data={activityLog} status={status} />
          </div>
        </div>
        <div className="item-detail-cover">
          <div className="header-details">Item Details</div>
          <form className="form-detail-cover">
            <div className="fields-detail-label-cover">
              <label className="profile-detail-label" htmlFor="toolsInStock">
                Items In Stock
              </label>
              {userRole === "admin" ? (
                <input
                  className="profile-detail-input"
                  type="text"
                  id="toolsInStock"
                  name="toolsInStock"
                  onChange={(e) => {
                    handleChange(e.target.value, "toolsInStock");
                  }}
                  value={values.toolsInStock}
                />
              ) : (
                <input
                  className="profile-detail-input"
                  type="text"
                  id="toolsInStock"
                  name="toolsInStock"
                  onChange={(e) => {
                    handleChange(e.target.value, "toolsInStock");
                  }}
                  value={values.toolsInStock}
                  disabled
                />
              )}
            </div>

            <div className="fields-detail-label-cover">
              <label className="profile-detail-label" htmlFor="category">
                Category
              </label>

              <select
                className="profile-detail-input"
                name="category"
                id="category"
                onChange={(e) => {
                  handleChange(e.target.value, "category");
                }}
                value={values?.category ? values.category : "no select"}
                required
              >
                <option value="">select</option>
                <option value="MATERIAL">MATERIAL</option>
                <option value="MACHINE">MACHINE</option>
                <option value="TOOLS">TOOLS</option>
                <option value="CONSUMABLE">CONSUMABLE</option>
              </select>
            </div>
            <div className="fields-detail-label-cover">
              <label className="profile-detail-label" htmlFor="itemName">
                Item Name
              </label>

              <input
                className="profile-detail-input"
                type="text"
                id="itemName"
                name="itemName"
                onChange={(e) => {
                  handleChange(e.target.value, "itemName");
                }}
                value={values.itemName}
              />
            </div>
            <div className="fields-detail-label-cover">
              <label className="profile-detail-label" htmlFor="itemType">
                Item Type/size
              </label>

              <input
                className="profile-detail-input"
                type="text"
                id="itemType"
                name="itemType"
                onChange={(e) => {
                  handleChange(e.target.value, "itemType");
                }}
                value={values.itemType ? values.itemType : "NA"}
                disabled
              />
            </div>
            <div className="fields-detail-label-cover">
              <label className="profile-detail-label" htmlFor="itemCode">
                Item Code
              </label>

              <input
                className="profile-detail-input"
                type="text"
                id="itemCode"
                name="itemCode"
                onChange={(e) => {
                  handleChange(e.target.value, "itemCode");
                }}
                value={values.itemCode}
                disabled
              />
            </div>
            <div className="fields-detail-label-cover">
              <label className="profile-detail-label" htmlFor="itemCode">
                Description
              </label>

              <input
                className="profile-detail-input"
                type="text"
                id="Description"
                name="Description"
                onChange={(e) => {
                  handleChange(e.target.value, "description");
                }}
                value={values.description}
              />
            </div>

            <div className="fields-detail-label-cover">
              <label className="profile-detail-label" htmlFor="location">
                Location
              </label>

              {userRole === "admin" ? (
                <input
                  className="profile-detail-input"
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Please enter"
                  disabled
                  onChange={(e) => {
                    handleChange(e.target.value, "location");
                  }}
                  value={values.location}
                />
              ) : (
                <input
                  className="profile-detail-input"
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Please enter"
                  onChange={(e) => {
                    handleChange(e.target.value, "location");
                  }}
                  value={values.location}
                />
              )}
            </div>
            <div className="fields-detail-label-cover">
              <label className="profile-detail-label" htmlFor="unit">
                Unit
              </label>

              <SelectSearch
                className="select-search-box"
                name="units"
                placeholder="Search"
                id="units"
                onChange={({ value }) => {
                  handleChange(value, "unit");
                }}
                value={values.unit}
                options={
                  units &&
                  units.map((standard, id) => {
                    return { name: standard.name, value: standard.name };
                  })
                }
              />
            </div>

            <div className="buttons-cover">
              <div
                className="buttons"
                onClick={(e) => handleSubmit(e)}
                type="submit"
              >
                UPDATE
              </div>
            </div>
          </form>
          <div className="notes-header">Notes</div>
          <div className="description-cover">
            <textarea
              style={{ resize: "none" }}
              className="profile-detail-input-textarea"
              name="comment"
              form="usrform"
              placeholder="Add notes here..."
              onChange={(e) => {
                handleChange(e.target.value, "notes");
              }}
              value={values.notes}
            ></textarea>
            <div className="add-buttons-cover">
              <div
                onClick={(e) => {
                  handleAddNotes(e);
                }}
                className="add"
              >
                ADD
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailedView;
