import React, { useEffect } from "react";

import { Route, Redirect, withRouter } from "react-router-dom";
import { routes } from "./routes";
import { useRouteMatch } from "react-router-dom";

import { compose } from "recompose";
import { withAuthentication, withClaims } from "./shared/components/Session";
import { withFirebase } from "./shared/components/Firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import TopBar from "./features/TopBar";
import { NotificationContainer } from "react-notifications";
function RouteWithSubRoutes({ route }) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

function App(props) {
  let mobDashboardmatch = useRouteMatch("/mobile/view");
  useEffect(() => {
    if (props.location.pathname === "/") {
      props.history.replace("/tool/inventory");
    }
  }, [props.location.pathname]);

  return (
    <div className="App">
      <div className="main-component-container">
        {props.location.pathname === "/login" || mobDashboardmatch ? null : (
          <TopBar />
        )}

        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={i} route={route} />;
        })}
      </div>
      <NotificationContainer />
    </div>
  );
}

export default compose(
  withRouter,
  withAuthentication,
  withClaims,
  withFirebase
)(App);
