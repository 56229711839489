import React from "react";
import "../styles.scss";
import SelectSearch from "react-select-search";

function SMSupplier({
  supList,
  handleChange,
  supName,
  orderInfo,
  addSupplier,
}) {
  const statusVariable = {
    open: "Open",
    a_approval: "Sent for Admin Approval",
    a_approved: "Admin Approved",
    sa_approved: "Super Admin Approved",
    rejected: "Rejected",
    vp: "Vendor Proccessing",
    delivered: "Received",
  };
  return (
    <div className="sm-supplier-container">
      <div className="sm-supplier-title">ADD SUPPLIER</div>
      <div className="sm-supplier-input-wrapper">
        <div className="sm-supplier-name">Supplier Name</div>
        <div className="sm-supplier-input">
          {(orderInfo.status && orderInfo.status === "vp") ||
          (orderInfo.status && orderInfo.status === "delivered") ? (
            orderInfo.supplierInfo && orderInfo.supplierInfo.name
          ) : (
            <SelectSearch
              className="select-search-box"
              placeholder="Search Supplier"
              onChange={({ value }) => handleChange(value)}
              value={supName}
              options={
                supList &&
                supList.map((sup, i) => {
                  return {
                    name: sup.name,
                    value: sup._id,
                  };
                })
              }
            />
          )}
        </div>
        {(orderInfo.status && orderInfo.status === "vp") ||
        (orderInfo.status && orderInfo.status === "delivered") ? null : (
          <div className="sm-supplier-add" onClick={() => addSupplier()}>
            ADD
          </div>
        )}
      </div>
      <div className="sm-supplier-status-container">
        <div className="sm-supplier-status-name">Status</div>
        <div className="sm-supplier-status">
          {statusVariable[orderInfo.status]}
        </div>
      </div>
    </div>
  );
}

export default SMSupplier;
