import React, { useState, useEffect } from "react";
import MobProjectAddHour from "../components/MobProjectAddHour";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";

function MobProjectAddHourContainer(props) {
  const [projData, setProjData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);

  const projectId = props.location.state.projectId;

  const projectName = props.location.state.projectName;

  const [addData, setAddData] = useState({
    hrs: 0,
    date: "",
    otHrs: 0,
  });

  const handleChange = (value, type) => {
    addData[type] = value;
    setAddData({ ...addData });
  };

  const validate = () => {
    if (!addData.date) {
      NotificationManager.warning("Please select Date", "", 2000);
      return;
    }
    if (!addData.hrs) {
      NotificationManager.warning("Please add working hours", "", 2000);
      return;
    }
  };

  const handleSubmit = async () => {
    validate();
    if (addData.date && addData.hrs) {
      const data = {
        hrs: addData.hrs,
        date: addData.date,
        otHrs: addData.otHrs,
      };
      const add = await ApiHandler.addHourToProject(projectId, data);
      setRefresh(!refresh);
      setAddData({
        hrs: 0,
        date: "",
        otHrs: 0,
      });

      if (add.status === "OK") {
        NotificationManager.success(
          "working hours added successfully",
          " ",
          2000
        );
      } else {
        NotificationManager.warning(add.message, "", 2000);
      }
    }
  };
  const handleDelete = async (logId) => {
    try {
      const del = await ApiHandler.deleteManHour(projectId, logId);
      setRefresh(!refresh);
      if (del.status === "OK") {
        NotificationManager.success(del.message, "", 2000);
      } else {
        NotificationManager.success(del.message, "", 2000);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    let clean = true;
    const fetchData = async () => {
      try {
        setLoader(true);

        const data = await ApiHandler.getProjectFormDetails(projectId);
        if (clean) {
          setProjData(data.results.work);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        throw error;
      }
    };
    fetchData();
    return function cleanup() {
      clean = false;
    };
  }, [refresh]);

  return (
    <MobProjectAddHour
      childrensProps={props.children}
      projData={projData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      addData={addData}
      loader={loader}
      projectName={projectName}
      handleDelete={handleDelete}
    />
  );
}
export default MobProjectAddHourContainer;
