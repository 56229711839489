import React, { useState, useEffect } from "react";
import Request from "../components/Request";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import Loader from "react-loader-spinner";

function RequestContainer({}) {
  let fy = new Date().getFullYear();
  let month = new Date().getMonth();
  let yearVal;
  if (month <= 2) {
    yearVal = fy;
  } else {
    yearVal = fy + 1;
  }

  const [yearOptions, setYearOptions] = useState(
    Array.from(new Array(5), (val, index) => yearVal - index)
  );

  const [year, setYear] = useState(yearOptions[0]);

  const handleInputChange = async (year) => {
    await setYear(year);
  };

  return (
    <div>
      <Request
        yearOptions={yearOptions}
        year={year}
        handleInputChange={handleInputChange}
      />
    </div>
  );
}

export default RequestContainer;
