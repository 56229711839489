import React, { useEffect, useState } from "react";
import DetailedRequestView from "../components/DetailedRequestView";
import { useParams } from "react-router-dom";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";

import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";
import icons from "../../../assets/icons/Icons";
import Loader from "react-loader-spinner";

function DetailedRequestViewContainer(props) {
  const [data, setData] = useState([]);
  const [appData, setAppData] = useState([]);
  const [returnData, setReturnData] = useState([]);
  const [status, setStatus] = useState(null);
  const [ongoingRequestId, setOngoingRequestId] = useState("");
  const [description, setDescription] = useState("");
  const [activityLog, setActivityLog] = useState([]);
  const [reload, setReload] = useState(false);
  const [shipBtn, setShipBtn] = useState([]);
  const [orderDetails, setOrderDetails] = useState("")
  const [btnStatus, setBtnStatus] = useState(false)
  const [newQuantity, setNewQuantity] = useState("")
  const [editStatus, setEditStatus] = useState(false)
  let { id } = useParams();
  const userRole = props.claimsData && props.claimsData.role;

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getEachRequestList(id);
      setData(response.results.items);
      setStatus(response.results.status);
      setOrderDetails(response.results)
      setBtnStatus(response.results.btnStatus)
    };
    if (props.claimsData) {
      fetchData();
    }
  }, [props.claimsData, reload, btnStatus, editStatus]);

  useEffect(() => {
    const fetch = data.map((element, index) => {
      // setNewQuantity([...newQuantity, element.approvedQuantity])
      return {
        _id: element._id,
        itemId: element.itemId,
        itemInfo: element.itemInfo,
        approvedQuantity: element.quantity,
        quantity: element.quantity,
      };
    });
    setAppData(fetch);
  }, [data]);

  const handleDescriptionChange = (changedValue) => {
    setDescription(changedValue);
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getReturnData(
        id,
        status === "return_approved" || status === "return_initiated"
          ? "return_approved"
          : status === "return_completed"
          ? "return_completed"
          : "ongoing_return"
      );
      if (response.results.ongoingRequestData) {
        setOngoingRequestId(response.results.ongoingRequestData._id);
      }
      if (response.results.ongoingRequestData) {
        setReturnData(response.results.ongoingRequestData.items);
      }
    };
    if (status != null) {
      fetchData();
    }
  }, [status]);

  const handleApproveClick = async (e) => {
    e.preventDefault();

    const modifiedData = appData.map((element, i) => {
      return {
        rowId: element._id,
        itemId: element.itemId,
        approvedQuantity: element.approvedQuantity,
      };
    });
    try {
      const result = await ApiHandler.postApprovedQuantity(id, {
        modifiedItems: modifiedData,
      });
      NotificationManager.success("Approved Successfully", "", 2000);

      try {
        const Result = await ApiHandler.changeApproveStatus(id, {
          status: "a_approved",
        });
        setReload(!reload);
      } catch (error) {
        NotificationManager.warning(
          "Some error has occurred, check your connection",
          "",
          2000
        );
      }
    } catch (error) {
      NotificationManager.warning(
        "Some error has occurred, check your connection",
        "",
        2000
      );
    }
  };

  const handleShipClick = async (e) => {
    e.preventDefault();
    try {
      const Result = await ApiHandler.postShipSuccess(id);
      setReload(!reload);
      NotificationManager.success("Shipped Successfully", "", 2000);
    } catch (error) {
      NotificationManager.warning(
        "Some error has occurred, check your connection",
        "",
        2000
      );
    }
  };

  const handleUpdateShipClick = async (e) => {
    e.preventDefault();

    const modifiedData = data.map((element) => {
      return {
        rowId: element._id,
        fromStore: {
          quantity: element.fromStore.quantity,
        },

        fromProjects: element.fromProjects.map((proj) => {
          return {
            projectId: proj.projectId,
            quantity: proj.assignedQuantity
              ? proj.assignedQuantity
              : proj.quantity,
            requestId: proj.requestId,
            ongoingRequestId: proj._id,
          };
        }),
      };
    });
    try {
      const result = await ApiHandler.postShippedQuantity(id, {
        modifiedItems: modifiedData,
      });
      setReload(!reload);

      NotificationManager.success("Updated Successfully", "", 2000);
    } catch (error) {
      NotificationManager.warning(
        "Some error has occurred, check your connection",
        "",
        2000
      );
    }
  };

  const handleAddNotes = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiHandler.postRequestNotes(id, {
        note: description,
      });
      setReload(!reload);
      setDescription("");

      NotificationManager.success("Added Notes Successfully", "", 2000);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getRequestActivityLog(id);
      setActivityLog(response.results);
    };
    fetchData();
  }, [reload]);

  const handleAcceptReturn = async (e) => {
    e.preventDefault();
    const modifiedData = returnData.map((element) => {
      return {
        itemId: element.itemId,
        toStoreQty: element.toStore.quantity,
      };
    });
    const approvedData = {
      ongoingRequestId: ongoingRequestId,
      modifiedItems: modifiedData,
    };
    try {
      const Result = await ApiHandler.postAcceptedReturnQuantity(
        id,
        approvedData
      );
      NotificationManager.success("Accepted Successfully", "", 2000);
      setReload(!reload);
    } catch (error) {
      NotificationManager.warning(
        "Some error has occurred, check your connection",
        "",
        2000
      );
    }
  };
  const handleReceivedClick = async (e) => {
    e.preventDefault();
    try {
      const Result = await ApiHandler.postReturnCompleted(id);
      NotificationManager.success("Received successfully", "", 2000);
      setReload(!reload);
    } catch (error) {
      NotificationManager.warning(
        "Some error has occurred, check your connection",
        "",
        2000
      );
    }
  };
  const handleCompleteRequestClick = async (e) => {
    e.preventDefault();
    try {
      const Result = await ApiHandler.postRequestCompleted(id);
      NotificationManager.success("Request completed successfully", "", 2000);
      setReload(!reload);
    } catch (error) {}
  };

  useEffect(() => {
    const shipBtn =
      data &&
      data.length > 0 &&
      data.filter((e) => {
        return (
          e.fromStore.quantity > 0 ||
          e.fromProjects.reduce((total, i) => total + i.quantity, 0)
        );
      });

    setShipBtn(shipBtn);
  }, [data]);

  const handleDeleteReq = async () => {
    try {
      if (id) {
        const del = await ApiHandler.delReq(id);

        await NotificationManager.success(
          "Request deleted successfully",
          "",
          2000
        );
        props.history.replace("/tool/requests");
      } else {
        NotificationManager.error("Error while deleting the request", "", 2000);
      }
    } catch (error) {
      NotificationManager.error("Error while deleting the request", "", 2000);
    }
  };

  const createPurchase = async() =>{
    try{
      if(!editValId){
        const itemData = appData.map(item =>{
          return {
            itemId: item.itemId,
            quantity: item.approvedQuantity
          }
        })
    
        const newOrderData = {
          purchasedFor: orderDetails.employeeId,
          projectId: orderDetails.projectId,
          // createdBy: props.claimsData.employeeId,
        };
    
        const response = await ApiHandler.addPurchaseOrder(newOrderData)
        let poId = response.results._id
    
        if (response.status === "OK") {
          const itemRes = await ApiHandler.addPurchaseItem(poId, itemData)
          if (itemRes.status === "OK") {
            NotificationManager.success(
            "New purchase ordered created succesfully", "", 2000);
            var reqId = orderDetails._id
            let poBtnStatus ={ 
              status: true
            }
    
            const btnRes = await ApiHandler.updateRequest(reqId, poBtnStatus)
            if(btnRes.status === "OK"){
              setBtnStatus(true)
              const id = {
                reqId: reqId
              }
              const requestId = await ApiHandler.mergeRequestPo(poId, id) 
              handleClose()
            }
          }
          else {
            NotificationManager.warning("Error in creating purchase order", "", 2000);
          }
        } 
        else {
          NotificationManager.warning(response.message, "", 2000);
        }
      }
      else{
        NotificationManager.warning("Please enter the value or click on save ", "", 2000);
      }
    }
    catch(err){
      throw err
    }
  }

  const handleBack = () => {
    props.history.replace("/tool/requests");
  };

  //modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setEditValId('');
    setShow(false);
    setNewQuantity('')
  }
  const handleShow = () => setShow(true);
  const [editValId, setEditValId] = useState('')
  const [itmData, setItmData] = useState({
    itemId: ''  
  })

  const onValueChnage = (e, id) =>{
    e.preventDefault()
    const {value, name} = e.target
    setNewQuantity(value)
    setItmData({itemId:id})
  }

  const editQuantity = (id, appQty) => {
   return(
    <>
      <input type="number" name="newQuantity" value={newQuantity} onChange={(e) => onValueChnage(e, id)} style={{width: "6em"}}/>
      <button type="submit" className="btn btn-padLft" onClick={(e) => {onUpdateData(e)}} data-toggle="tooltip" data-placement="bottom" title="save">
        {icons.save}
      </button>
      <button type="button" className="btn btn-padLft" onClick={()=>{setEditValId(''); setNewQuantity('')}} data-toggle="tooltip" data-placement="bottom" title="cancel">
        {icons.close}
      </button>
    </>
   )
  }

  const itemsDetails = () =>{
    return data.map((item, index) =>{
      return (
        <tr key={item._id}>
          <td>
            {item.itemInfo.concatName}
          </td>
          <td>
            {item.approvedQuantity}
            <button type="button" className="btn quantity-edit" onClick={() => setEditValId(item._id)} data-toggle="tooltip" data-placement="bottom" title="edit">
              {icons.edit}
            </button>
          </td>
          <td>
          {editValId === item._id ? 
            editQuantity(item._id, item.approvedQuantity) : ''}
          </td>
        </tr>
      )
    })
  }

  const onUpdateData = async() =>{
    if(newQuantity){
      try{
        itmData.approvedQuantity = Number(newQuantity)
        const data = await ApiHandler.editReqPurchaseQuantity(id,{
          modifiedItems: itmData
        })
        if (data.status === "OK") {
          NotificationManager.success(
            "Quantity Updated Successfully", "", 2000);
          setTimeout(() => {
            setEditValId('')
            setNewQuantity('')
          }, 1000);
          setEditStatus(!editStatus)
  
        } else {
          NotificationManager.warning(data.message, "", 2000);
        }
      }
      catch(err){
        throw err
      }
    }
    else{
      NotificationManager.warning("Please enter the value ", "", 2000);
    }

  }

  return (
    <>
      <DetailedRequestView
        handleApproveClick={handleApproveClick}
        data={data}
        status={status}
        handleShipClick={handleShipClick}
        returnData={returnData}
        handleAcceptReturn={handleAcceptReturn}
        handleReceivedClick={handleReceivedClick}
        handleCompleteRequestClick={handleCompleteRequestClick}
        handleDescriptionChange={handleDescriptionChange}
        description={description}
        handleAddNotes={handleAddNotes}
        activityLog={activityLog}
        userRole={userRole}
        handleUpdateShipClick={handleUpdateShipClick}
        appData={appData}
        shipBtn={shipBtn}
        handleDeleteReq={handleDeleteReq}
        handleBack={handleBack}
        createPurchase={createPurchase}
        btnStatus={btnStatus}
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        itemsDetails={itemsDetails}
        editValId={editValId}
      />
    </>
  );
}

export default compose(withRouter, withClaims)(DetailedRequestViewContainer);
