import React, { useState, useEffect } from "react";
import Projects from "../components/Projects";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Label } from "react-bootstrap";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";

function ProjectsContainer(props) {
  const [modalShow, setModalShow] = useState(false);
  const handleOpen = (status) => {
    setModalShow(status);
  };
  const handleClose = (status) => {
    setModalShow(status);
    setValues({ projectName: "", supervisor: "", location: "" });
  };

  const [values, setValues] = useState({
    projectName: "",
    supervisor: "",
    location: "",
    projectId: "",
    companyName: "",
  });
  const [reload, setReload] = useState(false);
  const role = props.claimsData && props.claimsData.role;
  const [supervisorList, SetSupervisorList] = useState([]);
  const [companyList, SetCompanyList] = useState([]);
  const currentOptions =
    supervisorList.length > 0 &&
    supervisorList.map((employee, index) => {
      return {
        label: employee.employeeInfo && employee.employeeInfo.name,
        value: employee.employeeInfo && employee.employeeInfo._id,
      };
    });

  const customStyles = {
    menu: () => ({
      position: "relative",
      border: "1px solid grey",
      backgroundColor: "white",
    }),
    placeholder: () => ({ color: "black", fontSize: 14 }),
    container: () => ({
      width: "65%",
      float: "left",
    }),
    control: () => ({
      width: "100%",
      minHeight: 45,
      display: "flex",
      border: "1px solid grey",
    }),
  };

  const [issubbmitted, setIsSubbmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (changedValue, type) => {
    values[type] = changedValue;
    setValues({ ...values });
  };
  const validate = () => {
    let errors = {};
    if (!values.projectName) {
      errors.projectName = "Project Name is required";
      NotificationManager.warning(errors.projectName, "", 2000);
      setErrors(errors);
      return;
    }
    if (!values.supervisor) {
      errors.supervisor = "Supervisor name is required";
      NotificationManager.warning(errors.supervisor, "", 2000);
      setErrors(errors);
      return;
    }

    setErrors(errors);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubbmitted(true);
    validate();
  };

  useEffect(() => {
    const addProjectInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        const modifiedData = {
          name: values.projectName,

          supervisor: values.supervisor.map((element, index) => {
            return element.value;
          }),
          location: values.location ? values.location : "N/A",
          companyId: values.companyName,
          customId: values.projectId,
        };

        try {
          const result = await ApiHandler.PostProjectDetails(modifiedData);
          modifiedData._id = result.results._id;

          NotificationManager.success("Added Successfully", "", 2000);
          setReload(!reload);
          handleClose(false);
        } catch (error) {}
      }
    };
    addProjectInfo();
  }, [errors]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.supervisorListWithAccess();
      SetSupervisorList(response.results);
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getCompanyList();
      SetCompanyList(response.results);
    };

    fetchData();
  }, []);

  return (
    <Projects
      modalShow={modalShow}
      handleOpen={handleOpen}
      handleClose={handleClose}
      values={values}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      customStyles={customStyles}
      currentOptions={currentOptions}
      role={role}
      companyList={companyList}
      reload={reload}
    />
  );
}

export default compose(withRouter, withClaims)(ProjectsContainer);
