import React from "react";
import "../styles.scss";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function Login({
  loginCred,
  handleInputChange,
  handleSignIn,
  showPass,
  handleShowPass,
}) {
  return (
    <div className="login-container">
      <Card
        border="light"
        style={{ width: "30rem", height: "22rem" }}
        className="card-position"
      >
        <Card.Header as="h2">Login</Card.Header>
        <Card.Body>
          <div className="form-cover">
            <form className="form" onSubmit={handleSignIn}>
              <input
                className="inputs"
                type="text"
                placeholder="Employee Id"
                onChange={(e) => handleInputChange(e, "customId")}
                value={loginCred.customId}
              />
              <input
                className="inputs"
                type={showPass ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => handleInputChange(e, "password")}
                value={loginCred.password}
              />
              <div
                className="showPass"
                onClick={() => {
                  handleShowPass();
                }}
              >
                {showPass ? "Hide Password" : "Show password"}
              </div>

              <Button variant="success" className="button" type="submit" block>
                {" "}
                Login
              </Button>
            </form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Login;
