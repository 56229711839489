import React, { useState, useEffect } from "react";
import DetailedView from "../components/DetailedView";
import { useParams } from "react-router-dom";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";

function DetailedViewContainer(props) {
  const userRole = props.claimsData && props.claimsData.role;
  const [values, setValues] = useState({
    totalQuantity: "",
    toolsInStock: "",
    category: "",
    itemName: "",
    itemType: "",
    itemCode: "",
    location: "",
    description: "",
    notes: "",
    unit: "",
  });
  const [activityLog, setActivityLog] = useState([]);
  const [toolsInUse, setToolsInUse] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [category, setCategory] = useState(null);
  const [concatName, setConcatName] = useState("");
  const [issubbmitted, setIsSubbmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [units, setUnits] = useState([]);

  const [apiResults, setApiResults] = useState(null);
  const handleChange = (changedValue, type) => {
    values[type] = changedValue;
    setValues({ ...values });
  };
  let { id } = useParams();

  const columns = ["Employee", "Project", "Location", "Quantity"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiHandler.getUnitList();
        setUnits(response.results);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getItemdetailedView(id);
      setApiResults(response.results);
      setCategory(response.results.category);
      setConcatName(response.results.concatName);

      const apiValues = response.results;
      const prefilledValues = {
        totalQuantity: apiValues.quantity,
        toolsInStock: apiValues.itemInStock,
        totalItemInUse: apiValues.itemInUse,
        category: apiValues.category,
        itemName: apiValues.name,
        itemType: apiValues.type,
        itemCode: apiValues.code,
        location: apiValues.location,
        unit: apiValues.unit,
        description: apiValues.description,
      };
      setValues(prefilledValues);
    };
    if (props.claimsData) {
      fetchData();
    }
  }, [status, props.claimsData]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getToolsInUse(id);
      const tempTableData = [];
      setToolsInUse(response.results.totalInUseQty);
      response.results.projectList.map((element) => {
        element.projectInfo.supervisorInfo.map((info) => {
          const data = [
            info.name,
            element.projectInfo.name,
            element.projectInfo.location,
            element.item.inUseQty,
          ];

          if (category === "MACHINE" || category === "TOOLS") {
            tempTableData.push(data);
          }
        });
      });
      setTableData(tempTableData);
    };
    if ((category === "MACHINE" || category === "TOOLS") && props.claimsData) {
      fetchData();
    }
  }, [category, props.claimsData]);

  useEffect(() => {
    try {
      const UpdateInfo = async () => {
        if (issubbmitted) {
          try {
            const response = await ApiHandler.postUpdate(id, {
              location: values.location,
              unit: values.unit,
              code: values.itemCode,
              description: values.description,
              category: values.category,
              itemInStock: values.toolsInStock,
            });

            console.log("response", response);
            setStatus(!status);
            NotificationManager.success("Updated Successfully", "", 2000);
          } catch (error) {}
        }
      };
      UpdateInfo();
    } catch (error) {}
  }, [issubbmitted]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.itemCode) {
      setIsSubbmitted(true);
    } else {
      NotificationManager.warning("Please add the item code", "", 2000);
    }
  };
  const handleAddNotes = async (e) => {
    e.preventDefault();
    try {
      if (values.notes) {
        const response = await ApiHandler.postNotes(id, {
          note: values.notes,
        });
        setStatus(!status);
        NotificationManager.success("Added Notes Successfully", "", 2000);
      } else {
        NotificationManager.warning("Please add Notes!", "", 2000);
      }
    } catch (error) {}
  };
  const handleBack = () => {
    props.history.replace("/tool/inventory");
  };

  const options = {
    rowsPerPageOptions: [4, 3, 2],
    rowsPerPage: 4,

    print: false,
    download: false,
    selectableRows: false,
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getActivityLog(id);
      setActivityLog(response.results);
    };
    fetchData();
  }, [status]);
  return (
    <>
      {apiResults && tableData ? (
        <DetailedView
          category={category}
          tableData={tableData}
          columns={columns}
          options={options}
          values={values}
          apiResults={apiResults}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleBack={handleBack}
          concatName={concatName}
          toolsInUse={toolsInUse}
          handleAddNotes={handleAddNotes}
          activityLog={activityLog}
          status={status}
          userRole={userRole}
          units={units}
        />
      ) : (
        <div className="loader-style">
          <Loader type="ThreeDots" color="#7764e4" height={100} width={100} />
        </div>
      )}
    </>
  );
}

export default compose(withRouter, withClaims)(DetailedViewContainer);
