import React, { Fragment } from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";

function PurchaseList({ tableData, columns, options }) {
  return (
    <div className="po-setting-list-container">
      <div className="po-list-title">
        <div>PURCHASE LIST WITH SUPPLIERS</div>
      </div>
      <div className="po-list-wrapper">
        <MUIDataTable data={tableData} columns={columns} options={options} />
      </div>
    </div>
  );
}

export default PurchaseList;
