import React from "react";
import "../styles.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ProjectTable from "../containers/ProjectTableContainer";
import Select from "react-select";
import SelectSearch from "react-select-search";

function Projects({
  modalShow,
  handleOpen,
  handleClose,
  customStyles,
  currentOptions,
  values,
  handleChange,
  handleSubmit,
  role,
  companyList,
  reload,
}) {
  return (
    <div className="projects-outer-cover">
      <div className="projects-inner-cover">
        {role === "super_admin" || role === "admin" ? (
          <Button
            variant="link"
            variant="outline-info"
            size="sm"
            className="button"
            block
            onClick={() => {
              handleOpen(true);
            }}
          >
            {" "}
            Add New Project
          </Button>
        ) : null}
        <Modal
          size="md"
          show={modalShow}
          onHide={() => handleClose(false)}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
            <Modal.Title style={{ color: "white", fontSize: "16px" }}>
              Add New Project
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="projects-form-cover">
              <div className="projects-fields-label-cover">
                <label className="projects-profile-label" htmlFor="company">
                  Company Name
                </label>

                <SelectSearch
                  onChange={({ value }) => {
                    handleChange(value, "companyName");
                  }}
                  className="select-search-box"
                  name="company"
                  id="company"
                  value={values.companyName}
                  options={companyList.map((list, id) => {
                    return {
                      name: list.name,
                      value: list._id,
                    };
                  })}
                />
              </div>
              <div className="projects-fields-label-cover">
                <label className="projects-profile-label" htmlFor="projName">
                  Project Name
                </label>

                <input
                  className="projects-profile-input"
                  type="text"
                  id="projName"
                  name="projName"
                  onChange={(e) => {
                    handleChange(e.target.value, "projectName");
                  }}
                  value={values.projectName}
                />
              </div>
              <div className="projects-fields-label-cover">
                <label className="projects-profile-label" htmlFor="location">
                  Project Id
                </label>

                <input
                  className="projects-profile-input"
                  type="text"
                  id="projectId"
                  name="projectId"
                  onChange={(e) => {
                    handleChange(e.target.value, "projectId");
                  }}
                  value={values.projectId}
                />
              </div>
              <div className="projects-fields-label-cover">
                <label className="projects-profile-label" htmlFor="supervisor">
                  Supervisor
                </label>
                <Select
                  onChange={(selectedOption) => {
                    handleChange(selectedOption, "supervisor");
                  }}
                  styles={customStyles}
                  isMulti={true}
                  options={currentOptions}
                  value={values.supervisor}
                />
              </div>

              <div className="projects-fields-label-cover">
                <label className="projects-profile-label" htmlFor="location">
                  Location
                </label>

                <input
                  className="projects-profile-input"
                  type="text"
                  id="location"
                  name="location"
                  onChange={(e) => {
                    handleChange(e.target.value, "location");
                  }}
                  value={values.location}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="projects-header">Project List</div>
        <div className="projects-table-cover">
          <ProjectTable reload={reload} />
        </div>
      </div>
    </div>
  );
}

export default Projects;
