import React, { useEffect, useState } from "react";
import MobReqProcess from "../components/MobReqProcess";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { withRouter } from "react-router-dom";

function MobReqProcessContainer(props) {
  const requestId = props.location.state && props.location.state.RequestId;

  const [reqProcessData, setReqProcessData] = useState({});
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [noteRefresh, setNoteRefresh] = useState(false);

  useEffect(() => {
    let clean = true;

    const fetchData = async () => {
      try {
        setLoader(true);
        const data = await ApiHandler.getEachRequestList(requestId);
        if (clean) {
          setReqProcessData(data.results);
          setLoader(false);
        }
      } catch (error) {
        throw error;
      }
    };
    fetchData();
    return () => {
      clean = false;
    };
  }, [refresh]);

  const handleReceiveStatus = async (field1, field2) => {
    try {
      if (field1 && field2) {
        const sendData = {
          var1: field1,
          var2: field2,
        };
        const store = await ApiHandler.reqSendStatus(requestId, sendData.var1);

        const project = await ApiHandler.reqSendStatus(
          requestId,
          sendData.var2
        );
        setRefresh(!refresh);

        if (store.status === "OK" && project.status === "OK") {
          NotificationManager.success("Status updated successfully", "", 2000);
        } else {
          NotificationManager.warning("Error", "", 2000);
        }
      }
      if (field1 && !field2) {
        const sendData = {
          var: field1,
        };

        const receive = await ApiHandler.reqSendStatus(requestId, sendData.var);

        setRefresh(!refresh);

        if (receive.status === "OK") {
          NotificationManager.success(
            "Status has been updated succefully",
            "",
            2000
          );
        } else {
          NotificationManager.warning(receive.message, "", 2000);
        }
      }
    } catch (error) {}
  };

  const handleReqReturn = async (data) => {
    try {
      const returnData = {
        projectId: reqProcessData.projectInfo._id,
        modifiedItems: data,
      };
      const reqReturn = await ApiHandler.reqItmReturnBySup(
        requestId,
        returnData
      );
      setRefresh(!refresh);
      if (reqReturn.status === "OK") {
        NotificationManager.success(
          "Return request sent successfully",
          "",
          2000
        );
      } else {
        NotificationManager.warning(reqReturn.message, "", 2000);
      }
    } catch (error) {
      throw error;
    }
  };

  const noteSubmit = async (noteData) => {
    try {
      const note = await ApiHandler.reqNote(requestId, { note: noteData });
      setNoteRefresh(!noteRefresh);

      if (note.status === "OK") {
        NotificationManager.success("Notes added successfully", "", 2000);
      } else {
        NotificationManager.warning(note.message, "", 2000);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteReq = async () => {
    try {
      const del = await ApiHandler.delReq(requestId);
      if (del.status === "OK") {
        NotificationManager.success("Requested deleted", "", 2000);
        props.history.replace("/mobile/view/request/list");
      } else {
        NotificationManager.warning(del.message, "", 2000);
      }
    } catch (error) {}
  };

  return (
    <MobReqProcess
      childrenProps={props.children}
      reqProcessData={reqProcessData}
      handleReceiveStatus={handleReceiveStatus}
      handleReqReturn={handleReqReturn}
      noteSubmit={noteSubmit}
      loader={loader}
      noteRefresh={noteRefresh}
      requestId={requestId}
      refresh={refresh}
      handleDeleteReq={handleDeleteReq}
    />
  );
}

export default withRouter(MobReqProcessContainer);
