import React from "react";
import "../styles.scss";

function AddCompany({ companyName, handleChange, subBtn }) {
  return (
    <div className="user-detail-add-company-wrapper">
      <div className="user-detail-add-company-title">ADD PROJECT DETAILS</div>
      <div className="user-details-add-company-body">
        <div className="user-details-add-company-container">
          <div className="user-details-add-company-name">Comapny Name</div>
          <input
            type="text"
            value={companyName}
            className="user-details-company-name-input"
            onChange={(e) => handleChange(e.target.value)}
          />
          <div className="user-details-add-btn" onClick={() => subBtn()}>
            ADD
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCompany;
