import React, { useState, useEffect } from "react";
import DetailedRequestTable2 from "../components/DetailedRequestTable2";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { NotificationManager } from "react-notifications";

function DetailedRequestTable2Container(props) {
  const [data, setData] = useState([]);
  const [innerData, setInnerData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [innerFinalData, setInnerFinalData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [concat, setConcat] = useState("");

  const columns = [
    "Item Name",
    "Size",
    "Description",
    "Approved Quantity",
    "Item in Stock",
    "from store",
    "From Other Projects",
  ];
  const innerTablecolumns = [
    "Project Name",
    "Return Quantity",
    "Assign Quantity",
  ];
  const innerOptions = {
    rowsPerPageOptions: [5, 4],
    rowsPerPage: 5,

    selectableRows: "none",
    disableToolbarSelect: true,
  };

  const options = {
    rowsPerPageOptions: [5, 4],
    rowsPerPage: 5,

    selectableRows: "none",
    disableToolbarSelect: true,
  };
  useEffect(() => {
    setData(props.data);
  }, [props.data, props.status]);

  const [totalArr, setTotalArr] = useState([]);

  useEffect(() => {
    const fetch =
      data &&
      data.map((data, i) => {
        return {
          totalQty:
            data.fromProjects && data.fromProjects.length > 0
              ? data.fromProjects.reduce(
                  (total, i) =>
                    total +
                    (i.assignedQuantity ? i.assignedQuantity : i.quantity),
                  0
                )
              : 0,
        };
      });
    setTotalArr(fetch);
  }, [data, props.status]);

  const [rowId, setRowId] = useState();
  const handleModal = async (itemId, row) => {
    const fetchData = async () => {
      const response = await ApiHandler.getAssignTableData(itemId);
      // const name = await
      await setConcat(data[row].itemInfo.concatName);
      await setInnerData(response.results);
    };
    fetchData();
    await setRowId(row);
    await setModalShow(true);
    await setSelectedRow(row);
  };

  const handleClose = (status) => {
    setModalShow(status);
    setRowId("");
    setConcat("");
    setInnerData([]);
    setSelectedRow();
  };
  const handleStoreChange = (changedValue, itemInStock, element, index) => {
    if (
      totalArr &&
      totalArr.length > 0 &&
      totalArr[index].totalQty + changedValue <= element.approvedQuantity
    ) {
      if (changedValue <= itemInStock) {
        element.fromStore.quantity = changedValue;
        setData([...data]);
        return;
      } else {
        NotificationManager.warning("Insufficent quantity", "", 2000);
        element.fromStore.quantity = 0;
        setData([...data]);
      }
    } else {
      NotificationManager.warning(
        "Quantity is empty or more than that of Approved Qty",
        "",
        2000
      );
      element.fromStore.quantity = 0;
      setData([...data]);
    }
  };

  const handleAssignQuantity = (changedValue, element) => {
    const storeQty = data[rowId].fromStore.quantity;
    const approvedQty = data[rowId].approvedQuantity;

    if (changedValue + storeQty <= approvedQty) {
      if (changedValue <= element.quantity) {
        element["assignedQuantity"] = changedValue;
        setInnerData([...innerData]);
      } else {
        element["assignedQuantity"] = 0;
        setInnerData([...innerData]);
        NotificationManager.warning("Insufficent Quantity", "", 2000);
      }
    } else {
      element["assignedQuantity"] = 0;
      setInnerData([...innerData]);
      NotificationManager.warning(
        "Quantity is empty or more than the approved qty",
        "",
        2000
      );
    }
  };
  const handleAssign = (e) => {
    e.preventDefault();

    data[selectedRow].fromProjects = innerData.filter((element) => {
      return element.assignedQuantity;
    });
    setData([...data]);
    setModalShow(false);
  };

  useEffect(() => {
    const tempTableData = [];
    data.map((element, index) => {
      const data = [
        !element.dimension
          ? element.itemInfo.name
          : element.itemInfo.name +
            " " +
            element.dimension.width +
            " x " +
            element.dimension.length +
            " x " +
            element.dimension.thickness +
            " -- " +
            element.dimension.qty +
            " Qty ",
        element.itemInfo && element.itemInfo.type
          ? element.itemInfo.type
          : "N/A",
        element.itemInfo && element.itemInfo.description
          ? element.itemInfo.description
          : "N/A",
        element.approvedQuantity,
        element.itemInfo.itemInStock,
        <input
          disabled={
            props.userRole !== "store" ||
            props.status === "shipped" ||
            props.status === "received"
              ? true
              : false
          }
          type="number"
          onChange={(e) => {
            handleStoreChange(
              e.target.valueAsNumber,
              element.itemInfo.itemInStock,
              element,
              index
            );
          }}
          value={element.fromStore.quantity}
        ></input>,
        <div>
          {element.fromProjects.map((proj, i) => {
            return (
              <div
                key={i}
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {proj.projectInfo.name} -
                <span
                  style={{
                    paddingLeft: "5px",
                  }}
                >
                  {props.status == "a_approved"
                    ? proj.assignedQuantity
                      ? proj.assignedQuantity
                      : proj.quantity
                    : proj.quantity}
                </span>
              </div>
            );
          })}
          {props.userRole === "store" &&
          props.status != "shipped" &&
          props.status != "received" ? (
            <div
              onClick={() => {
                handleModal(element.itemId, index);
              }}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Assign
            </div>
          ) : null}
        </div>,
      ];
      tempTableData.push(data);
    });
    setTableData(tempTableData);
  }, [data, props.status]);

  useEffect(() => {
    const tempTableData = [];
    innerData.map((element, index) => {
      const oldData = data[selectedRow].fromProjects.filter((mainData) => {
        return element.projectId == mainData.projectId;
      });
      // if (oldData.length > 0) {
      //   element.assignedQuantity = parseInt(oldData[0].assignedQuantity);
      // }
      const innerTableData = [
        element.projectInfo.name,
        element.quantity,
        <input
          type="number"
          disabled={props.userRole !== "store"}
          onChange={(e) => {
            handleAssignQuantity(e.target.valueAsNumber, element, index);
          }}
          // value={}
          //element.assignedQuantity == undefined ? 0 : element.assignedQuantity
          //value={element.assignedQuantity}
          // value={
          //   innerData[index].assignedQuantity
          //     ? innerData[index].assignedQuantity
          //     : data[rowId].fromProjects[index].quantity
          // }
        />,
      ];
      tempTableData.push(innerTableData);
    });

    setInnerFinalData(tempTableData);
  }, [innerData]);

  return (
    <DetailedRequestTable2
      data={tableData}
      innerDetails={innerFinalData}
      columns={columns}
      options={options}
      handleClose={handleClose}
      modalShow={modalShow}
      innerTablecolumns={innerTablecolumns}
      innerOptions={innerOptions}
      handleAssign={handleAssign}
      concat={concat}
    />
  );
}

export default DetailedRequestTable2Container;
