import React from "react";
import "../styles.scss";

import MUIDataTable from "mui-datatables";

function OfferTable({ columns, options, tableData, setReload }) {
  return (
    tableData && (
      <MUIDataTable columns={columns} options={options} data={tableData} />
    )
  );
}

export default OfferTable;
