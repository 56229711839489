import React, { useState, useEffect, Fragment } from "react";
import { withClaims } from "../../../../shared/components/Session";
import MobileRequest from "../components/MobileRequest";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";

function MobileRequestContainer(props) {
  const [reqListData, setReqListData] = useState(null);
  const [loader, setLoader] = useState(false);
  const id = props.claimsData && props.claimsData.employeeId;
  //const [reqStat, setReqStat] = useState([]);
  let fy = new Date().getFullYear();
  let month = new Date().getMonth();
  let yearVal;
  if (month <= 2) {
    yearVal = fy;
  } else {
    yearVal = fy + 1;
  }

  const [yearOptions, setYearOptions] = useState(
    Array.from(new Array(5), (val, index) => yearVal - index)
  );

  const [year, setYear] = useState(yearOptions[0]);

  const handleInputChange = async (year) => {
    await setYear(year);
  };

  useEffect(() => {
    let clean = true;
    const fetchData = async () => {
      try {
        setLoader(true);

        const data = await ApiHandler.supervisorRequestList(
          props.claimsData.employeeId,
          year
        );
        if (clean) {
          setReqListData(data.results);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        throw error;
      }
    };
    if (props.claimsData) {
      fetchData();
    }
    return function cleanup() {
      clean = false;
    };
  }, [props.claimsData, year]);

  const handleClick = (reqId, status) => {
    if (status === "open") {
      props.history.push({
        pathname: "/mobile/view/request/add",
        exact: true,
        state: { RequestId: reqId },
      });
    } else {
      props.history.push({
        pathname: "/mobile/view/request/process",
        exact: true,
        state: { RequestId: reqId },
      });
    }
  };

  return (
    <MobileRequest
      reqListData={reqListData}
      childrenProps={props.children}
      handleClick={handleClick}
      loader={loader}
      yearOptions={yearOptions}
      year={year}
      handleInputChange={handleInputChange}
    />
  );
}

export default withClaims(MobileRequestContainer);
