import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";

function ProjectTable({ data, columns, options }) {
  return (
    data && (
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
        className="pointer"
      />
    )
  );
}

export default ProjectTable;
