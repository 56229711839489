import React from "react";
import "../styles.scss";
import { logRoles } from "@testing-library/react";
import moment from "moment";
function ProgressBar({ activityLog }) {
  return (
    <div className="progress-bar-body mb-4">
      <div className="progress-bar-header">Activity Log</div>
      <div className="outer-container pb-4">
        {activityLog &&
          activityLog.map((element, index) => (
            <div key={index} className="card">
              <div className="date-and-time">
                {moment(element.createdAt).format("DD MMMM YYYY HH:mm")} |{" "}
                {<b>{element.createdBy && element.createdBy.name}</b>}
              </div>
              <div className="description">{element.message}</div>
              {index == activityLog.length - 1 ? null : (
                <div className="step-bar"></div>
              )}
              <div className="step-number"></div>
            </div>
          ))}
        {/* <div className="card-new ">
          <div className="step"></div>
        </div> */}
      </div>
    </div>
  );
}

export default ProgressBar;
