import React, { useState, useEffect } from "react";
import MobileCreateRequest from "../components/MobileCreateRequest";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";
import { withClaims } from "../../../../shared/components/Session";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";

function MobileCreateRequestContainer(props) {
  const [projectData, setProjectData] = useState({
    projectname: "",
    date: "",
  });
  const [projectList, setProjectList] = useState([]);

  const empId = props.claimsData && props.claimsData.employeeId;

  const validate = () => {
    if (!projectData.projectname) {
      NotificationManager.warning("Please select the Project", "", 2000);
      return;
    }
    if (!projectData.date) {
      NotificationManager.warning("Please select the Date", "", 2000);
      return;
    }
  };

  useEffect(() => {
    let clean = true;
    const fetchData = async () => {
      try {
        const data = await ApiHandler.supervisorProjectList(empId);
        if (clean) {
          setProjectList(data.results);
        }
      } catch (error) {
        throw error;
      }
    };
    if (props.claimsData) {
      fetchData();
    }
    return function cleanup() {
      clean = false;
    };
  }, [props.claimsData]);

  const handleChange = (value, type) => {
    projectData[type] = value;
    setProjectData({ ...projectData });
  };
  const handleSubmit = async () => {
    validate();

    if (projectData.projectname && projectData.date && empId) {
      const requestData = {
        projectId: projectData.projectname,
        expectedDate: projectData.date,
        employeeId: empId,
        createdBy: empId,
      };
      const send = await ApiHandler.addRequest(requestData);
      setProjectData({
        projectname: "",
        date: "",
      });
      if (send.status === "OK") {
        NotificationManager.success(
          "New Request created succesfully",
          "",
          2000
        );
        props.history.replace({
          pathname: "/mobile/view/request/add",
          exact: true,
          state: { RequestId: send.results._id },
        });
      } else {
        NotificationManager.warning(send.message, "", 2000);
      }
    }
  };

  return (
    <MobileCreateRequest
      childrenProps={props.children}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      projectData={projectData}
      projectList={projectList}
    />
  );
}

export default withClaims(MobileCreateRequestContainer);
