import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faUserCircle,
  faEye,
  faCheck,
  faPlus,
  faEdit,
  faTrashAlt,
  faArrowLeft,
  faArrowCircleLeft,
  faFileWord,
  faUpload,
  faSave,
  faWindowClose,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
const icons = {
  bell: <FontAwesomeIcon icon={faBell} color="white" />,
  userCircle: <FontAwesomeIcon icon={faUserCircle} color="white" size="2x" />,
  eye: <FontAwesomeIcon icon={faEye} color="white" />,
  done: <FontAwesomeIcon icon={faCheck} color="white" />,
  add: <FontAwesomeIcon icon={faPlus} color="white" />,
  edit: <FontAwesomeIcon icon={faEdit} color="white" />,
  delete: <FontAwesomeIcon icon={faTrashAlt} color="white" />,
  backArrow: <FontAwesomeIcon icon={faArrowLeft} color="white" />,
  backArrowLg: <FontAwesomeIcon icon={faArrowLeft} color="white" size="2x" />,
  arrowLeft: <FontAwesomeIcon icon={faArrowCircleLeft} color="green" />,
  msWord: <FontAwesomeIcon icon={faFileWord} color="blue" />,
  upload: <FontAwesomeIcon icon={faUpload} color="white" size="1x" />,
  download: <FontAwesomeIcon icon={faDownload} color="white" size="1x" />,
  save: <FontAwesomeIcon icon={faSave} color="black" />,
  close: <FontAwesomeIcon icon={faWindowClose} color="red" />,
};
export default icons;
