import React, { useState, useEffect } from "react";
import ProgressBar from "../components/ProgressBar";

function ProgressBarContainer(props) {
  const [activityLog, setActivityLog] = useState([]);
  useEffect(() => {
    setActivityLog(props.data);
  }, [props.data]);
  return <ProgressBar activityLog={activityLog} />;
}

export default ProgressBarContainer;
