import React from "react";
import "../styles.scss";
import MobItemList from "../containers/MobItemListContainer";
import MobNotes from "../containers/MobNotesContainer";
import MobActivity from "../containers/MobActivityContainer";
import MobApproved from "../containers/MobApprovedContainer";
import MobReturn from "../containers/MobReturnContainer";
import moment from "moment";

function MobReqProcess({
  childrenProps,
  reqProcessData,
  loader,
  handleReceiveStatus,
  handleReqReturn,
  noteSubmit,
  noteRefresh,
  requestId,
  refresh,
  handleDeleteReq,
}) {
  return (
    <div className="mob-process-container">
      {childrenProps}
      {reqProcessData.status && reqProcessData.status === "a_approval" && (
        <div className="mob-process-delete-container">
          <div
            className="mob-process-del-btn"
            onClick={() =>
              window.confirm("Confirm to delete") && handleDeleteReq()
            }
          >
            Delete Request
          </div>
        </div>
      )}
      <div className="mob-process-date-container">
        <div>
          Created date:{moment(reqProcessData.createdAt).format("DD/MM/YY")}
        </div>
        <div>
          Expected date:{moment(reqProcessData.expectedDate).format("DD/MM/YY")}
        </div>
      </div>
      <MobItemList
        reqProcessData={reqProcessData}
        loader={loader}
        handleReqReturn={handleReqReturn}
      />
      {reqProcessData.status && reqProcessData.status === "shipped" && (
        <MobApproved
          reqProcessData={reqProcessData}
          loader={loader}
          handleReceiveStatus={handleReceiveStatus}
        />
      )}
      {((reqProcessData.status &&
        reqProcessData.status === "return_approved") ||
        (reqProcessData.status &&
          reqProcessData.status === "return_initiated")) && (
        <MobReturn requestId={requestId} />
      )}
      <MobNotes noteSubmit={noteSubmit} />
      <MobActivity
        noteRefresh={noteRefresh}
        requestId={requestId}
        refresh={refresh}
      />
    </div>
  );
}

export default MobReqProcess;
