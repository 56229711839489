import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

const withAuthorization = (condition) => (Component) => {
  function WithAuthorization(props) {
    useEffect(() => {
      const authListener = props.firebase.auth.onAuthStateChanged((user) => {
        if (!condition(user)) {
          props.history.push("/login");
        }
      });

      return () => authListener();
    });
    return (
      <AuthUserContext.Consumer>
        {(authData) =>
          condition(authData.currentUser) ? <Component {...props} /> : null
        }
      </AuthUserContext.Consumer>
    );
  }

  return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
