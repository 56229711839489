import React, { useState } from "react";
import AddCompany from "../components/AddCompany";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { NotificationManager } from "react-notifications";

function AddCompanyContainer(props) {
  const [companyName, setCompanyName] = useState("");

  const handleChange = (data) => {
    setCompanyName(data);
  };

  const validate = () => {
    if (!companyName) {
      NotificationManager.warning("Please enter the company name", "", 2000);
    }
  };
  const subBtn = async () => {
    try {
      validate();
      if (companyName) {
        const submit = await ApiHandler.addCompany({ name: companyName });
        setCompanyName("");
        if (submit.status === "OK") {
          NotificationManager.success(submit.message, "", 2000);
        } else {
          NotificationManager.warning(submit.message, "", 2000);
        }
      }
    } catch (error) {}
  };

  return (
    <AddCompany
      companyName={companyName}
      handleChange={handleChange}
      subBtn={subBtn}
    />
  );
}

export default AddCompanyContainer;
