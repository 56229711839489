import React, { useState, Fragment } from "react";
import moment from "moment";
import Loader from "../../Loader";
import "../styles.scss";

function MobApproved({
  btn,
  handleBtn,
  reqProcessData,
  loader,
  handleReceiveStatus,
}) {
  const statusField = {
    open: "Open",
    a_approval: "Sent for Approval",
    a_approved: "Admin Approved",
    rejected: "rejected",
    shipped: "shipped",
    return_requested: "Sent for Return Request",
    received: "received",
    return_approved: "Return Request Approved",
    return_initiated: "Supervisor initiated Return",
    return_completed: "Returned",
    request_completed: "Completed",
  };

  let project = [];
  let store = [];

  return (
    <div className="mob-approved-container">
      <div className="mob-approved-header-container">
        <div
          className={
            btn === 1 ? "mob-approved-header selected" : "mob-approved-header"
          }
          onClick={() => handleBtn(1)}
        >
          ITEM FROM STORE
        </div>
        <div
          className={
            btn === 2
              ? "mob-approved-header mob-header-ext selected"
              : "mob-approved-header mob-header-ext"
          }
          onClick={() => handleBtn(2)}
        >
          ITEM FROM OTHER PROJECT
        </div>
      </div>
      {btn === 1 && (
        <div className="mob-approved-data-list-container">
          {loader ? (
            <Loader />
          ) : (
            <table>
              <tbody>
                <tr className="mob-approved-data-table-title-container">
                  <th className="mob-approved-data-table-title width-lg">
                    ITEM NAME
                  </th>
                  <th className="mob-approved-data-table-title width-sm">
                    QTY
                  </th>
                </tr>
                {reqProcessData.items &&
                  reqProcessData.items.length > 0 &&
                  reqProcessData.items.map((data, i) => {
                    if (data.fromStore.quantity > 0) {
                      store.push("true");
                    }
                    if (data.fromProjects && data.fromProjects.length > 0) {
                      project.push("true");
                    }

                    return (
                      <tr className="mob-approved-table-data-container" key={i}>
                        {data.fromStore.quantity > 0 ? (
                          <Fragment>
                            <td className="mob-approved-table-data">
                              {data.itemInfo.concatName}
                            </td>
                            <td className="mob-approved-table-data">
                              {data.fromStore.quantity}
                            </td>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <td className="mob-approved-table-data">
                              {data.itemInfo.concatName}
                            </td>
                            <td className="mob-approved-table-data">
                              No items from store
                            </td>
                          </Fragment>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      )}

      {btn === 2 && (
        <div className="mob-approved-data-list-container">
          <table>
            <tbody>
              <tr className="mob-approved-data-table-title-container">
                <th className="mob-approved-data-table-title width-sm">
                  ITEM NAME
                </th>

                <th className="mob-approved-data-table-title width-lg">
                  Project Name - Qty
                </th>
              </tr>
              {reqProcessData.items &&
                reqProcessData.items.length > 0 &&
                reqProcessData.items.map((data, i) => {
                  if (data.fromProjects && data.fromProjects.length > 0) {
                    project.push("true");
                  }
                  if (data.fromStore.quantity > 0) {
                    store.push("true");
                  }

                  return (
                    <tr className="mob-approved-table-data-container" key={i}>
                      {data.fromProjects && data.fromProjects.length > 0 ? (
                        <Fragment>
                          <td className="mob-approved-table-data">
                            {data.itemInfo.concatName}
                          </td>
                          <td className="mob-approved-table-data">
                            {data.fromProjects &&
                              data.fromProjects.length > 0 &&
                              data.fromProjects.map((prj, i) => {
                                return (
                                  <div key={i} style={{ padding: "2px" }}>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        paddingRight: "2px",
                                      }}
                                    >
                                      {prj.projectInfo && prj.projectInfo.name}
                                    </span>
                                    -->
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        paddingLeft: "2px",
                                      }}
                                    >
                                      {prj.quantity && prj.quantity}
                                    </span>
                                  </div>
                                );
                              })}
                          </td>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <td className="mob-approved-table-data">
                            No items from projects
                          </td>
                          <td className="mob-approved-table-data"></td>
                          <td className="mob-approved-table-data"></td>
                        </Fragment>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      <div className="mob-approved-staus-container">
        <div className="mob-approved-status">
          Status:
          <span style={{ color: "#7764e4" }}>
            {statusField[reqProcessData.status]}
          </span>
        </div>
        <div className="mob-approved-date">
          Date: {moment(reqProcessData.statusChangeTime).format("DD/MM/YYYY")}
        </div>
        <div className="mob-approved-btn-container">
          {btn === 1 &&
            !reqProcessData.receivedFrom.store &&
            store.length > 0 &&
            !project.length && (
              <div
                className="mob-approved-btn"
                onClick={() =>
                  window.confirm("Have you received all the items ?") &&
                  handleReceiveStatus("store", "project")
                }
              >
                Received
              </div>
            )}
          {btn === 1 &&
            !reqProcessData.receivedFrom.store &&
            store.length > 0 &&
            project.length > 0 && (
              <div
                className="mob-approved-btn"
                onClick={() =>
                  window.confirm("Have you received all the items ?") &&
                  handleReceiveStatus("store", null)
                }
              >
                Received
              </div>
            )}

          {btn === 2 &&
            !reqProcessData.receivedFrom.project &&
            project.length > 0 &&
            !store.length && (
              <div
                className="mob-approved-btn"
                onClick={() =>
                  window.confirm("Have you received all the items ?") &&
                  handleReceiveStatus("project", "store")
                }
              >
                Received
              </div>
            )}
          {btn === 2 &&
            !reqProcessData.receivedFrom.project &&
            project.length > 0 &&
            store.length > 0 && (
              <div
                className="mob-approved-btn"
                onClick={() =>
                  window.confirm("Have you received all the items ?") &&
                  handleReceiveStatus("project", null)
                }
              >
                Received
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default MobApproved;
