import React, { useEffect, useState } from "react";
import OfferTable from "../components/offerTable";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import Loader from "react-loader-spinner";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";
import { Resource } from "../../../shared/constants/ResourceVariables";
import icons from "../../../assets/icons/Icons";
import "../styles.scss";
import Modal from "react-bootstrap/Modal";

const OfferTableContainer = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [offerData, setOfferData] = useState(null);

  const [tableData, setTableData] = useState(null);
  const [uploadDocFile, setUploadDocFile] = useState(null);
  const [upStatus, setUpStatus] = useState(false);
  const [offerList, setOfferList] = useState([]);

  const handleFilePath = (e) => {
    setUploadDocFile(e.target.files[0]);
  };

  const downloadFile = (element) => {
    if (element?.documents) {
      return (
        <div className="download-div">
          <div
            className="download-style"
            onClick={() => downloadSavedFile(element.offerNumber)}
          >
            {icons.download}
          </div>

          <div style={{ fontSize: "12px" }}>{element.documents.name}</div>
        </div>
      );
    } else {
      return (
        <div className="download-div">
          <div className="download-style" onClick={()=>downloadTemplateFile(element.offerNumber)}>
            {icons.download}
          </div>
        </div>
      );
    }
  };

  const uploadFile = () => {
    return (
      <div className="download-div">
        <div className="download-style" onClick={() => setModalShow(true)}>
          {icons.upload}
        </div>
      </div>
    );
  };

  const columns = [
    "Offer No",
    "Company",
    "Given By",
    "Date",
    "Download",
    "Upload",
  ];

  useEffect(() => {
    const getOfferList = async () => {
      try {
        const response = await ApiHandler.getAllOffers(props.year);
        setOfferList(response.results);
      } catch (error) {}
    };
    if (props.year) {
      getOfferList();
    }
  }, [props.status, props.year, upStatus]);

  const offerTables = () => {
    if (offerList) {
      setUpStatus(false);
      const tempTableData = [];
      offerList.length > 0 &&
        offerList.map((element, index) => {
          const data = [
            element.offerNumber,
            element.companyName,
            element.givenBy,
            moment(element.createdAt).format("DD-MM-YYYY  hh:mm a"),
            downloadFile(element),
            uploadFile(),
          ];
          tempTableData.push(data);
        });
      setTableData(tempTableData);
    } else {
      return;
    }
  };

  useEffect(() => {
    offerTables();
  }, [offerList]);

  const options = {
    rowsPerPageOptions: [5, 4, 3],
    rowsPerPage: 5,
    onRowClick: (data, index) => {},
    onCellClick: (data, index) => {
      setOfferData(offerList && offerList[index.dataIndex].offerNumber);
    },
    selectableRows: false,
  };

  const handleFileSubmit = async () => {
    if (uploadDocFile && offerData) {
      try {
        const formData = new FormData();
        formData.append("uploadedFile", uploadDocFile);

        const data = await ApiHandler.offerFileUpload(offerData, formData);
        setOfferData("");
        setUploadDocFile(null);
        handleModalClose();
        if (data.status === "OK") {
          setUpStatus(true);
          NotificationManager.success("File uploaded Successfully", "", 2000);
        } else {
          NotificationManager.warning(data.message, "", 2000);
        }
      } catch (error) {
        throw error;
      }
    } else {
      NotificationManager.warning("Please select the file to upload", "", 2000);
    }
  };

  const downloadSavedFile = async (offerNumber) => {
    return (window.location.href =
      Resource.url.baseUrl + `/offers/file/download/?oid=${offerNumber}`);
  };

  const downloadTemplateFile = async (offerNumber) => {
    return (window.location.href =
      Resource.url.baseUrl + `/offers/file/template/download/?oid=${offerNumber}`);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setOfferData(null);
    setUploadDocFile(null);
  };

  return (
    <div>
      <Modal
        size="sm"
        show={modalShow}
        onHide={() => handleModalClose()}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
          <Modal.Title style={{ color: "white", fontSize: "16px" }}>
            Upload Offer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-cover">
            <input type="file" onChange={(e) => handleFilePath(e)} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <div
              style={{
                backgroundColor: "#7764E4",
                padding: "5px 7px",
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => handleFileSubmit()}
            >
              Submit
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {tableData ? (
        <OfferTable columns={columns} options={options} tableData={tableData} />
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="#7764e4"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </div>
      )}
    </div>
  );
};

export default OfferTableContainer;
