import React, { useState, useEffect } from "react";
import SMSupplier from "../components/SMSupplier";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useParams } from "react-router-dom";

function SMSupplierContainer(props) {
  const [supList, setSupList] = useState([]);
  const [supName, setSupName] = useState("");
  const [orderInfo, setOrderInfo] = useState({});

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiHandler.supplierList();
        setSupList(data.results);
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiHandler.purchaseOrderInfo(id);
        setOrderInfo(data.results);
        setSupName(data.results.supplierInfo && data.results.supplierInfo._id);
      } catch (error) {}
    };

    fetchData();
  }, [props.refresh, props.poRefresh]);

  const handleChange = (value) => {
    setSupName(value);
  };

  const addSupplier = async () => {
    try {
      if (supName) {
        const add = await props.addSupplier({
          supplierId: supName,
        });
        //setSupName("");
      } else {
        NotificationManager.warning("Please select the supplier", "", 2000);
      }
    } catch (error) {}
  };
  return (
    <SMSupplier
      supList={supList}
      handleChange={handleChange}
      supName={supName}
      orderInfo={orderInfo}
      addSupplier={addSupplier}
    />
  );
}

export default SMSupplierContainer;
