import React, { useState, useEffect } from "react";
import Employees from "../components/Employees";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import moment from "moment";
import { withClaims } from "../../../shared/components/Session";
import { NotificationManager } from "react-notifications";
function EmployeesContainer(props) {
  const [modalShow, setModalShow] = useState(false);
  const [status, setStatus] = useState(false);
  const [values, setValues] = useState({
    employeeId: "",
    name: "",
    joiningDate: "",
    jobRole: "",
  });
  const [totalEmployees, setTotalEmployess] = useState("");
  const [apiResult, setApiResult] = useState([]);
  const [role, setRole] = useState("admin");
  const [issubbmitted, setIsSubbmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (changedValue, type) => {
    values[type] = changedValue;
    setValues({ ...values });
  };
  const handleOpen = (status) => {
    setModalShow(status);
  };
  const handleClose = (status) => {
    setModalShow(status);
  };
  useEffect(() => {
    setRole(props.claimsData && props.claimsData.role);
  }, [props.claimsData]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getEmployeeTableDetails();
      setApiResult(response.results);
      setTotalEmployess(response.results.length);
    };
    fetchData();
  }, [status]);

  useEffect(() => {
    const editInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        const modifiedData = {
          customId: values.employeeId,

          name: values.name,
          jobRole: values.jobRole,
          doj: moment(values.joiningDate)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        };
        try {
          const result = await ApiHandler.postEmployeeDetails(modifiedData);
          NotificationManager.success("Added Successfully", "", 2000);
          setStatus(!status);
        } catch (error) {}
      }
    };
    editInfo();
  }, [errors]);
  const validate = () => {
    let errors = {};
    if (!values.employeeId) {
      errors.employeeId = "Employee Id is required";
      NotificationManager.warning(errors.category, "", 2000);
      setErrors(errors);
      return;
    }
    if (!values.name) {
      errors.name = "Name is required";
      NotificationManager.warning(errors.category, "", 2000);
      setErrors(errors);
      return;
    }
    if (!values.jobRole) {
      errors.jobRole = "Job Role is required";
      NotificationManager.warning(errors.category, "", 2000);
      setErrors(errors);
      return;
    }
    setErrors(errors);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubbmitted(true);
    validate();
    handleClose(false);
  };
  return (
    <Employees
      modalShow={modalShow}
      handleOpen={handleOpen}
      handleClose={handleClose}
      values={values}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      status={status}
      role={role}
      apiResult={apiResult}
      totalEmployees={totalEmployees}
    />
  );
}

export default withClaims(EmployeesContainer);
