import React from "react";
import "../styles.scss";
import EmployeeViewTable from "../containers/EmployeeViewTableContainer";
import Button from "react-bootstrap/Button";
import { moment } from "moment";
import Modal from "react-bootstrap/Modal";
import icons from "../../../assets/icons/Icons";
function EmployeeView({
  handleChange,
  values,
  handleSubmit,
  modalShow,
  handleOpen,
  handleClose,
  activeUserRole,
  handleGrantChange,
  grantValues,
  submitGrantRequest,
  role,
  handleBack,
  createdAt,
  grantAccess,
  handleActivity,
  activity,
}) {
  return (
    <div className="detailed-view-outer-cover">
      <div className="employeeview-inner-cover">
        <div className="buttons-cover">
          <div
            onClick={() => handleBack()}
            className="detailed-po-topbar-arrw-wrapper"
          >
            {icons.backArrow}
          </div>
          <div style={{ paddingLeft: "20px" }}>
            Created Date: <span style={{ color: "green" }}>{createdAt}</span>
          </div>
          {role === "super_admin" && activity === true ? (
            <Button
              disabled={grantAccess === true}
              variant="link"
              variant="success"
              size="sm"
              className="grant"
              onClick={() => {
                handleOpen(true);
              }}
            >
              {grantAccess === true ? "Access Granted" : "Grant access to tool"}
            </Button>
          ) : null}
          {role === "super_admin" ? (
            <Button
              disabled={activity === false}
              onClick={(e) => {
                handleActivity(e);
              }}
              variant="link"
              variant="danger"
              size="sm"
              className="status"
            >
              inactive
            </Button>
          ) : null}
        </div>

        <Modal
          size="md"
          show={modalShow}
          onHide={() => handleClose(false)}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
            <Modal.Title style={{ color: "white", fontSize: "16px" }}>
              Grant access to tool
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-cover">
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="jobRole">
                  Access Role
                </label>

                <select
                  className="profile-input"
                  name="userRole"
                  id="userRole"
                  onChange={(e) => {
                    handleGrantChange(e.target.value, "role");
                  }}
                  values={grantValues.userRole}
                >
                  <option value="">Please Select...</option>
                  <option value="super_admin">Super Admin</option>
                  <option value="admin">Admin</option>
                  <option value="store">Store Manager</option>
                  <option value="purchase">Purchase Manager</option>
                  <option value="supervisor">Supervisor</option>
                </select>
              </div>
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="name">
                  password
                </label>

                <input
                  className="profile-input"
                  type="text"
                  id="userPass"
                  name="userPass"
                  onChange={(e) => {
                    handleGrantChange(e.target.value, "password");
                  }}
                  values={grantValues.userPass}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              type="submit"
              onClick={() => submitGrantRequest(values.employeeId)}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
        <form>
          <div className="employeeview-form-cover">
            <div className="employee-fields-label-cover">
              <label className="employee-profile-label" htmlFor="employeeId">
                Employee Id
              </label>

              <input
                className="employee-profile-input"
                type="text"
                id="employeeId"
                name="employeeId"
                onChange={(e) => {
                  handleChange(e.target.value, "employeeId");
                }}
                value={values.employeeId}
                disabled
              />
            </div>
            <div className="employee-fields-label-cover">
              <label className="employee-profile-label" htmlFor="joinDate">
                Joining Date
              </label>

              <input
                className="employee-profile-input"
                id="joinDate"
                name="joinDate"
                type="date"
                value={values.joinDate}
                onChange={(e) => {
                  handleChange(e.target.value, "joinDate");
                }}
              />
            </div>
          </div>
          <div className="employeeview-form-cover">
            <div className="employee-fields-label-cover">
              <label className="employee-profile-label" htmlFor="name">
                Name
              </label>

              <input
                disabled={role === "storeManager" || activity === false}
                className="employee-profile-input"
                type="name"
                id="name"
                name="name"
                onChange={(e) => {
                  handleChange(e.target.value, "name");
                }}
                value={values.name}
              />
            </div>
            <div className="employee-fields-label-cover">
              <label className="employee-profile-label" htmlFor="jobRole">
                Job Role
              </label>

              <select
                className="employee-profile-input"
                type="text"
                id="jobRole"
                name="jobRole"
                onChange={(e) => {
                  handleChange(e.target.value, "jobRole");
                }}
                value={values.jobRole}
              >
                <option value="EXECUTIVE OFFICER">EXECUTIVE OFFICER</option>
                <option value="HRD INCHARGE">HRD INCHARGE</option>
                <option value="ACCOUNTANT">ACCOUNTANT</option>
                <option value="SKILLED">SKILLED</option>
                <option value="HIGH SKILLED">HIGH SKILLED</option>
                <option value="SEMI SKILLED">SEMI SKILLED</option>
                <option value="UNSKILLED">UNSKILLED</option>
              </select>
            </div>
          </div>
          {(role === "super_admin" || role === "admin") && activity === true ? (
            <div className="buttons">
              <Button
                type="submit"
                variant="outline-success"
                size="sm"
                className="update"
                block
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {" "}
                Update
              </Button>
            </div>
          ) : null}
        </form>
        <div className="employeeview-table-cover">
          {/*<EmployeeViewTable />*/}
        </div>
      </div>
    </div>
  );
}

export default EmployeeView;
