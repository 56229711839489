import React from "react";
import "../styles.scss";

function UserDetails({ logout, userData }) {
  return (
    <div className="user-detail-wrapper">
      <div className="user-detail-title">USER DETAILS</div>
      <div className="user-detail-input-title-data-wrapper">
        <div className="user-detail-input-title-data-container">
          <div className="user-detail-input-title">Name</div>
          <div className="user-detail-data">
            {userData && userData.name ? userData.name : "Loading..."}
          </div>
        </div>
        <div className="user-detail-input-title-data-container">
          <div className="user-detail-input-title">Employee Id</div>
          <div className="user-detail-data">
            {userData && userData.customId ? userData.customId : "Loading..."}
          </div>
        </div>
        <div className="user-detail-input-title-data-container">
          <div className="user-detail-input-title">Job Role</div>
          <div className="user-detail-data">
            {userData && userData.jobRole ? userData.jobRole : "Loading..."}
          </div>
        </div>
        <div className="user-detail-logout-container">
          <div className="user-detail-logout-btn" onClick={() => logout()}>
            SIGN OUT
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
