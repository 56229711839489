import axios from "axios";
import firebase from "../components/Firebase";
import { Resource } from "../constants/ResourceVariables";

axios.defaults.baseURL = Resource.url.baseUrl;

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const authHeader = (token) => ({
  ...(token && { Authorization: `Bearer ${token}` }),
});

export const apiAuth = {
  getApiwithAuth: async (url) => {
    const idToken = await firebase.getIdToken();

    const headers = authHeader(idToken);

    return await axios.get(url, {
      headers: headers,
    });
  },

  postApiwithAuth: async (url, data) => {
    const idToken = await firebase.getIdToken();
    const headers = authHeader(idToken);
    return await axios.post(url, data, {
      headers: headers,
    });
  },

  deleteApiwithAuth: async (url) => {
    const idToken = await firebase.getIdToken();
    const headers = authHeader(idToken);
    return await axios.delete(url, {
      headers: headers,
    });
  },

  putApiwithAuth: async (url, data) => {
    const idToken = await firebase.getIdToken();
    const headers = authHeader(idToken);
    return await axios.put(url, data, {
      headers: headers,
      "Content-Type": "multipart/form-data",
    });
  },

  getApiwithoutAuth: async (url) => {
    return await axios.get(url);
  },

  postApiwithoutAuth: async (url, data) => {
    return await axios.post(url, data);
  },
  deleteApiwithoutAuth: async (url) => {
    return await axios.delete(url);
  },
  putApiwithoutAuth: async (url, data) => {
    return await axios.put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  putApiwithoutHeader: async (url, data) => {
    return await axios.put(url, data);
  },
};
