import React from "react";
import "../styles.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InventoryConsumableTable from "../containers/InventoryConsumableTableContainer";
import SelectSearch from "react-select-search";

import icons from "../../../assets/icons/Icons";
function Inventory({
  btnSelected,
  handleTabs,
  modalShow,
  handleOpen,
  handleClose,
  values,
  handleChange,
  handleSubmit,
  status,
  units,
  userRole,
}) {
  return (
    <div className="inventory-outer-cover">
      <div className="inventory-inner-cover">
        {(userRole === "store" || userRole === "purchase") && (
          <Button
            variant="link"
            variant="outline-info"
            size="sm"
            className="button "
            block
            onClick={() => {
              handleOpen(true);
            }}
          >
            Add New Item
          </Button>
        )}
        <Modal
          size="md"
          show={modalShow}
          onHide={() => handleClose(false)}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
            <Modal.Title style={{ color: "white", fontSize: "16px" }}>
              Add New Item
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-cover">
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="companyName">
                  Category
                </label>

                <select
                  className="profile-input"
                  name="category"
                  id="category"
                  onChange={(e) => {
                    handleChange(e.target.value, "category");
                  }}
                  value={values.category}
                  required
                >
                  <option value="">Please Select...</option>
                  <option value="MATERIAL">MATERIAL</option>
                  <option value="MACHINE">MACHINE</option>
                  <option value="TOOLS">TOOLS</option>
                  <option value="CONSUMABLE">CONSUMABLE</option>
                </select>
              </div>
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="itemName">
                  Item Name
                </label>

                <input
                  className="profile-input"
                  type="text"
                  id="itemName"
                  name="itemName"
                  required
                  onChange={(e) => {
                    handleChange(e.target.value, "itemName");
                  }}
                />
              </div>
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="itemType">
                  Type/size
                </label>

                <input
                  className="profile-input"
                  type="text"
                  id="itemType"
                  name="itemType"
                  onChange={(e) => {
                    handleChange(e.target.value, "itemType");
                  }}
                  required
                />
              </div>

              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="itemCode">
                  Item Code
                </label>

                <input
                  className="profile-input"
                  type="text"
                  id="itemCode"
                  name="itemCode"
                  onChange={(e) => {
                    handleChange(e.target.value, "itemCode");
                  }}
                  required
                />
              </div>
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="itemCode">
                  description
                </label>

                <input
                  className="profile-input"
                  type="text"
                  id="description"
                  name="description"
                  onChange={(e) => {
                    handleChange(e.target.value, "description");
                  }}
                  required
                />
              </div>
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="companyName">
                  Units
                </label>

                <SelectSearch
                  className="select-search-box"
                  name="units"
                  placeholder="Search"
                  id="units"
                  onChange={({ value }) => {
                    handleChange(value, "units");
                  }}
                  value={values.units}
                  options={
                    units &&
                    units.map((standard, id) => {
                      return { name: standard.name, value: standard.name };
                    })
                  }
                />
              </div>

              <Modal.Footer>
                <Button
                  type="submit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  variant="success"
                >
                  Add
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
        <div className="header">Inventory</div>

        <div className="table-cover">
          {btnSelected === 1 && <InventoryConsumableTable status={status} />}
        </div>
      </div>
    </div>
  );
}

export default Inventory;
