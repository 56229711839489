import React from "react";
import "../styles.scss";
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";

function Dashboard(props) {
  const {
    purchase_data,
    purchase_option,
    req_data,
    req_option,
    project_data,
    project_option,
    employee_data,
    employee_option,
    employee_role_data,
    employee_role_option,
    projectItemCost,
    purchase_sample_data,
    purchase_sample_option,
    totalActiveEmp,
    projectStatus,
    projWorkHrs,
  } = props;
  return (
    <div className="dashboard-outer-cover">
      <div className="dashboard-title">DASHBOARD</div>

      <div className="dash-data-container">
        <div className="dash-emp-container">
          <div className="dash-emp-1">
            <div className="dash-emp-1-title">Total Active Employees</div>
            <div className="dash-emp-1-value">
              {totalActiveEmp && totalActiveEmp.emp
                ? totalActiveEmp.emp
                : "..."}
            </div>
          </div>
          <div className="dash-emp-1">
            <div className="dash-emp-1-title">
              Total Active Employees With Tool Access
            </div>
            <div className="dash-emp-1-value">
              {totalActiveEmp && totalActiveEmp.activeEmp
                ? totalActiveEmp.activeEmp
                : "..."}
            </div>
          </div>
        </div>
        <div className="dash-project-container">
          <div className="dash-proj-1">
            <div className="dash-proj-1-title">Active Project List</div>

            <table className="dash-proj-tab-1">
              <thead></thead>
              <tbody>
                <tr>
                  <th className="proj-tab-header">Project Name</th>
                  <th className="proj-tab-header">Status</th>
                  <th className="proj-tab-header">Total Work Hrs</th>
                </tr>

                {projectStatus.active &&
                  projectStatus.active.length > 0 &&
                  projectStatus.active.map((proj, i) => {
                    return (
                      <tr key={i}>
                        <td className="proj-tab-data">{proj}</td>
                        <td className="proj-tab-data ongoing">Active</td>
                        <td className="proj-tab-data ">
                          {projWorkHrs[`${proj}`]}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="dash-proj-1">
            <div className="dash-proj-1-title">Completed Project List</div>
            <table className="dash-proj-tab-1">
              <thead></thead>
              <tbody>
                <tr>
                  <th className="proj-tab-header">Project Name</th>
                  <th className="proj-tab-header">Status</th>
                </tr>

                {projectStatus.completed &&
                  projectStatus.completed.length > 0 &&
                  projectStatus.completed.map((proj, i) => {
                    return (
                      <tr key={i}>
                        <td className="proj-tab-data">{proj}</td>
                        <td className="proj-tab-data completed">Completed</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="proj-cost-container">
          <div className="proj-cost-title">Project cost table</div>
          <table className="proj-cost-table">
            <thead></thead>
            <tbody>
              <tr>
                <th className="proj-cost-title">Project Name</th>
                <th className="proj-cost-title">Material Cost</th>
                <th className="proj-cost-title">Consumable Cost</th>
              </tr>
              {projectItemCost &&
                projectItemCost.length > 0 &&
                projectItemCost.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="proj-cost-data">{data.name}</td>
                      <td className="proj-cost-data">
                        {data.MATERIAL ? data.MATERIAL : 0}
                      </td>
                      <td className="proj-cost-data">
                        {data.CONSUMABLE ? data.CONSUMABLE : 0}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row-fluid">
        <div className="col-10 mx-auto">
          <div className="mt-3 mb-5 graph_small">
            <div className="graph-title"> Requests per month</div>
            <div className="row row-style-doughnut">
              <div className="col-9">
                <Bar data={req_data} options={req_option} />
              </div>
            </div>
          </div>

          <div className="mt-3 mb-5 graph_small">
            <div className="graph-title">Purchase Orders</div>
            <div className="row">
              <div className="col-6">
                <Bar data={purchase_data} options={purchase_option} />
              </div>
              <div className="col-6">
                <Bar
                  data={purchase_sample_data}
                  options={purchase_sample_option}
                  style={{ height: "100%" }}
                />
              </div>
            </div>
          </div>

          <div className="mt-3 mb-5 graph_small">
            <div className="graph-title"> Projects </div>

            <div className="row row-style-doughnut">
              <div className="col-8">
                <Doughnut
                  data={project_data}
                  options={project_option}
                  style={{ height: "100%" }}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12">
                <Bar
                  data={project_loc_data}
                  options={project_loc_option}
                  style={{ height: "100%" }}
                />
              </div>
            </div> */}
          </div>

          <div className="mt-3 mb-5 graph_small">
            <div className="graph-title">Employees</div>
            <div className="row">
              <div className="col-6 mx-left">
                <Pie
                  data={employee_data}
                  options={employee_option}
                  style={{ height: "100%" }}
                />
              </div>
              <div className="col-6 mx-right">
                <Doughnut
                  data={employee_role_data}
                  options={employee_role_option}
                  style={{ height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
