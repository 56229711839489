import React, { useEffect, useState } from "react";
import DetailedRequestTable3 from "../components/DetailedRequestTable3";
import { NotificationManager } from "react-notifications";

function DetailedRequestTable3Container(props) {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const columns = [
    "Item Name",
    "Size",
    "Description",
    "Return Quantity",

    "To store",
    "To Other Projects",
  ];
  const options = {
    rowsPerPageOptions: [5, 4],
    rowsPerPage: 5,

    selectableRows: "none",
    disableToolbarSelect: true,
  };
  useEffect(() => {
    setData(props.data);
  }, [props.data, props.status]);

  useEffect(() => {
    const addTable = async () => {
      const tempTableData =
        data &&
        data.length > 0 &&
        data.map((element, index) => [
          element.itemInfo.name,
          element.itemInfo && element.itemInfo.type
            ? element.itemInfo.type
            : "N/A",
          element.itemInfo && element.itemInfo.description
            ? element.itemInfo.description
            : "N/A",
          element.quantity,
          <input
            disabled={
              props.userRole !== "store" ||
              props.status === "return_approved" ||
              props.status === "return_completed" ||
              props.status === "return_initiated"
            }
            type="number"
            onChange={(e) => {
              handleStoreChange(e.target.valueAsNumber, element, index);
            }}
            value={element.toStore.quantity}
          ></input>,
          <div>
            {element.toProjects &&
              element.toProjects.length > 0 &&
              element.toProjects.map((info, i) => {
                return (
                  <div key={i}>
                    {info.quantity}
                    <span style={{ paddingLeft: "5px" }}>-</span>
                    <span style={{ paddingLeft: "5px" }}>
                      {info.projectInfo.name}
                    </span>
                  </div>
                );
              })}
          </div>,
        ]);

      setTableData(tempTableData);
    };
    if (data && data.length > 0) {
      addTable();
    }
  }, [data]);

  const handleStoreChange = async (changedValue, element, index) => {
    const totalQty = data[index].toProjects.reduce(
      (total, i) => total + i.quantity,
      0
    );
    if (changedValue + totalQty <= element.quantity) {
      element.toStore.quantity = changedValue;
      setData([...data]);
    } else {
      NotificationManager.warning(
        "Qty cannot be more than of return qty",
        "",
        2000
      );
      element.toStore.quantity = 0;
      setData([...data]);
    }
  };

  return (
    <DetailedRequestTable3
      columns={columns}
      options={options}
      data={tableData}
    />
  );
}

export default DetailedRequestTable3Container;
