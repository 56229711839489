import React from "react";
import "../styles.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SelectSearch from "react-select-search";
import PurchaseOrderTableContainer from "../containers/PurchaseOrderTableContainer";
// import EmployeesTable from "../containers/EmployeesTableContainer";
import DetailedPurchaseOrderView from "../../DetailedPurchaseOrderView";
function PurchaseOrder({
  modalShow,
  handleOpen,
  handleClose,
  projects,
  items,
  employees,
  newOrderValues,
  handleInputChange,
  handleAddOrder,
  userRole,
  year,
  yearOptions,
  handleYearChange,
  orders,
}) {
  return (
    <div className="po-outer-cover">
      <div className="po-inner-cover">
        {/*{(userRole === "purchase" || userRole === "store") && (
          <Button
            variant="link"
            variant="outline-info"
            size="sm"
            className="button"
            block
            onClick={() => {
              handleOpen(true);
            }}
          >
            {" "}
            Create New Order
          </Button>
        )}
        <Modal
          size="md"
          show={modalShow}
          onHide={() => handleClose(false)}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
            <Modal.Title style={{ color: "white", fontSize: "16px" }}>
              Create New Purchase Order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-cover">
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="employeeId">
                  Employee Name
                </label>

                <SelectSearch
                  id="employee"
                  className="select-search-box"
                  name="employee"
                  options={employees.map((employee) => {
                    return { name: employee.name, value: employee._id };
                  })}
                  value={newOrderValues.employeeId}
                  onChange={({ value }) => {
                    handleInputChange(value, "employeeId");
                  }}
                />
              </div>
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="project">
                  Project
                </label>

                <SelectSearch
                  id="project"
                  className="select-search-box"
                  name="project"
                  options={projects.map((project) => {
                    return { name: project.name, value: project._id };
                  })}
                  value={newOrderValues.projectId}
                  onChange={({ value }) => {
                    handleInputChange(value, "projectId");
                  }}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="success" onClick={handleAddOrder}>
              Create
            </Button>
          </Modal.Footer>
        </Modal> */}
        <div className="header">
          <div>PURCHASE ORDER LIST</div>
          <div>
            <span style={{ fontSize: "14px", paddingRight: "5px" }}>
              Select Financial Year:
            </span>
            <select
              style={{ width: "100px" }}
              value={year}
              onChange={(e) => handleYearChange(e.target.value)}
            >
              <option></option>
              {yearOptions &&
                yearOptions.length > 0 &&
                yearOptions.map((year, i) => {
                  return (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="table-cover">
          <PurchaseOrderTableContainer orders={orders} />
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrder;
