import React from "react";
import "../styles.scss";
import images from "../../../../shared/constants/ImageVariables";

function Loader() {
  return (
    <div className="mob-loader-style">
      <img src={images.loader2} />
    </div>
  );
}

export default Loader;
