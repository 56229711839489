import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";

function EmployeesTable({ tableData, columns, options }) {
  return (
    tableData && (
      <MUIDataTable data={tableData} columns={columns} options={options} />
    )
  );
}

export default EmployeesTable;
