import React, { Fragment } from "react";
import "../styles.scss";
import icons from "../../../assets/icons/Icons";
import SelectSearch from "react-select-search";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import MUIDataTable from "mui-datatables";
import SMOptions from "../containers/SMOptionsContainer";

function DetailedPurchaseOrderView({
  orderInfo,
  deletePO,
  handleBack,
  itemList,
  addItem,
  handleChange,
  handleUnitChange,
  handleUnitUpdate,
  submitItem,
  options,
  table,
  columns,
  handleClose,
  editUnit,
  statusHandler,
  receiveModalShow,
  receivedQtyHandler,
  userRole,
  poRefresh,
  poExport,
  addSupplier,
}) {
  const status = {
    open: "Open",
    a_approval: "Sent for Admin Approval",
    a_approved: "Admin Approved",
    sa_approved: "Super Admin Approved",
    rejected: "Rejected",
    vp: "Vendor Proccessing",
    delivered: "Received",
  };

  let getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiPaper: {
          root: {
            zIndex: 1,
          },
        },
      },
    });

  return (
    <Fragment>
      <Modal
        size="md"
        show={receiveModalShow}
        onHide={() => handleClose()}
        animation={true}
        centered
      >
        <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
          <Modal.Title style={{ color: "white", fontSize: "16px" }}>
            Add Received Quantity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="po-form-modal-container">
            <div style={{ fontSize: "14px" }}>
              Ordered Quantity:
              <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                {editUnit.quantity}
              </span>
            </div>
            <div style={{ fontSize: "14px" }}>
              Total received Quantity:
              <span style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                {editUnit.receivedQuantity}
              </span>
            </div>

            <div className="po-form-modal-title">Add Received Quantity</div>
            <input
              type="number"
              className="po-form-modal-input"
              value={editUnit.addReceived}
              onChange={(e) =>
                handleUnitChange(e.target.valueAsNumber, "addReceived")
              }
            />
            <div className="po-form-modal-btn-container">
              <div
                className="po-form-modal-btn"
                onClick={() => receivedQtyHandler()}
              >
                ADD
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="detailed-po-view-outer-cover">
        <div className="detailed-po-view-inner-cover">
          <div className="detailed-po-topbar-container">
            <div className="detailed-po-topbar-arrw-container">
              <div
                className="detailed-po-topbar-arrw-wrapper"
                onClick={() => handleBack()}
              >
                {icons.backArrow}
              </div>
            </div>
            <div className="detailed-po-topbar-po-no-container"></div>
            <div className="detailed-po-topbar-option-common-container emp-style">
              Employee:
              <span
                style={{
                  paddingLeft: "5px",
                  fontSize: "13px",
                  fontWeight: "normal",
                }}
              >
                {orderInfo.purchasedFor
                  ? orderInfo.purchasedFor.name
                  : "Loading..."}
              </span>
            </div>
            <div className="detailed-po-topbar-option-common-container proj-style">
              Project:
              <span
                style={{
                  paddingLeft: "5px",
                  fontSize: "13px",
                  fontWeight: "normal",
                }}
              >
                {orderInfo.projectInfo
                  ? orderInfo.projectInfo.name
                  : "Loading..."}
              </span>
            </div>
            <div className="detailed-po-topbar-option-common-container status-style">
              Status:
              <span
                style={{
                  paddingLeft: "5px",
                  fontSize: "13px",
                  fontWeight: "normal",
                }}
              >
                {orderInfo.status ? status[orderInfo.status] : "Loading..."}
              </span>
            </div>
            <div className="detailed-po-topbar-del-container">
              {userRole === "purchase" &&
                orderInfo.status &&
                (orderInfo.status === "open" ||
                  orderInfo.status === "rejected") && (
                  <div
                    className="detailed-po-topbar-del-wrapper"
                    onClick={() =>
                      window.confirm(
                        "Are you sure you want to delete the Purcahse order?"
                      ) && deletePO()
                    }
                  >
                    DELETE PO
                  </div>
                )}
            </div>
          </div>
          {userRole === "purchase" &&
            orderInfo.status &&
            orderInfo.status === "open" && (
              <div className="detailed-po-add-itm-container">
                <div className="detailed-po-add-itm-title">ADD NEW ITEM</div>
                <div className="detailed-po-add-itm-ip-container">
                  <SelectSearch
                    className="select-search-box"
                    placeholder=" Search Item"
                    search
                    onChange={({ value }) => handleChange(value, "itemName")}
                    value={addItem.itemName}
                    options={
                      itemList &&
                      itemList.map((itm, i) => {
                        return {
                          name: itm.concatName,
                          value: itm._id,
                        };
                      })
                    }
                  />
                  <div className="detailed-po-itm-more-info-container">
                    <div>
                      Description:
                      <span style={{ fontWeight: "bold", paddingLeft: "2px" }}>
                        {addItem.description
                          ? addItem.description
                          : "Select item"}
                      </span>
                    </div>
                    <div>
                      Unit:
                      <span style={{ fontWeight: "bold", paddingLeft: "2px" }}>
                        {addItem.unit ? addItem.unit : "Select item"}
                      </span>
                    </div>
                    <div>
                      Type:
                      <span style={{ fontWeight: "bold", paddingLeft: "2px" }}>
                        {addItem.type ? addItem.type : "Select item"}
                      </span>
                    </div>
                  </div>
                  <input
                    type="number"
                    className="detailed-po-ip-qty-container"
                    value={addItem.qty}
                    placeholder="quantity"
                    onChange={(e) =>
                      handleChange(e.target.valueAsNumber, "qty")
                    }
                  />
                  <div className="detailed-po-ip-sub-container">
                    <div
                      className="detailed-po-ip-sub"
                      onClick={() => submitItem()}
                    >
                      ADD
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="detailed-po-item-table-container">
            <div className="detailed-po-item-table-title-export-wrapper">
              <div className="detailed-po-item-table-title">ITEM LIST</div>
              {orderInfo &&
                orderInfo.status !== "rejected" &&
                orderInfo.items &&
                orderInfo.items.length > 0 &&
                userRole === "purchase" && (
                  <div
                    className="detailed-po-item-export"
                    onClick={() => poExport(orderInfo.status)}
                  >
                    EXPORT
                  </div>
                )}
            </div>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable data={table} options={options} columns={columns} />
            </MuiThemeProvider>
            <div className="total-container">
              <b>
                <div>
                  TOTAL :{" "}
                  {orderInfo.items && orderInfo.items.length > 0 ? (
                    orderInfo.items
                      .map((item) => {
                        return item.unitPrice * item.quantity;
                      })
                      .reduce((total, item) => {
                        return total + item;
                      })
                      .toFixed(2)
                  ) : (
                    <span>0</span>
                  )}
                </div>
              </b>
            </div>
            <div className="detailed-po-status-container">
              {userRole === "purchase" &&
                orderInfo.items &&
                orderInfo.items.length > 0 &&
                orderInfo.status === "open" && (
                  <div
                    className="detailed-po-status-btn"
                    onClick={() =>
                      window.confirm(
                        "Are you sure you want to update the unit price ?"
                      ) && handleUnitUpdate()
                    }
                  >
                    UPDATE UNIT PRICE
                  </div>
                )}
              {userRole === "purchase" &&
                orderInfo.items &&
                orderInfo.items.length > 0 &&
                orderInfo.status === "open" && (
                  <div
                    className="detailed-po-status-btn"
                    onClick={() =>
                      window.confirm(
                        "Are you sure you want to send for approval ?"
                      ) && statusHandler("a_approval")
                    }
                  >
                    SEND FOR APPROVAL
                  </div>
                )}
              {userRole === "purchase" &&
                orderInfo.items &&
                orderInfo.status === "sa_approved" && (
                  <div
                    className="detailed-po-status-btn"
                    onClick={() =>
                      window.confirm(
                        "Are you sure you want to raise Purchase Order ?"
                      ) && statusHandler("vp")
                    }
                  >
                    Raise Purchase Order
                  </div>
                )}
              {userRole === "purchase" &&
                orderInfo.items &&
                orderInfo.status === "vp" && (
                  <div
                    className="detailed-po-status-btn"
                    onClick={() =>
                      window.confirm(
                        "Are you sure you want to mark all the Purchase Order items as received, partial received items will also be considered as received"
                      ) && statusHandler("delivered")
                    }
                  >
                    Received
                  </div>
                )}

              {userRole === "admin" &&
                orderInfo.items &&
                orderInfo.status === "a_approval" && (
                  <Fragment>
                    <div
                      className="detailed-po-status-btn"
                      onClick={() =>
                        window.confirm(
                          "Are you sure you want to approve the purchase order ?"
                        ) && statusHandler("a_approved")
                      }
                    >
                      APPROVE
                    </div>
                    <div
                      className="detailed-po-status-btn del-status"
                      onClick={() =>
                        window.confirm(
                          "Are you sure you want to reject the purchase order ?"
                        ) && statusHandler("rejected")
                      }
                    >
                      REJECT
                    </div>
                  </Fragment>
                )}

              {userRole === "super_admin" &&
                orderInfo.items &&
                orderInfo.status === "a_approved" && (
                  <Fragment>
                    <div
                      className="detailed-po-status-btn"
                      onClick={() =>
                        window.confirm(
                          "Are you sure you want to approve the purchase order ?"
                        ) && statusHandler("sa_approved")
                      }
                    >
                      APPROVE
                    </div>
                    <div
                      className="detailed-po-status-btn del-status"
                      onClick={() =>
                        window.confirm(
                          "Are you sure you want to reject the purchase order ?"
                        ) && statusHandler("rejected")
                      }
                    >
                      REJECT
                    </div>
                  </Fragment>
                )}
            </div>
          </div>
          <SMOptions poRefresh={poRefresh} addSupplier={addSupplier} />
        </div>
      </div>
    </Fragment>
  );
}

export default DetailedPurchaseOrderView;
