import React, { useState, useEffect } from "react";
import EmployeesTable from "../components/EmployeesTable";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Loader from "react-loader-spinner";

function EmployeesTableContainer(props) {
  const columns = [
    "SL NO",
    "Employee Id",
    "Name",
    "Joining Date",
    "Job Role",
    "Status",
    "Tool Access",
  ];
  const [tableData, setTableData] = useState([]);
  const [tableId, setTableId] = useState(null);

  useEffect(() => {
    setTableId(props.apiResult);

    const tempTableData = [];

    props.apiResult.map((element, index) => {
      const data = [
        index + 1,
        element.customId,
        element.name,
        element.doj ? moment(element.doj).format(" DD/MM/YYYY") : "-",
        element.jobRole,
        element.isActive ? (
          <div style={{ color: "green" }}>Active</div>
        ) : (
          <div style={{ color: "red" }}>Inactive</div>
        ),
        element.hasToolAccess ? (
          <div style={{ color: "green" }}>
            {element.clientInfo && element.clientInfo.role}
          </div>
        ) : (
          <div style={{ color: "red" }}>No</div>
        ),
      ];
      tempTableData.push(data);
    });
    setTableData(tempTableData);
  }, [props.apiResult]);

  const options = {
    rowsPerPageOptions: [6, 5, 4],
    rowsPerPage: 5,
    onRowClick: (data, index) => {
      props.history.push(`/tool/employeeview/${tableId[index.dataIndex]._id}`);
    },
    selectableRows: false,
  };
  return (
    <div>
      {tableData.length > 0 ? (
        <EmployeesTable
          columns={columns}
          options={options}
          tableData={tableData}
        />
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="#7764e4"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </div>
      )}
    </div>
  );
}

export default withRouter(EmployeesTableContainer);
