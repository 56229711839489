import React from "react";
import "../styles.scss";
import images from "../../../shared/constants/ImageVariables";
import { Link, withRouter } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

function SideNavBar(props) {
  let itemmatch = useRouteMatch("/tool/itemview/:id");
  let requestmatch = useRouteMatch("/tool/requestview/:id");
  let purchaseOrdermatch = useRouteMatch("/tool/order-info/:id");
  let projectsmatch = useRouteMatch("/tool/projectsview/:id");
  let employeematch = useRouteMatch("/tool/employeeview/:id");
  return (
    <div className="side-Navbar-container">
      <div className="side-bar-image-cover">
        <img className="logo" src={images.NdSonsLogo} />
      </div>
      {props.role && (props.role === "super_admin" || props.role === "admin") && (
        <Link
          to="/tool/dashboard"
          className={
            props.location.pathname === "/tool/dashboard"
              ? "sidebar-common option-selected"
              : "sidebar-common"
          }
          style={{ textDecoration: "none" }}
        >
          <div className="content-cover">
            <div className="sidebar-img-container">
              <img className="sidebar-images" src={images.dashboard}></img>
            </div>
            <span className="sidebar-names">Dashboard</span>
          </div>
        </Link>
      )}
      <Link
        to="/tool/inventory"
        className={
          props.location.pathname === "/tool/inventory" || itemmatch
            ? "sidebar-common option-selected"
            : "sidebar-common"
        }
        style={{ textDecoration: "none" }}
      >
        <div className="content-cover">
          <div className="sidebar-img-container">
            <img className="sidebar-images" src={images.inventory}></img>
          </div>
          <span className="sidebar-names">Inventory</span>
        </div>
      </Link>

      <Link
        to="/tool/requests"
        className={
          props.location.pathname === "/tool/requests" || requestmatch
            ? "sidebar-common option-selected"
            : "sidebar-common"
        }
        style={{ textDecoration: "none" }}
      >
        <div className="content-cover">
          <div className="sidebar-img-container">
            <img className="sidebar-images" src={images.requests}></img>
          </div>
          <span className="sidebar-names">Requests</span>
          <div className="sidebar-act-count">
            {props.reqCount && props.reqCount}
          </div>
        </div>
      </Link>
      {props.role && props.role !== "store" && (
        <Link
          to="/tool/purchase-order"
          className={
            props.location.pathname === "/tool/purchase-order" ||
              purchaseOrdermatch
              ? "sidebar-common option-selected"
              : "sidebar-common"
          }
          style={{ textDecoration: "none" }}
        >
          <div className="content-cover">
            <div className="sidebar-img-container">
              <img className="sidebar-images" src={images.purchase}></img>
            </div>

            <span className="sidebar-names">Purchase Order</span>
            <div className="sidebar-act-count">
              {props.poCount && props.poCount}
            </div>
          </div>
        </Link>
      )}
      <Link
        to="/tool/projects"
        className={
          props.location.pathname === "/tool/projects" || projectsmatch
            ? "sidebar-common option-selected"
            : "sidebar-common"
        }
        style={{ textDecoration: "none" }}
      >
        <div className="content-cover">
          <div className="sidebar-img-container">
            <img className="sidebar-images" src={images.projects}></img>
          </div>
          <span className="sidebar-names">Projects</span>
        </div>
      </Link>

      <Link
        to="/tool/employees"
        className={
          props.location.pathname === "/tool/employees" || employeematch
            ? "sidebar-common option-selected"
            : "sidebar-common"
        }
        style={{ textDecoration: "none" }}
      >
        <div className="content-cover">
          <div className="sidebar-img-container">
            <img className="sidebar-images" src={images.employess}></img>
          </div>
          <span className="sidebar-names">Employess</span>
        </div>
      </Link>

      <Link
        to="/tool/settings"
        className={
          props.location.pathname === "/tool/settings"
            ? "sidebar-common option-selected"
            : "sidebar-common"
        }
        style={{ textDecoration: "none" }}
      >
        <div className="content-cover">
          <div className="sidebar-img-container">
            <img className="sidebar-images" src={images.settings}></img>
          </div>
          <span className="sidebar-names">Settings</span>
        </div>
      </Link>

      <Link
        to="/tool/offers"
        className={
          props.location.pathname === "/tool/offers"
            ? "sidebar-common option-selected"
            : "sidebar-common"
        }
        style={{ textDecoration: "none" }}
      >
        <div className="content-cover">
          <div className="sidebar-img-container">
            <img className="sidebar-images" src={images.offer}></img>
          </div>
          <span className="sidebar-names">Offers</span>
        </div>
      </Link>

      <Link
        to="/tool/audits"
        className={
          props.location.pathname === "/tool/audits"
            ? "sidebar-common option-selected"
            : "sidebar-common"
        }
        style={{ textDecoration: "none" }}
      >
        <div className="content-cover">
          <div className="sidebar-img-container">
            <img className="sidebar-images" src={images.audit}></img>
          </div>
          <span className="sidebar-names">Audits</span>
        </div>
      </Link>
    </div>
  );
}

export default withRouter(SideNavBar);
