import React, { Fragment } from "react";
import moment from "moment";

import "../styles.scss";

function MobActivity({ mobActData }) {
  return (
    <div className="mob-activity-container">
      <div className="mob-activity-header">ACTIVITY LOG </div>
      <div className="mob-activity-data-wrapper">
        {mobActData &&
          mobActData.length > 0 &&
          mobActData.map((data, i) => {
            return (
              <div className="mob-activity-data-container" key={i}>
                <div className="mob-activity-date">
                  {moment(data.createdAt).format("MMM Do YYYY  h:mm a")}
                  {data.type === "created" && (
                    <span
                      style={{
                        fontWeight: "bold",
                        paddingLeft: "5px",
                        color: "green",
                      }}
                    >
                      {data.createdBy.name}
                    </span>
                  )}
                </div>
                {data.message && (
                  <div className="mob-activity-data">{data.message}</div>
                )}
                <div className="mob-activity-circle"></div>
                {mobActData.length - 1 === i ? null : (
                  <div className="mob-activity-bar"></div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MobActivity;
