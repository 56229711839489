import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../styles.scss";
import SMActivity from "../components/SMActivity";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";

function SMActivityContainer(props) {
  const [activity, setActivity] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiHandler.poActivity(id);
        setActivity(data.results);
      } catch (error) {}
    };
    fetchData();
  }, [props.refresh, props.poRefresh]);
  return <SMActivity activity={activity} />;
}

export default SMActivityContainer;
