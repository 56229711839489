import React from "react";
import "../styles.scss";

function MobileSetting({ childrenProps, userDetails, handleSignOut }) {
  return (
    <div className="mob-seetings-container">
      {childrenProps}
      <div className="mob-user-info-container">
        <div className="mob-user-title">User Details</div>
        <div className="mob-user-info-wrapper">
          <div className="mob-user-row">
            <div className="mob-user-row-title">Name</div>
            <div className="mob-user-row-data">
              {userDetails.name ? userDetails.name : "Loading..."}
            </div>
          </div>
          <div className="mob-user-row">
            <div className="mob-user-row-title">Employee ID</div>
            <div className="mob-user-row-data">
              {userDetails.customId ? userDetails.customId : "Loading..."}
            </div>
          </div>
          <div className="mob-user-row">
            <div className="mob-user-row-title">Job Role</div>
            <div className="mob-user-row-data">
              {userDetails.jobRole ? userDetails.jobRole : "Loading..."}
            </div>
          </div>
          <div className="mob-user-log-out-container">
            <div
              className="mob-user-log-out-btn"
              onClick={() => handleSignOut()}
            >
              SIGN OUT
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSetting;
