import React, { useEffect, useState } from "react";
import MobileSetting from "../components/MobileSetting";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";
import { withFirebase } from "../../../../shared/components/Firebase";
import { withClaims } from "../../../../shared/components/Session";
import { compose } from "recompose";

function MobileSettingContainer(props) {
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    let clean = true;

    const fetchData = async () => {
      try {
        const data = await ApiHandler.getIndividualEmployeeDetails(
          props.claimsData && props.claimsData.employeeId
        );
        if (clean) {
          setUserDetails(data.results);
        }
      } catch (error) {}
    };
    if (props.claimsData) {
      fetchData();
    }
    return function cleanUp() {
      clean = false;
    };
  }, [props.claimsData]);

  const handleSignOut = async () => {
    await props.firebase.doSignOut();
  };

  return (
    <MobileSetting
      childrenProps={props.children}
      userDetails={userDetails}
      handleSignOut={handleSignOut}
    />
  );
}

export default compose(withFirebase, withClaims)(MobileSettingContainer);
