import React, { Fragment } from "react";
import moment from "moment";
import "../styles.scss";
import Loader from "../../Loader";
function MobItemList({
  mobData,
  loader,
  itmTableData,
  handleReturnChange,
  handleReqReturn,
}) {
  const statusField = {
    open: "Open",
    a_approval: "Sent for Approval",
    a_approved: "Admin Approved",
    rejected: "rejected",
    shipped: "shipped",
    return_requested: "Sent for Return Request",
    received: "received",
    return_approved: "Return Request Approved",
    return_initiated: "Supervisor initiated Return",
    return_completed: "Returned",
    request_completed: "Completed",
  };
  return (
    <div className="mob-process-item-container">
      <div className="mob-process-item-header">ITEM LIST</div>
      <div className="mob-process-item-table-container">
        {loader ? (
          <Loader />
        ) : (
          <table>
            <tbody>
              <tr className="mob-process-table-title">
                <th className="mob-process-title-style">ITEM NAME</th>
                <th className="mob-process-title-style">QTY</th>
                {(mobData.status === "a_approved" ||
                  mobData.status === "received" ||
                  mobData.status === "shipped") && (
                  <th className="mob-process-title-style">APPROVED QTY</th>
                )}
                {mobData.status === "received" && (
                  <th className="mob-process-title-style">RETURN QTY</th>
                )}
              </tr>
              {mobData.items &&
                mobData.items.map((data, i) => {
                  return (
                    <tr className="mob-process-data-list" key={i}>
                      <td className="mob-process-data-style">
                        {data.itemInfo.concatName}
                      </td>
                      <td className="mob-process-data-style">
                        {data.quantity}
                      </td>
                      {(mobData.status === "a_approved" ||
                        mobData.status === "received" ||
                        mobData.status === "shipped") && (
                        <td className="mob-process-data-style">
                          {data.approvedQuantity}
                        </td>
                      )}
                      {mobData.status === "received" && (
                        <td className="mob-process-data-style">
                          <input
                            type="Number"
                            style={{
                              width: "50px",
                              outline: "none",
                              border: "1px solid black",
                            }}
                            value={itmTableData[i].returnedQuantity}
                            onChange={(e) =>
                              handleReturnChange(
                                i,
                                e.target.valueAsNumber,
                                data.approvedQuantity
                              )
                            }
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>

      {(mobData.status === "a_approval" ||
        mobData.status === "a_approved" ||
        mobData.status === "return_requested" ||
        mobData.status === "return_completed" ||
        mobData.status === "request_completed" ||
        mobData.status === "received") && (
        <div className="mob-process-status-container">
          <div className="mob-process-status">
            Status:
            <span style={{ paddingLeft: "5px" }}>
              {mobData.status && statusField[mobData.status]}
            </span>
          </div>

          <div className="mob-process-date">
            Date:
            <span style={{ paddingLeft: "5px" }}>
              {moment(mobData.statusChangeTime).format("DD/MM/YYYY")}
            </span>
          </div>
          <div className="mob-process-btn-container">
            {mobData.status === "received" && (
              <div
                className="mob-process-btn"
                onClick={() =>
                  window.confirm(
                    "Are you sure you want to send return request ?"
                  ) && handleReqReturn()
                }
              >
                Send Return Request
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MobItemList;
