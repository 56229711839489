import React, { useState, useEffect } from "react";
import Inventory from "../components/Inventory";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { withClaims } from "../../../shared/components/Session";

function InventoryContainer(props) {
  const userRole = props.claimsData && props.claimsData.role;
  const [btnSelected, setBtnSelected] = useState(1);
  const [values, setValues] = useState({
    category: "",
    itemName: "",
    itemType: "",
    itemCode: "",
    units: "",
    description: "",
    //itemQuantity: 0
  });
  const [units, setUnits] = useState([]);
  const [issubbmitted, setIsSubbmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState(false);
  const handleTabs = (id) => {
    setBtnSelected(id);
  };
  const [modalShow, setModalShow] = useState(false);
  const handleOpen = (status) => {
    setModalShow(status);
  };
  const handleClose = (status) => {
    setModalShow(status);
  };
  const handleChange = (changedValue, type) => {
    values[type] = changedValue;
    setValues({ ...values });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiHandler.getUnitList();
        setUnits(response.results);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const editInfo = async () => {
      if (!Object.keys(errors).length && issubbmitted) {
        const modifiedData = {
          category: values.category,
          description: values.description,
          name: values.itemName,
          type: values.itemType,
          code: values.itemCode,
          unit: values.units,
        };
        let result;
        try {
          result = await ApiHandler.inventoryDetails(modifiedData);

          setStatus(!status);

          NotificationManager.success("Added Successfully", "", 2000);
        } catch (error) {
          NotificationManager.error("Error while adding the item", "", 2000);
        }
      }
    };
    editInfo();
  }, [errors]);

  const validate = () => {
    let errors = {};
    if (!values.category) {
      errors.category = "category is required";
      NotificationManager.warning(errors.category, "", 2000);
      setErrors(errors);

      return;
    }
    if (!values.itemName) {
      errors.itemName = "Item Name is required";
      NotificationManager.warning(errors.itemName, "", 2000);
      setErrors(errors);
      return;
    }

    if (!values.itemCode) {
      errors.itemCode = "Item Code is required";
      NotificationManager.warning(errors.itemCode, "", 2000);
      setErrors(errors);
      return;
    }

    setErrors(errors);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubbmitted(true);
    validate();
    handleClose(false);
  };

  return (
    <Inventory
      btnSelected={btnSelected}
      handleTabs={handleTabs}
      modalShow={modalShow}
      handleOpen={handleOpen}
      handleClose={handleClose}
      values={values}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      status={status}
      units={units}
      userRole={userRole}
    />
  );
}

export default withClaims(InventoryContainer);
