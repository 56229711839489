import React, { useState, useEffect } from "react";
import Offers from "../components/offers";
import { withClaims } from "../../../shared/components/Session";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { compose } from "recompose";
import NotificationManager from "react-notifications/lib/NotificationManager";

const OffersContainers = (props) => {
  const [companyName, setCompanyName] = useState("");
  const [status, setStatus] = useState(false);
  const [companies, setCompanies] = useState([]);

  const employeeId = props.claimsData && props.claimsData.employeeId;
  let fy = new Date().getFullYear();
  let month = new Date().getMonth();
  let yearVal;
  if (month <= 2) {
    yearVal = fy;
  } else {
    yearVal = fy + 1;
  }

  const [yearOptions, setYearOptions] = useState(
    Array.from(new Array(5), (val, index) => yearVal - index)
  );

  const [year, setYear] = useState(yearOptions[0]);
  const handleChange = (newValue, type) => {
    setCompanyName(newValue);
  };

  const getCompanyList = async () => {
    const data = await ApiHandler.getCompanyList();
    setCompanies(data.results);
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  const [givenBy, setGivenBy] = useState("");

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        const data = await ApiHandler.getIndividualEmployeeDetails(employeeId);
        setGivenBy(data.results.name);
      } catch (error) {}
    };
    if (employeeId) {
      getCurrentUser();
    }
  }, [employeeId]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const offers = {
      companyName,
      givenBy,
    };

    if (companyName && givenBy) {
      const response = await ApiHandler.createOffer(offers);

      if (response.status === "OK") {
        setStatus(!status);
        setCompanyName("");
        NotificationManager.success("Company Added Successfully", "", 2000);
      } else {
        NotificationManager.warning(response.message, "", 2000);
      }
    } else {
      NotificationManager.warning("company or user name error", "", 2000);
    }
  };

  const handleFYChange = (val) => {
    setYear(val);
  };

  return (
    <Offers
      handleChange={handleChange}
      companyList={companies}
      companyName={companyName}
      onSubmit={onSubmit}
      status={status}
      yearOptions={yearOptions}
      year={year}
      handleFYChange={handleFYChange}
    />
  );
};

export default compose(withClaims)(OffersContainers);
