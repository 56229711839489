import React from "react";
import "../styles.scss";
import icons from "../../../../assets/icons/Icons";
import MobileAddTable from "../containers/MobileAddTableContainer";
import SelectSearch from "react-select-search";

function MobileAddReq({
  childrenProps,
  itemList,
  handleChange,
  addData,
  handleSubmit,
  requestId,
  refresh,
  handleDeleteReq,
  quantityField,
  addDataList,
}) {
  return (
    <div className="mob-req-add-container">
      {childrenProps}
      <div className="mob-req-delete-container">
        <div
          className="mob-req-del-btn"
          onClick={() =>
            window.confirm("Confirm to delete") && handleDeleteReq()
          }
        >
          Delete Request
        </div>
      </div>
      <div className="mob-add-item-container">
        <div className="mob-add-item-title">ADD ITEM</div>

        <div className="mob-add-item-wrapper">
          <span>Item Name</span>
          <SelectSearch
            className="select-search-box"
            placeholder=" Search Item Name"
            search
            onChange={({ value, name }) =>
              handleChange({ value, name }, "itemName")
            }
            value={addData.itemName}
            options={
              addDataList.itemNameList &&
              addDataList.itemNameList.map((itm, i) => {
                return {
                  name: itm.itemName,
                  value: itm._id,
                };
              })
            }
          />
          <span style={{ padding: "10px 0px" }}>Type</span>

          <SelectSearch
            className="select-search-box"
            placeholder=" Search Item Type"
            search
            onChange={({ value, name }) =>
              handleChange({ value, name }, "type")
            }
            value={addData.type}
            options={
              addDataList.typeList &&
              addDataList.typeList.map((itm, i) => {
                return {
                  name: itm.type,
                  value: itm._id,
                };
              })
            }
          />
          <span style={{ padding: "10px 0" }}>Description</span>
          <SelectSearch
            className="select-search-box"
            placeholder=" Search Item Description"
            search
            onChange={({ value, name }) =>
              handleChange({ value, name }, "description")
            }
            value={addData.description}
            options={
              addDataList.descriptionList &&
              addDataList.descriptionList.map((itm, i) => {
                return {
                  name: itm.description,
                  value: itm._id,
                };
              })
            }
          />

          <div>{quantityField()}</div>
        </div>
      </div>
      <MobileAddTable requestId={requestId} refresh={refresh} />
    </div>
  );
}

export default MobileAddReq;
