import React, { useState } from "react";
import InventoryDetails from "../components/InventoryDetails";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { NotificationManager } from "react-notifications";

function InventoryDetailsContainer(props) {
  const data = {
    location: "",
    unit: "",
  };
  const [inventoryData, setInventoryData] = useState(data);

  const handleChange = (data, type) => {
    inventoryData[type] = data;
    setInventoryData({ ...inventoryData });
  };

  const validateLoc = () => {
    if (!inventoryData.location) {
      NotificationManager.warning(
        "Please add the inventory location",
        "",
        2000
      );
    }
  };

  const validateUnit = () => {
    if (!inventoryData.unit) {
      NotificationManager.warning("Please add the unit", "", 2000);
    }
  };

  const addLocation = async () => {
    validateLoc();
    if (inventoryData.location) {
      const add = await ApiHandler.addInventoryLoc({
        name: inventoryData.location,
      });
      setInventoryData(data);
      if (add.status === "OK") {
        NotificationManager.success(add.message, "", 2000);
      } else {
        NotificationManager.warning(add.message, "", 2000);
      }
    }
  };

  const addUnit = async () => {
    validateUnit();
    if (inventoryData.unit) {
      const add = await ApiHandler.addUnit({
        name: inventoryData.unit,
      });
      setInventoryData(data);
      if (add.status === "OK") {
        NotificationManager.success(add.message, "", 2000);
      } else {
        NotificationManager.warning(add.message, "", 2000);
      }
    }
  };
  return (
    <InventoryDetails
      handleChange={handleChange}
      inventoryData={inventoryData}
      addLocation={addLocation}
      addUnit={addUnit}
    />
  );
}

export default InventoryDetailsContainer;
