import React, { useState } from "react";
import "../styles.scss";
import SelectSearch from "react-select-search";
import OfferTableContainer from "../containers/offerTableContainer";

const Offers = ({
  handleChange,
  companyList,
  companyName,
  onSubmit,
  status,
  year,
  yearOptions,
  handleFYChange,
}) => {
  return (
    <div className="offer-outer-cover">
      <div className="offer-inner-cover">
        <div className="header">ADD NEW OFFER</div>
        <div className="add-new-offer-body">
          <div className="add-new-offer-title">company</div>
          <div>
            <SelectSearch
              className="select-search-box"
              name="companyName"
              placeholder="Search"
              id="companyName"
              onChange={({ value }) => {
                handleChange(value, "companyName");
              }}
              value={companyName}
              options={
                companyList &&
                companyList.map((company, id) => {
                  return { name: company.name, value: company.name };
                })
              }
            />
            <button
              type="button"
              className="btn btn-success add-new-offer-btn"
              onClick={(e) => onSubmit(e)}
            >
              ADD
            </button>
          </div>
        </div>
      </div>

      <div className="offerList-inner-cover">
        <div className="offer-header">
          <div>OFFER LIST</div>

          <div>
            <span style={{ fontSize: "14px", paddingRight: "5px" }}>
              Select Financial Year:
            </span>
            <select
              style={{ width: "150px" }}
              value={year}
              onChange={(e) => handleFYChange(e.target.value)}
            >
              <option></option>
              {yearOptions &&
                yearOptions.length > 0 &&
                yearOptions.map((year, i) => {
                  let next = `${(year - 1).toString().substr(-2)}`;

                  return (
                    <option key={i} value={year}>
                      {year}-{next}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="offer-table">
          <OfferTableContainer status={status} year={year} />
        </div>
      </div>
    </div>
  );
};

export default Offers;
