export const Resource = {
  url: {
    baseUrl: `https://ndsons-api.updaptcsr.com`,
    //baseUrl: `http://localhost:3020`,

    //client
    login: `/client/login`,
    supervisorListWithAccess: `/client/list-all?role=SUPERVISOR|ADMIN`,

    //unit
    unitList: `/unit/list-all`,
    addUnit: `/unit/add`,

    //company
    companyList: `/company/list-all`,
    addCompany: `/company/add`,

    //employee
    employeeList: `/client/role/statics`,
    employeeRoleList: `employee/jobrole/statics`,
    EmployeeDetails: `/employee/add`,
    employeeTableDetails: `/employee/list-all`,
    supervisorList: `/employee/list-all?jobRole=SUPERVISOR`,
    individualEmployeeDetails: (id) => `/employee/${id}/info`,
    grantAccess: `/employee/grant-access`,
    edittedEmployeeDetails: (id) => `/employee/${id}/edit`,
    activity: (id) => `/employee/${id}/toggle/active`,
    totalActiveEmployees: `/employee/total`,
    totalActiveEmployeesWithAccess: `/employee/total/access`,

    //inventory
    itemList: `/inventory/item/list-all`,
    ItemdetailedView: (id) => `/inventory/item/${id}/info`,
    postInventoryDetails: `/inventory/item/add`,
    toolsInUse: (id) => `/project/list-all/item-in-use/${id}`,
    update: (id) => `/inventory/item/${id}/edit`,
    notes: (id) => `/inventory/item/${id}/note/add`,
    activityLog: (id) => `/inventory/item/${id}/activity/list-all`,
    itemListWithLessPayload: `/inventory/item/list-all?select=name,unit,itemInStock,description,type,concatName`,

    //inventory-location
    addInventoryLoc: `/inventory/location/add`,

    //project
    projectWorkhrsList: `/project/workshrs/list-all`,
    ReceivedProjectDetails: `/project/list-all`,
    ProjectDetails: `/project/add`,
    ProjectFormDetails: (id) => `/project/${id}/info`,
    ProjectDetailsUpdate: (id) => `/project/${id}/edit`,
    supervisorProjectList: (supId) => `/project/list-all?supervisorId=${supId}`,
    addHourToProject: (projectId) => `/project/${projectId}/work/add`,
    projectItemsInUse: (id) => `/project/${id}/item-in-use/list-all`,
    projectNotes: (id) => `/project/${id}/note/add`,
    projectActivityLog: (id) => `/project/${id}/activity/list-all`,
    projFileUpload: (pid) => `/project/${pid}/file/upload`,
    ProjFileDelete: (pid, docId) => `/project/${pid}/file/${docId}/delete`,
    projectPo: (id) => `/po/list-all?projectId=${id}`,
    deleteManHour: (projectId, logId) =>
      `/project/${projectId}/work/log/${logId}/delete`,
    projectList: `/project/workhr/statics`,
    projectLoc: `project/loc/statics`,
    projectStatusList: `/project/status/list`,
    //supplier
    supplierList: `/supplier/list-all`,
    addSupplier: `/supplier/add`,
    deleteSupplier: (id) => `/supplier/delete/${id}`,
    editSupplier: (id) => `/supplier/edit/${id}`,

    //purchase-order
    purchaseOrderList: (year) => `/po/list-all?fy=${year}`,
    purchaseCostList: `/po/monthly/cost/statics`,
    addPurchaseOrder: `/po/add`,
    deletePurchaseOrder: (pid) => `/po/${pid}/delete`,
    addPurchaseItem: (pid) => `/po/${pid}/item/add`,
    editPurchaseItmUnitPrice: (pid) => `/po/${pid}/price/edit`,
    poStatus: (pid) => `/po/${pid}/status/change`,
    poItemDelete: (pid, rowId) => `/po/${pid}/item/row/${rowId}/delete`,
    poReceivedQtyEdit: (pid) => `/po/${pid}/received-qty/edit`,
    poNotes: (pid) => `/po/${pid}/note/add`,
    poActivity: (pid) => `/po/${pid}/activity/list-all`,
    poFileUpload: (pid) => `/po/${pid}/file/upload`,
    poFileDelete: (pid, docId) => `/po/${pid}/file/${docId}/delete`,
    purchaseOrderInfo: (pid) => `/po/${pid}/info`,
    poSupplierAdd: (pid) => `/po/${pid}/supplier/add`,
    poItemExport: (pid) => `/po/${pid}/raise/docx/export`,
    poActionCount: (role) => `/po/action-required/count?role=${role}`,
    poList: `/po/monthly/statics`,
    poItemsCost: `/po/project/items-cost`,

    // request
    requestList: (year) => `/request/list-all?fy=${year}`,
    updateRequest: (id) => `/request/${id}/update`,
    updateDimension: (id) => `/request/${id}/updateDimension`,
    editReqPurchaseQuantity: (id) => `/request/${id}/edit`,
    requestStatus: (reqId) => `/request/${reqId}/approve`,
    eachRequestList: (id) => `/request/${id}/info`,
    addRequest: `/request/add`,
    addRequestItem: (reqId) => `/request/${reqId}/item/add`,
    editRequestedItmQty: (reqId, rowId) =>
      `/request/${reqId}/item/row/${rowId}/qty/edit`,

    supervisorRequestList: (supId, year) =>
      `/request/list-all?supervisorId=${supId}&fy=${year}`,
    deleteReqItem: (reqId, rowId) =>
      `/request/${reqId}/item/row/${rowId}/delete`,
    reqReceivedStatus: (reqId) => `/request/${reqId}/status/change/received`,
    reqItmReturnBySup: (reqId) => `/request/${reqId}/raise-return-request`,
    reqNote: (reqId) => `/request/${reqId}/note/add`,
    reqActivity: (reqId) => `/request/${reqId}/activity/list-all`,
    reqReturnApprovedList: (reqId) =>
      `/request/${reqId}/info?type=return_approved`,
    reqReturnInitiate: (reqId) => `/request/${reqId}/return-request/initiate`,
    approvedQuantity: (id) => `/request/${id}/approved-qty/edit`,
    approveStatus: (id) => `/request/${id}/approve`,
    assignTableData: (id) => `/request/project/list-all?itemId=${id}`,
    shippedQuantity: (id) => `/request/${id}/assignment/edit`,
    shipSuccess: (id) => `/request/${id}/ship-items`,
    returnData: (id, status) => `/request/${id}/info?type=${status}`,
    acceptedReturnQuantity: (id) => `/request/${id}/accept-return`,
    returnCompleted: (id) => `/request/${id}/return-request/complete`,
    requestCompleted: (id) => `/request/${id}/complete`,
    requestNotes: (id) => `/request/${id}/note/add`,
    requestActivityLog: (id) => `/request/${id}/activity/list-all`,
    delReq: (id) => `/request/${id}/delete`,
    reqActionCount: (role) => `/request/action-required/count?role=${role}`,
    reqStatics: `/request/monthly/statics`,
    mergeRequestPo: (id) => `request/${id}/mergeReqPo`,

    //audit
    getAllOrdersForAudit: (fy) => `/po/list-all/audits?fy=${fy}`,

    //offers
    createOffer: `/offers/add`,
    getAllOffers: (year) => `/offers/list-all?fy=${year}`,
    offerFileUpload: (offerNum) => `/offers/file/upload?oid=${offerNum}`,
  },
  thirdPartyUrl: {},
  storageKey: {
    FCM_TOKEN: "FCM_TOKEN",
  },
};
