import React, { useState, useEffect } from "react";
import MobReturn from "../components/MobReturn";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";
import NotificationManager from "react-notifications/lib/NotificationManager";

function MobReturnContainer(props) {
  const [loader, setLoader] = useState(true);
  const [btn, setBtn] = useState(1);
  const [returnData, setReturnData] = useState([]);
  const [refresh, setRefesh] = useState(false);

  const handleBtn = (value) => {
    setBtn(value);
  };

  const handleReturn = async (onGoingReqId, data) => {
    try {
      const returnData = await ApiHandler.reqReturnInitiate(props.requestId, {
        sentTo: data,
        ongoingRequestId: onGoingReqId,
      });
      setRefesh(!refresh);

      if (returnData.status === "OK") {
        NotificationManager.success("Status updated Succesfully", "", 2000);
      } else {
        NotificationManager.warning(returnData.message, "", 2000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiHandler.reqReturnApprovedList(
          props.requestId && props.requestId
        );
        data.results.ongoingRequestData && setReturnData(data.results);
        setLoader(false);
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, [refresh]);

  return (
    <MobReturn
      btn={btn}
      handleBtn={handleBtn}
      loader={loader}
      returnDataList={returnData}
      handleReturn={handleReturn}
    />
  );
}

export default MobReturnContainer;
