import React from "react";
import "../styles.scss";
import SMNotes from "../containers/SMNotesContainer";
import SMSupplier from "../containers/SMSupplierContainer";
import SMActivity from "../containers/SMActivityContainer";
import SMFile from "../containers/SMFileContainer";

function SMOptions({ sendNote, refresh, poRefresh, addSupplier, deleteFile }) {
  return (
    <div className="sm-option-container">
      <SMNotes sendNote={sendNote} />
      <SMSupplier
        refresh={refresh}
        addSupplier={addSupplier}
        poRefresh={poRefresh}
      />
      <SMActivity refresh={refresh} poRefresh={poRefresh} />
      <SMFile deleteFile={deleteFile} />
    </div>
  );
}

export default SMOptions;
