import React, { Fragment, useEffect, useState } from "react";
import PurchaseList from "../components/PurchaseList";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import moment from "moment";

function PurchaseListContainer() {
  const [poList, setPoList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const columns = ["PO ID", "Project Name", "Status", "Supplier Name", "Date"];

  const status = {
    open: "Open",
    a_approval: "Sent for admin approval",
    a_approved: "Admin Approved",
    sa_approved: "Super Admin Approved",
    rejected: "Rejected",
    vp: "Vendor PRocessing",
    delivered: "Delievered",
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await ApiHandler.purchaseOrderList();
        setPoList(data.results);
      } catch (error) {
        throw error;
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    try {
      const data =
        poList &&
        poList.map((element, i) => [
          element.customId ? element.customId : "N/A",
          element.projectInfo ? element.projectInfo.name : "N/A",
          status[element.status],
          element.supplierInfo ? element.supplierInfo.name : "N/A",
          moment(element.createdAt).format("DD/MM/YYYY"),
        ]);
      setTableData(data);
    } catch (error) {}
  }, [poList]);

  const options = {
    rowsPerPageOptions: [6, 5, 4],
    rowsPerPage: 5,
    selectableRows: false,
  };

  return (
    <PurchaseList tableData={tableData} options={options} columns={columns} />
  );
}

export default PurchaseListContainer;
