import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function DetailedRequestTable2({
  data,
  columns,
  options,
  modalShow,
  handleClose,
  innerTablecolumns,
  innerOptions,
  innerDetails,
  handleAssign,
  concat,
}) {
  return (
    <>
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
        title={"Item List"}
      />
      <Modal
        size="lg"
        show={modalShow}
        onHide={() => handleClose(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
          <Modal.Title style={{ color: "white", fontSize: "16px" }}>
            {concat}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MUIDataTable
            data={innerDetails}
            columns={innerTablecolumns}
            options={innerOptions}
            title={"Item List"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              handleAssign(e);
            }}
            variant="success"
            type="submit"
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DetailedRequestTable2;
