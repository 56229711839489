import React, { useEffect, Fragment, useState } from "react";
import SideNavBar from "../components/SideNavBar";
import { Route } from "react-router-dom";
import Dashboard from "../../Dashboard";
import Inventory from "../../Inventory";
import Employees from "../../Employees";
import Projects from "../../Projects";
import Request from "../../Request";
import PurchaseOrder from "../../PurchaseOrder";
import ItemDetailedView from "../../ItemDetailedView";
import EmployeeView from "../../EmployeeDetailedView";
import DetailedRequestView from "../../DetailedRequestView";
import ProjectsView from "../../ProjectsDetailedView";
import Settings from "../../Settings";
import Offers from "../../Offers";
import Audits from "../../audit";
import { compose } from "recompose";
import {
  withAuthorization,
  withClaims,
} from "../../../shared/components/Session";
import DetailedPurchaseOrderView from "../../DetailedPurchaseOrderView";
import { withFirebase } from "../../../shared/components/Firebase";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";

function SideNavBarConatiner(props) {
  const [reqCount, setReqCount] = useState();
  const [poCount, setPoCount] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.claimsData.role === "supervisor") {
          return props.history.replace("/mobile/view/request/list");
        } else if (
          window.screen.width < 1200 &&
          props.claimsData.role === "admin"
        ) {
          return props.history.replace("/mobile/view/request/list");
        }
      } catch (error) {}
    };

    if (props.claimsData) {
      fetchData();
    }
  }, [props.claimsData]);

  useEffect(() => {
    const fetchData = async () => {
      const req = await ApiHandler.reqActionCount(props.claimsData.role);
      const po = await ApiHandler.poActionCount(props.claimsData.role);
      setReqCount(req.results.totalActionRequired);
      setPoCount(po.results.totalActionRequired);
    };
    if (props.claimsData) {
      fetchData();
    }
  }, [props.claimsData]);
  return (
    <>
      <SideNavBar
        reqCount={reqCount}
        poCount={poCount}
        role={props.claimsData && props.claimsData.role}
      />
      <Route
        path="/tool/inventory"
        render={(props) => <Inventory {...props} />}
      />
      <Route
        path="/tool/itemview/:id"
        render={(props) => <ItemDetailedView {...props} />}
      />
      <Route
        path="/tool/employees"
        render={(props) => <Employees {...props} />}
      />
      <Route
        path="/tool/employeeview/:id"
        render={(props) => <EmployeeView {...props} />}
      />
      <Route
        path="/tool/purchase-order"
        render={(props) => <PurchaseOrder {...props} />}
      />
      <Route
        path="/tool/projects"
        render={(props) => <Projects {...props} />}
      />
      <Route
        path="/tool/projectsview/:id"
        render={(props) => <ProjectsView {...props} />}
      />
      <Route path="/tool/requests" render={(props) => <Request {...props} />} />
      <Route
        path="/tool/order-info/:id"
        render={(props) => <DetailedPurchaseOrderView {...props} />}
      />
      <Route
        path="/tool/requestview/:id"
        render={(props) => <DetailedRequestView {...props} />}
      />
      <Route
        path="/tool/settings"
        render={(props) => <Settings {...props} />}
      />
      <Route
        path="/tool/dashboard"
        render={(props) => <Dashboard {...props} />}
      />
      <Route path="/tool/offers" render={(props) => <Offers {...props} />} />
      <Route path="/tool/audits" render={(props) => <Audits {...props} />} />
    </>
  );
}

const condition = (authUser) => authUser != null;
export default compose(
  withClaims,
  withFirebase,
  withAuthorization(condition)
)(SideNavBarConatiner);
