import React, { useState } from "react";
import SMNotes from "../components/SMNotes";
import NotificationManager from "react-notifications/lib/NotificationManager";

function SMNotesContainer(props) {
  const [note, setNote] = useState("");

  const handleChange = (data) => {
    setNote(data);
  };

  const handleNote = async () => {
    if (note) {
      await props.sendNote(note);
      await setNote("");
    } else {
      NotificationManager.warning("Please enter the notes");
    }
  };

  return (
    <SMNotes note={note} handleChange={handleChange} handleNote={handleNote} />
  );
}

export default SMNotesContainer;
