import React, { useEffect, useState } from "react";
import MobItemList from "../components/MobItemList";
import NotificationManager from "react-notifications/lib/NotificationManager";

function MobItemListContainer(props) {
  const [itmTableData, setItmTableData] = useState([]);

  useEffect(() => {
    const data =
      props.reqProcessData.items &&
      props.reqProcessData.items.map((data, i) => {
        return {
          rowId: data._id,
          itemId: data.itemId,
          returnedQuantity: data.returnedQuantity,
        };
      });
    setItmTableData(data);
  }, [props.reqProcessData]);

  const handleReturnChange = (i, value, approvedQty) => {
    if (value && value <= approvedQty) {
      itmTableData[i].returnedQuantity = value;
      setItmTableData([...itmTableData]);
    } else {
      NotificationManager.warning(
        "Return quantity cannot be empty or cannot be more than approved quantity",
        "",
        2000
      );
      itmTableData[i].returnedQuantity = 0;
      setItmTableData([...itmTableData]);
    }
  };

  const handleReqReturn = async () => {
    await props.handleReqReturn(itmTableData);
    setItmTableData([]);
  };
  return (
    <MobItemList
      mobData={props.reqProcessData}
      loader={props.loader}
      itmTableData={itmTableData}
      handleReturnChange={handleReturnChange}
      handleReqReturn={handleReqReturn}
    />
  );
}

export default MobItemListContainer;
