import React, { useState, useEffect } from "react";
import MobileAddTable from "../components/MobileAddTable";
import { withRouter } from "react-router-dom";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";

function MobileAddTableContainer(props) {
  const [show, setShow] = useState(false);
  const [editOption, setEditOption] = useState({
    itemName: "",
    qty: 0,
    itemId: "",
    rowId: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleShow = (rowId, itemId, itemName, qty) => {
    editOption["itemName"] = itemName;
    editOption["qty"] = qty;
    editOption["itemId"] = itemId;
    editOption["rowId"] = rowId;
    setEditOption({ ...editOption });
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleChange = (value, type) => {
    editOption[type] = value;
    setEditOption({ ...editOption });
  };

  const validate = () => {
    if (!editOption.qty) {
      NotificationManager.warning("Please add the quantity", "", 2000);
    }
  };
  const [reqList, setReqList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);
        const data = await ApiHandler.getEachRequestList(props.requestId);
        setReqList(data.results);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        throw error;
      }
    };
    fetchData();
  }, [props.refresh, refresh]);
  const handleEdit = async () => {
    validate();
    if (editOption.qty && editOption.itemName) {
      const data = {
        itemId: editOption.itemId,
        quantity: editOption.qty,
      };
      const edit = await ApiHandler.editRequestedItmQty(
        props.requestId,
        editOption.rowId,
        data
      );
      setRefresh(!refresh);
      setShow(false);

      if (edit.status === "OK") {
        NotificationManager.success(
          "item quantity edited successfully",
          "",
          2000
        );
      } else {
        NotificationManager.warning(edit.message, "", 2000);
      }
    }
  };
  const handleSubmit = async () => {
    try {
      const sendStatus = await ApiHandler.requestStatus(props.requestId, {
        status: "a_approval",
      });

      if (sendStatus.status === "OK") {
        NotificationManager.success("Request has been sent", "", 2000);
        props.history.replace({
          pathname: "/mobile/view/request/process",
          state: { RequestId: props.requestId },
        });
      }
    } catch (error) {}
  };

  const deleteItem = async (itemId) => {
    try {
      const data = await ApiHandler.deleteReqItem(props.requestId, itemId);
      setRefresh(!refresh);

      if (data.status === "OK") {
        NotificationManager.success("item deleted successfully", "", 2000);
      } else {
        NotificationManager.warning(data.message, "", 2000);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <MobileAddTable
      reqList={reqList}
      handleSubmit={handleSubmit}
      show={show}
      handleClose={handleClose}
      handleShow={handleShow}
      editOption={editOption}
      handleChange={handleChange}
      handleEdit={handleEdit}
      deleteItem={deleteItem}
      loader={loader}
    />
  );
}

export default withRouter(MobileAddTableContainer);
