import React from "react";

import AuthUserContext from "./context";

const withCurrentUser = Component => props => (
  <AuthUserContext.Consumer>
    {authData => <Component {...props} currentUser={authData.currentUser} />}
  </AuthUserContext.Consumer>
);

export default withCurrentUser;
