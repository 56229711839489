import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";
function RequestTable({ data, columns, options }) {
  return (
    <MUIDataTable
      data={data}
      columns={columns}
      options={options}
      className="pointer"
    />
  );
}

export default RequestTable;
