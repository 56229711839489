import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";

function DetailedRequestTable({ data, columns, options, handleApproveClick }) {
  return (
    <MUIDataTable
      data={data}
      columns={columns}
      options={options}
      title={"Item List"}
      handleApproveClick={handleApproveClick}
    />
  );
}

export default DetailedRequestTable;
