import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import MobileProject from "../components/MobileProject";
import { withClaims } from "../../../../shared/components/Session";
import { ApiHandler } from "../../../../shared/helpers/ApiHandler";
import { compose } from "recompose";

function MobileProjectContainer(props) {
  const [projectData, setProjectData] = useState(null);
  const [loader, setLoader] = useState(false);

  const supId = props.claimsData && props.claimsData.employeeId;

  const handleProject = (prjtId, prjName) => {
    props.history.replace({
      pathname: "/mobile/view/projects/add/hours",
      exact: true,
      state: { projectId: prjtId, projectName: prjName },
    });
  };

  useEffect(() => {
    let clean = true;
    const fetchData = async () => {
      try {
        setLoader(true);
        const data = await ApiHandler.supervisorProjectList(supId);
        if (clean) {
          setProjectData(data.results);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        throw error;
      }
    };
    if (props.claimsData) {
      fetchData();
    }
    return function cleanup() {
      clean = false;
    };
  }, [props.claimsData]);
  return (
    <MobileProject
      childrensProps={props.children}
      projectData={projectData}
      handleProject={handleProject}
      loader={loader}
    />
  );
}

export default compose(withRouter, withClaims)(MobileProjectContainer);
