import React from "react";
import "../styles.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import RequestTable from "../containers/RequestTableContainer";
import SelectSearch from "react-select-search";

function Request({ yearOptions, year, handleInputChange }) {
  return (
    <div className="request-outer-cover">
      <div className="request-inner-cover">
        <div className="request-header">
          <div>Request List</div>
          <div>
            <span style={{ fontSize: "14px", paddingRight: "5px" }}>
              Select Financial Year:
            </span>
            <select
              style={{ width: "150px" }}
              value={year}
              onChange={(e) => handleInputChange(e.target.value)}
            >
              <option></option>
              {yearOptions &&
                yearOptions.length > 0 &&
                yearOptions.map((year, i) => {
                  let next = `${(year - 1).toString().substr(-2)}`;
                  return (
                    <option key={i} value={year}>
                      {year}-{next}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="request-table-cover">
          <RequestTable year={year} />
        </div>
      </div>
    </div>
  );
}

export default Request;
