import React, { useEffect, useState } from "react";
import ProjectsView from "../components/ProjectsView";
import { useParams } from "react-router-dom";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";
import { Resource } from "../../../shared/constants/ResourceVariables";

function ProjectsViewContainer(props) {
  const userRole = props.claimsData && props.claimsData.role;
  const columns = ["Items", "Category", "Quantity"];
  const poColumns = ["PO NO", "Vendor", "Purchase Total", "Date"];
  const manHoursColumns = ["Date", "Man Hours", "OT Hours", "Employee"];
  const [tableData, setTableData] = useState([]);
  let { id } = useParams();
  const [values, setValues] = useState({
    projectName: "",
    projectSupervisor: [],
    company: "",
    file: "",
    scope: "",
    status: "",
    documents: [],
    startDate: "",
    endDate: "",
    description: "",
  });
  const [poData, setPoData] = useState([]);
  const [manHoursData, setManHoursData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [projActivityLog, setProjActivityLog] = useState([]);
  const [header, setHeader] = useState(null);
  const [apiResults, setApiResults] = useState(null);
  const [issubbmitted, setIsSubbmitted] = useState(false);
  const [callState, setCallState] = useState(false);
  const [status, setStatus] = useState(false);
  const [supervisorList, SetSupervisorList] = useState([]);

  const currentOptions =
    supervisorList.length > 0 &&
    supervisorList.map((employee, index) => {
      return {
        label: employee.employeeInfo && employee.employeeInfo.name,
        value: employee.employeeInfo && employee.employeeInfo._id,
      };
    });

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.supervisorListWithAccess();
      SetSupervisorList(response.results);
    };

    fetchData();
  }, []);

  const handleChange = (changedValue, type) => {
    values[type] = changedValue;
    setValues({ ...values });
  };
  useEffect(() => {
    const fetchTableData = async () => {
      const response = await ApiHandler.getProjectItemsInUse(id);
      const apiValues = response.results;
      const tempTableData = [];
      apiValues.map((element) => {
        const data = [element.concatName, element.category, element.quantity];
        tempTableData.push(data);
      });

      setTableData(tempTableData);
    };
    fetchTableData();
  }, []);

  const [poTotal, setpoTotal] = useState(0);

  const purchaseTotal = () => {
    let total = 0;
    return poData.map((purchase, i) => {
      total += purchase[2];
      setpoTotal(total);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getProjectPo(id);
      const tempTableData = [];

      response.results.map((element, i) => {
        const data = [
          element.customId ? element.customId : "N/A",
          element.supplierInfo != null ? element.supplierInfo.name : "N/A",
          element.items && element.items.length > 0
            ? element.items
                .map((item) => {
                  return item.unitPrice * item.quantity;
                })
                .reduce((total, item) => {
                  return total + item;
                })
            : 0,
          moment(element.createdAt).format("DD-MM-YYYY"),
        ];
        tempTableData.push(data);
      });
      setPoData(tempTableData);
    };
    fetchData();
    purchaseTotal();
  }, [poData.length]);

  useEffect(() => {
    const fetchFormData = async () => {
      const response = await ApiHandler.getProjectFormDetails(id);
      const apiValues = response.results;
      setHeader(response.results);
      const supervisor = apiValues.supervisorInfo.map((list, index) => {
        return {
          label: list.name,
          value: list._id,
        };
      });
      setApiResults(response.results);
      const projectFormDetails = {
        projectName: apiValues.name,
        projectSupervisor: supervisor,
        status: apiValues.status,
        scope: apiValues.scope,
        documents: apiValues.documents,
        company: apiValues.companyInfo.name,
        startDate:
          apiValues.startDate &&
          moment(apiValues.startDate).format("YYYY-MM-DD"),
        endDate:
          apiValues.endDate && moment(apiValues.endDate).format("YYYY-MM-DD"),
      };
      setValues(projectFormDetails);

      const tempTableData = [];
      response.results.work.logs.map((log) => {
        const data = [
          moment(log.date).format("DD-MM-YYYY"),
          log.hrs,
          log.otHrs ? log.otHrs : 0,
          log.loggedBy && log.loggedBy.name,
        ];
        tempTableData.push(data);
      });
      setManHoursData(tempTableData);
    };
    if (props.claimsData) {
      fetchFormData();
    }
  }, [refresh, props.claimsData]);

  useEffect(() => {
    const addProjectViewInfo = async () => {
      if (issubbmitted) {
        const modifiedData = {
          status: values.status,
          supervisor: values.projectSupervisor.map((element, index) => {
            return element.value;
          }),
          scope: values.scope,
          name: values.projectName,
          startDate: values.startDate,
          endDate: values.endDate,
        };
        try {
          const result = await ApiHandler.postProjectDetailsUpdate(
            id,
            modifiedData
          );
          setStatus(!status);

          NotificationManager.success("updated Successfully", "", 2000);
        } catch (error) {}
      }
    };
    addProjectViewInfo();
  }, [callState]);

  const handleAddNotes = async (e) => {
    e.preventDefault();
    try {
      if (values.description) {
        const response = await ApiHandler.postProjectNotes(id, {
          note: values.description,
        });
        setStatus(!status);
        values["description"] = "";
        setValues({ ...values });
        NotificationManager.success("Added Notes Successfully", "", 2000);
      } else {
        NotificationManager.warning("Please add notes!", "", 2000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiHandler.getProjectActivityLog(id);
      setProjActivityLog(response.results);
    };
    fetchData();
  }, [status, refresh]);

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    if (values.file) {
      try {
        const formData = new FormData();
        formData.append("uploadedFile", values.file);
        setUploadStatus(true);
        const data = await ApiHandler.poProjFileUpload(id, formData);
        setUploadStatus(false);
        NotificationManager.success("File uploaded Successfully", "", 2000);
        setRefresh(!refresh);
      } catch (error) {
        NotificationManager.warning(error.message, "", 2000);
      }
    } else {
      NotificationManager.warning("Please select a file", "", 2000);
    }
  };

  const handleFileDelete = async (documentId) => {
    try {
      const fileDeleteResponse = await ApiHandler.poProjFileDelete(
        id,
        documentId
      );

      NotificationManager.success("Document deleted successfully", "", 2000);
      setRefresh(!refresh);
    } catch (error) {
      NotificationManager.warning(
        "An error occurred while deleting the document",
        "",
        2000
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubbmitted(true);
    setCallState(!callState);
  };

  const handleBack = () => {
    props.history.replace("/tool/projects");
  };

  const options = {
    rowsPerPageOptions: [8, 7, 6],
    rowsPerPage: 8,

    print: false,
    download: false,
    selectableRows: false,
  };

  const customStyles = {
    menu: () => ({
      position: "relative",
      border: "1px solid grey",
      backgroundColor: "white",
    }),
    placeholder: () => ({ color: "black", fontSize: 14 }),
    container: () => ({
      width: "65%",
      float: "left",
    }),
    control: () => ({
      width: "100%",
      minHeight: 45,
      display: "flex",
      border: "1px solid grey",
    }),
  };

  const viewFile = async (docId) => {
    if (id) {
      return (window.location.href =
        Resource.url.baseUrl + `/project/${id}/file/${docId}/download`);
    } else {
      NotificationManager.warning("Unable to download the documents", "", 2000);
    }
  };

  return (
    <>
      {apiResults ? (
        <ProjectsView
          poColumns={poColumns}
          columns={columns}
          data={tableData}
          options={options}
          handleChange={handleChange}
          values={values}
          handleSubmit={handleSubmit}
          handleFileSubmit={handleFileSubmit}
          handleBack={handleBack}
          handleFileDelete={handleFileDelete}
          header={header}
          handleAddNotes={handleAddNotes}
          projActivityLog={projActivityLog}
          uploadStatus={uploadStatus}
          poData={poData}
          manHoursData={manHoursData}
          manHoursColumns={manHoursColumns}
          customStyles={customStyles}
          currentOptions={currentOptions}
          userRole={userRole}
          viewFile={viewFile}
          poTotal={poTotal}
          apiResults={apiResults}
        />
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="#7764e4"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </div>
      )}
    </>
  );
}

export default compose(withRouter, withClaims)(ProjectsViewContainer);
