import firebase from "firebase";
import firebaseConfig from "../../../config/firebaseConfig";

class Firebase {
  constructor() {
    if (!Firebase.instance) {
      firebase.initializeApp(firebaseConfig);
      Firebase.instance = this;
    }

    this.auth = firebase.auth();
    return Firebase.instance;
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithCustomToken = token => this.auth.signInWithCustomToken(token);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  getIdToken = () => this.auth.currentUser.getIdToken(true);

  getIdTokenResult = () => this.auth.currentUser.getIdTokenResult();
}

const firebaseInstance = new Firebase();
Object.freeze(firebaseInstance);

export default firebaseInstance;
