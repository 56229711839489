import React, { useState, useEffect, Fragment } from "react";
import Audit from "../components/audits";
import { withClaims } from "../../../shared/components/Session";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { compose } from "recompose";
import Loader from "react-loader-spinner";
import moment from "moment";
import { Resource } from "../../../shared/constants/ResourceVariables";
import NotificationManager from "react-notifications/lib/NotificationManager";

const AuditsContainer = (props) => {
  const [tableData, setTableData] = useState(null);
  const [upStatus, setUpStatus] = useState(false);
  const [auditList, setAuditList] = useState([]);

  let fy = new Date().getFullYear();
  let month = new Date().getMonth();
  let yearVal;
  if (month <= 2) {
    yearVal = fy;
  } else {
    yearVal = fy + 1;
  }

  const [yearOptions, setYearOptions] = useState(
    Array.from(new Array(5), (val, index) => yearVal - index)
  );

  const [year, setYear] = useState(yearOptions[0]);

  const handleInputChange = async (year) => {
    await setYear(year);
  };

  const columns = [
    "Sl.No",
    "Item Name/Description",
    "Type/Size",
    "Category",
    "Total Quantity Stock",
    "Unit",
    "PO NO",
    "Recieved Qty",
    "Recieved Date",
    "Project Name",
    "Request No/Job Card Reference",
    "Issued To",
    "Issue (Qty)",
    "Issued Date",
    "Balance (Qty)",
    "Remark",
  ];

  useEffect(() => {
    const getAuditList = async () => {
      try {
        const response = await ApiHandler.getAllOrdersForAudit(year);
        setAuditList(response);
      } catch (error) {}
    };
    if (year) {
      getAuditList();
    }
  }, [props.status, year, upStatus]);

  const auditTable = () => {
    if (auditList.results) {
      setUpStatus(false);
      const tempTableData = [];
      let slNo = 0;
      auditList.results.map((stock, index) => {
        return stock.items.map((item) => {
          const data = [
            ++slNo,
            item.itemInfo.concatName,
            item.itemInfo.type,
            item.itemInfo.category,
            item.quantity,
            item.itemInfo.unit,
            stock.customId,
            item.receivedQuantity,
            moment(item.statusChangeTime).format("DD-MM-YYYY  hh:mm a"),
            stock.projectInfo.name,
            stock.requestInfo ? stock.requestInfo.customId : "",
            stock.purchasedFor.name,
            item.receivedQuantity,
            moment(item.statusChangeTime).format("DD-MM-YYYY  hh:mm a"),
            item.itemInfo.itemInStock,
            // REMARK
          ];
          tempTableData.push(data);
        });
      });
      setTableData(tempTableData);
    } else {
      return;
    }
  };

  useEffect(() => {
    auditTable();
  }, [auditList]);

  const options = {
    rowsPerPageOptions: [5, 4, 3],
    rowsPerPage: 5,
    selectableRows: false,
  };

  const exportAudit = () => {
    try {
      return (window.location.href =
        Resource.url.baseUrl + `/po/audit/docx/export`);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Fragment>
      {tableData ? (
        <Audit
          columns={columns}
          options={options}
          tableData={tableData}
          exportAudit={exportAudit}
          year={year}
          yearOptions={yearOptions}
          handleInputChange={handleInputChange}
        />
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="#7764e4"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </div>
      )}
    </Fragment>
  );
};

export default compose(withClaims)(AuditsContainer);
