import React, { useEffect, useState } from "react";
import DetailedRequestTable from "../components/DetailedRequestTable";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";

function DetailedRequestTableContainer(props) {
  const columns = [
    "Item Name",
    "Size",
    "Description",
    "Requested Quantity",
    "Approved Quantity",
  ];
  const options = {
    rowsPerPageOptions: [5, 4],
    rowsPerPage: 5,

    selectableRows: "none",
    disableToolbarSelect: true,
  };
  const [tableData, setTableData] = useState([]);

  const [data, setData] = useState([]);

  const handleQuantityChange = (changedValue, element) => {
    if (changedValue <= element.quantity) {
      element.approvedQuantity = changedValue;
      setData([...data]);
    } else if (changedValue > element.quantity) {
      NotificationManager.warning(
        "Approved Quantity cannot be more than that of requested",
        "",
        2000
      );
      element.approvedQuantity = element.quantity;
      setData([...data]);
    } else {
      element.approvedQuantity = 0;
      setData([...data]);
    }
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    const tempTableData = [];

    data.map((element, index) => {
      const map = [
        element.itemInfo.name,
        element.itemInfo.type ? element.itemInfo.type : "N/A",
        element.itemInfo.description ? element.itemInfo.description : "N/A",

        element.quantity,
        <input
          disabled={props.userRole !== "admin" || props.status !== "a_approval"}
          type="number"
          onChange={(e) => {
            handleQuantityChange(e.target.valueAsNumber, element);
          }}
          value={element.approvedQuantity}
        ></input>,
      ];
      tempTableData.push(map);
    });
    setTableData(tempTableData);
  }, [data]);

  return (
    <DetailedRequestTable
      data={tableData}
      options={options}
      columns={columns}
    />
  );
}

export default DetailedRequestTableContainer;
