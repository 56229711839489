import React, { useState, useEffect } from "react";
import SMFile from "../components/SMFile";
import { useParams } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import { Resource } from "../../../shared/constants/ResourceVariables";

function SMFileContainer(props) {
  const [fileToUpload, setFileToUpload] = useState(null);
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handleChange = (data) => {
    setFileToUpload(data);
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();

    if (fileToUpload) {
      const formData = new FormData();
      formData.append("uploadedFile", fileToUpload);

      const data = await ApiHandler.poFileUpload(id, formData);
      setRefresh(!refresh);

      if (data.status === "OK") {
        NotificationManager.success("File uploaded Successfully", "", 2000);
      } else {
        NotificationManager.warning(data.message, "", 2000);
      }
    } else {
      NotificationManager.warning("Please select the file", "", 2000);
    }
  };

  const deleteFile = async (docId) => {
    try {
      if (docId) {
        await props.deleteFile(docId);
        setRefresh(!refresh);
      }
    } catch (error) {}
  };

  const viewFile = async (docId) => {
    if (fileList._id) {
      return (window.location.href =
        Resource.url.baseUrl + `/po/${fileList._id}/file/${docId}/download`);
    } else {
      NotificationManager.warning("Unable to download the documents", "", 2000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ApiHandler.purchaseOrderInfo(id);
        setFileList(data.results);
      } catch (error) {}
    };
    fetchData();
  }, [refresh]);

  return (
    <SMFile
      handleChange={handleChange}
      handleFileSubmit={handleFileSubmit}
      fileToUpload={fileToUpload}
      fileList={fileList}
      deleteFile={deleteFile}
      viewFile={viewFile}
    />
  );
}

export default SMFileContainer;
