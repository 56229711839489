import React, { useState } from "react";
import SMOptions from "../components/SMOptions";
import { useParams, withRouter } from "react-router-dom";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import NotificationManager from "react-notifications/lib/NotificationManager";

function SMOptionsContainer(props) {
  const { id } = useParams();

  const [refresh, setRefresh] = useState(false);
  const sendNote = async (data) => {
    try {
      const send = await ApiHandler.poNotes(id, { note: data });
      setRefresh(!refresh);

      if (send.status === "OK") {
        NotificationManager.success("Notes added successfully", "", 2000);
      } else {
        NotificationManager.warning(send.message, "", 2000);
      }
    } catch (error) {}
  };

  const deleteFile = async (docId) => {
    try {
      const del = await ApiHandler.poFileDelete(id, docId);
      setRefresh(!refresh);

      if (del.status === "OK") {
        NotificationManager.success("File deleted successfully", "", 2000);
        return;
      } else {
        NotificationManager.warning(del.message, "", 2000);
      }
    } catch (error) {}
  };

  return (
    <SMOptions
      sendNote={sendNote}
      orderInfo={props.orderInfo}
      refresh={refresh}
      poRefresh={props.poRefresh}
      addSupplier={props.addSupplier}
      deleteFile={deleteFile}
    />
  );
}

export default SMOptionsContainer;
