import React from "react";
import AuthUserContext from "./context";

const withClaims = Component => props => (
  <AuthUserContext.Consumer>
    {authData => <Component {...props} claimsData={authData.claimsData} />}
  </AuthUserContext.Consumer>
);

export default withClaims;
