import React, { useState, useEffect, Fragment } from "react";
import { useParams, withRouter } from "react-router-dom";
import DetailedPurchaseOrderView from "../components/DetailedPurchaseOrderView";
import { ApiHandler } from "../../../shared/helpers/ApiHandler";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";
import Loader from "react-loader-spinner";
import icons from "../../../assets/icons/Icons";
import "../styles.scss";
import { Resource } from "../../../shared/constants/ResourceVariables";

function DetailedPurchaseOrderViewContainer(props) {
  const [orderInfo, setOrderInfo] = useState({});
  const { id } = useParams();
  const [itemList, setItemList] = useState([{}]);
  const [refresh, setRefresh] = useState(false);
  const [addItem, setAddItem] = useState({
    itemName: "",
    qty: 0,
    description: "",
    unit: "",
    type: "",
  });
  const userRole = props.claimsData && props.claimsData.role;

  const handleChange = (value, type) => {
    if (type === "itemName") {
      const findData = itemList.find((itm) => itm.id === value);
      addItem["description"] = findData.description
        ? findData.description
        : "N/A";
      addItem["unit"] = findData.unit ? findData.unit : "N/A";
      addItem["type"] = findData.type ? findData.type : "N/A";

      addItem[type] = value;
      setAddItem({ ...addItem });
    } else {
      addItem[type] = value;
      setAddItem({ ...addItem });
    }
  };
  const deletePO = async () => {
    try {
      const delPO = await ApiHandler.deletePurchaseOrder(id);
      if (delPO.status === "OK") {
        NotificationManager.success(
          "Purchase order deleted successfully",
          "",
          2000
        );
        props.history.replace("/tool/purchase-order");
      } else {
        NotificationManager.warning(delPO.message, "", 2000);
      }
    } catch (error) {
      throw error;
    }
  };

  const validate = () => {
    if (!addItem.itemName) {
      NotificationManager.warning("Please select the item", "", 2000);
      return;
    }
    if (!addItem.qty) {
      NotificationManager.warning("Please add the quantity", "", 2000);
      return;
    }
  };

  const submitItem = async () => {
    try {
      validate();
      if (addItem.itemName && addItem.qty) {
        const data = {
          itemId: addItem.itemName,
          quantity: addItem.qty,
        };
        const itemData = await ApiHandler.addPurchaseItem(id, data);
        setRefresh(!refresh);
        setAddItem({
          itemName: "",
          qty: 0,
          description: "",
          unit: "",
          type: "",
        });
        if (itemData.status === "OK") {
          NotificationManager.success("Added item successfully", "", 2000);
        } else {
          NotificationManager.warning(itemData.message, "", 2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
  const handleBack = () => {
    props.history.replace("/tool/purchase-order");
  };

  const [editData, setEditData] = useState([]);

  useEffect(() => {
    const data = async () => {
      try {
        const fetchData = await ApiHandler.purchaseOrderInfo(id);

        setOrderInfo(fetchData.results);
      } catch (error) {
        throw error;
      }
    };
    data();
  }, [refresh]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await ApiHandler.itemListWithLessPayload();
      setItemList(data.results);
    };
    fetchData();
  }, []);
  ////////////////////////////////////////////////////////
  const [table, setTable] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [receiveModalShow, setReceiveModalShow] = useState(false);

  const commonData = {
    rowId: "",
    itemId: "",
    quantity: 0,
    receivedQuantity: 0,
    addReceived: 0,
  };

  const [editUnit, setEditUnit] = useState(commonData);
  const handleClose = () => {
    setModalShow(false);
    setReceiveModalShow(false);
    setEditUnit(commonData);
  };

  const enableModal = () => {
    setModalShow(true);
  };

  const enableReceivedModal = () => {
    setReceiveModalShow(true);
  };

  const handleUnitChange = (value, type) => {
    editUnit[type] = value;
    setEditUnit({ ...editUnit });
  };

  const receivedQuantityValidate = () => {
    if (!editUnit.addReceived) {
      NotificationManager.warning(
        "Please enter the received quantity",
        "",
        2000
      );
      return;
    }
  };

  const handleUnitPriceChange = (i, value) => {
    editData[i].unitPrice = value;
    setEditData([...editData]);
  };

  const editField = (index) => {
    return (
      <input
        type="number"
        className="detailed-po-tab-input-container"
        value={editData && editData.length > 0 && editData[index].unitPrice}
        onChange={(e) => handleUnitPriceChange(index, e.target.valueAsNumber)}
      />
    );
  };

  const deleteField = () => {
    return (
      <div className="detailed-po-tab-col-container del">
        <div className="detailed-po-tab-col-icon del-color">{icons.delete}</div>
      </div>
    );
  };

  const receivedField = () => {
    return (
      <div className="detailed-po-tab-col-container">
        <div
          className="detailed-po-tab-col-icon"
          onClick={() => enableReceivedModal()}
        >
          {icons.edit}
        </div>
      </div>
    );
  };

  const columns = [
    "ITEM NAME",
    "QUANTITY",
    "SIZE",
    "DESCRIPTION",
    "UNIT",
    "UNIT RATE",
    userRole === "purchase" && orderInfo.status === "open" && "EDIT UNIT RATE",
    "TOTAL RATE",
    userRole === "purchase" && orderInfo.status === "open" && "DELETE",
    (orderInfo.status === "vp" || orderInfo.status === "delivered") &&
      "TOTAL RECEIVED QTY",
    userRole === "purchase" && orderInfo.status === "vp" && "ADD RECEIVED QTY",
  ];

  const options = {
    rowsPerPageOptions: [5, 10],
    rowsPerPage: 5,
    onCellClick: (data, index) => {
      const itemData =
        orderInfo.items.length > 0 && orderInfo.items[index.dataIndex];
      editUnit["rowId"] = itemData._id;
      editUnit["itemId"] = itemData.itemId;
      editUnit["quantity"] = itemData.quantity;
      editUnit["receivedQuantity"] = itemData.receivedQuantity;
      setEditUnit({ ...editUnit });
      if (
        orderInfo.status === "open" &&
        userRole === "purchase" &&
        index.colIndex === 8
      ) {
        window.confirm("Are you sure you want to delete the item ?") &&
          itemDeleteHandler(itemData._id);
      }
    },

    // disableToolbarSelect: false,
    selectableRows: false,
  };

  const handleUnitUpdate = async () => {
    try {
      const sendData = await ApiHandler.editPurchaseItmUnitPrice(id, {
        modifiedItems: editData,
      });

      setRefresh(!refresh);

      setEditUnit(commonData);

      if (sendData.status === "OK") {
        NotificationManager.success(
          "Unit price updated successfully",
          "",
          2000
        );
      } else {
        NotificationManager.warning(sendData.message, "", 2000);
      }
    } catch (error) {}
  };

  const statusHandler = async (data) => {
    try {
      const statusCode = await ApiHandler.poStatus(id, {
        status: data,
      });
      setRefresh(!refresh);
      if (statusCode.status === "OK") {
        NotificationManager.success("Status updated successfully", "", 2000);
        return;
      } else {
        NotificationManager.success(statusCode.message, "", 2000);
        return;
      }
    } catch (error) {}
  };

  const itemDeleteHandler = async (rowId) => {
    try {
      if (editUnit.rowId) {
        const del = await ApiHandler.poItemDelete(id, rowId);
        setRefresh(!refresh);
        setEditUnit(commonData);

        if (del.status === "OK") {
          NotificationManager.success("Item deleted successfully", "", 2000);
        } else {
          NotificationManager.warning(del.message, "", 2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
  const receivedQtyHandler = async () => {
    try {
      receivedQuantityValidate();

      if (editUnit.addReceived) {
        if (
          editUnit.addReceived + editUnit.receivedQuantity <=
          editUnit.quantity
        ) {
          const receiveData = {
            rowId: editUnit.rowId,
            itemId: editUnit.itemId,
            receivedQuantity: editUnit.addReceived,
          };

          const receive = await ApiHandler.poReceivedQtyEdit(id, {
            modifiedItems: [receiveData],
          });

          setRefresh(!refresh);
          setReceiveModalShow(false);
          setEditUnit(commonData);

          if (receive.status === "OK") {
            NotificationManager.success(
              "Received Qty added to the total received Qty",
              "",
              2000
            );
          } else {
            NotificationManager.warning(receive.message, "", 2000);
          }
        } else {
          NotificationManager.warning(
            "Recieved quantity is more than you have ordered!!!"
          );
        }
      }
    } catch (error) {}
  };

  const poExport = async (status) => {
    try {
      if (
        status === "sa_approved" ||
        status === "vp" ||
        status === "delivered"
      ) {
        return (window.location.href =
          Resource.url.baseUrl + `/po/${id}/raise/docx/export`
          );
      } else {
        return (window.location.href =
          Resource.url.baseUrl + `/po/${id}/enquire/docx/export`);
      }
    } catch (error) {}
  };

  const addSupplier = async (data) => {
    try {
      const add = await ApiHandler.poSupplierAdd(id, data);
      setRefresh(!refresh);
      if (add.status === "OK") {
        NotificationManager.success(add.message, "", 2000);
      } else {
        NotificationManager.warning(add.message, "", 2000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const editData =
      orderInfo.items &&
      orderInfo.items.map((itm, index) => {
        return {
          rowId: itm._id,
          itemId: itm.itemId,
          unitPrice: itm.unitPrice,
        };
      });
    setEditData(editData);
  }, [orderInfo]);

  useEffect(() => {
    const dataMapping = () => {
      const tableData =
        orderInfo.items &&
        orderInfo.items.map((itm, index) => [
          itm.itemInfo.concatName,
          itm.quantity,
          itm.itemInfo && itm.itemInfo.type ? itm.itemInfo.type : "N/A",
          itm.itemInfo && itm.itemInfo.description
            ? itm.itemInfo.description
            : "N/A",
          itm.itemInfo.unit,
          itm.unitPrice,
          userRole === "purchase" &&
            orderInfo.status === "open" &&
            editField(index),
          (itm.quantity * itm.unitPrice).toFixed(2),
          userRole === "purchase" && orderInfo.status === "open" && deleteField(),
          (orderInfo.status === "vp" || orderInfo.status === "delivered") &&
            itm.receivedQuantity,
          userRole === "purchase" && orderInfo.status === "vp" && receivedField(),
        ]);
      setTable(tableData);
    };
    dataMapping();
  }, [editData]);

  return (
    <Fragment>
      <DetailedPurchaseOrderView
        orderInfo={orderInfo}
        deletePO={deletePO}
        handleBack={handleBack}
        itemList={itemList.length > 0 && itemList}
        addItem={addItem}
        handleChange={handleChange}
        submitItem={submitItem}
        table={table}
        columns={columns}
        options={options}
        handleUnitUpdate={handleUnitUpdate}
        handleClose={handleClose}
        handleUnitChange={handleUnitChange}
        editUnit={editUnit}
        statusHandler={statusHandler}
        receiveModalShow={receiveModalShow}
        receivedQtyHandler={receivedQtyHandler}
        userRole={userRole}
        poRefresh={refresh}
        poExport={poExport}
        addSupplier={addSupplier}
      />
    </Fragment>
  );
}

export default compose(
  withClaims,
  withRouter
)(DetailedPurchaseOrderViewContainer);
