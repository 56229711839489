import React from "react";
import "../styles.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EmployeesTable from "../containers/EmployeesTableContainer";
function Employees({
  modalShow,
  handleOpen,
  handleClose,
  values,
  handleChange,
  handleSubmit,

  role,
  apiResult,
  totalEmployees,
}) {
  return (
    <div className="employees-outer-cover">
      <div className="employees-inner-cover">
        {role === "admin" || role === "super_admin" ? (
          <Button
            variant="link"
            variant="outline-info"
            size="sm"
            className="button"
            block
            onClick={() => {
              handleOpen(true);
            }}
          >
            {" "}
            Add New Employee
          </Button>
        ) : null}
        <Modal
          size="md"
          show={modalShow}
          onHide={() => handleClose(false)}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
            <Modal.Title style={{ color: "white", fontSize: "16px" }}>
              Add New Employee
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-cover">
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="employeeId">
                  Employee Id
                </label>

                <input
                  className="profile-input"
                  type="text"
                  id="employeeId"
                  name="employeeId"
                  onChange={(e) => {
                    handleChange(e.target.value, "employeeId");
                  }}
                  values={values.employeeId}
                />
              </div>
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="name">
                  Name
                </label>

                <input
                  className="profile-input"
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) => {
                    handleChange(e.target.value, "name");
                  }}
                  values={values.name}
                />
              </div>

              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="joiningDate">
                  Joining Date
                </label>

                <input
                  className="profile-input"
                  type="date"
                  id="joiningDate"
                  name="joiningDate"
                  onChange={(e) => {
                    handleChange(e.target.value, "joiningDate");
                  }}
                  values={values.joiningDate}
                />
              </div>
              <div className="fields-label-cover">
                <label className="profile-label" htmlFor="jobRole">
                  Job Role
                </label>

                <select
                  className="profile-input"
                  name="jobRole"
                  id="jobRole"
                  onChange={(e) => {
                    handleChange(e.target.value, "jobRole");
                  }}
                  values={values.jobRole}
                >
                  <option value="">Please Select...</option>
                  <option value="EXECUTIVE OFFICER">EXECUTIVE OFFICER</option>
                  <option value="HRD INCHARGE">HRD INCHARGE</option>
                  <option value="ACCOUNTANT">ACCOUNTANT</option>
                  <option value="SKILLED">SKILLED</option>
                  <option value="HIGH SKILLED">HIGH SKILLED</option>
                  <option value="SEMI SKILLED">SEMI SKILLED</option>
                  <option value="UNSKILLED">UNSKILLED</option>
                </select>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="header">
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            Employee List
          </span>
          <span style={{ paddingLeft: "15px", fontSize: "16px" }}>
            [Total Employees-{totalEmployees}]
          </span>
        </div>
        <div className="table-cover">
          <EmployeesTable apiResult={apiResult} />
        </div>
      </div>
    </div>
  );
}

export default Employees;
