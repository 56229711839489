import React from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";

const Audits = ({
  columns,
  options,
  tableData,
  exportAudit,
  year,
  yearOptions,
  handleInputChange,
}) => {
  return (
    <div className="audit-outer-cover">
      <div className="audit-inner-cover">
        <div className="header">
          <div className="audit-header-title">Stock Register</div>
          <div>
            Select financial year:
            <select
              style={{ width: "100px" }}
              value={year}
              onChange={(e) => handleInputChange(e.target.value)}
            >
              <option></option>
              {yearOptions &&
                yearOptions.length > 0 &&
                yearOptions.map((year, i) => {
                  return (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="audit-btn" onClick={() => exportAudit()}>
            EXPORT
          </div>
        </div>
        <div id="tblData">
          {tableData && (
            <MUIDataTable
              data={tableData}
              options={options}
              columns={columns}
              className="mt-4 pointer"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Audits;
