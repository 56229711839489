import React from "react";
import "../styles.scss";
import moment from "moment";
import ProgressBar from "../../ProgressBar";

function SMActivity({ activity }) {
  return (
    <div className="sm-activity-container">
      <ProgressBar data={activity} />
    </div>
  );
}

export default SMActivity;
