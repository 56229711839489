import React, { Fragment } from "react";
import "../styles.scss";
import MUIDataTable from "mui-datatables";
import Modal from "react-bootstrap/Modal";

function SupplierList({
  tableData,
  columns,
  options,
  modalShow,
  handleModal,
  supInfo,
  handleEdit,
  handleChange,
  showModal,
}) {
  return (
    <Fragment>
      <Modal
        size="sm"
        show={modalShow}
        onHide={() => handleModal()}
        animation={true}
        centered
      >
        <Modal.Header style={{ backgroundColor: "#7764E4" }} closeButton>
          <Modal.Title style={{ color: "white", fontSize: "16px" }}>
            Supplier
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="sup-form-wrapper">
            <div className="sup-modal-container">
              <div className="sup-title">Supplier Code</div>
              <input
                type="text"
                className="sup-input"
                value={supInfo.code}
                onChange={(e) => {
                  handleChange("code", e.target.value);
                }}
              />
            </div>
            <div className="sup-modal-container">
              <div className="sup-title">Supplier Name</div>
              <input
                type="text"
                className="sup-input"
                value={supInfo.name}
                onChange={(e) => {
                  handleChange("name", e.target.value);
                }}
              />
            </div>
            <div className="sup-modal-container">
              <div className="sup-title">Supplier Address</div>
              <textarea
                placeholder="add address here"
                className="sup-text-area"
                value={supInfo.address}
                onChange={(e) => {
                  handleChange("address", e.target.value);
                }}
              />
            </div>
            <div className="sup-add-btn-container">
              <div className="sup-add-btn" onClick={() => handleEdit()}>
                ADD
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="supplier-list-container">
        <div>
          <div className="supplier-list-title">
            <div>SUPPLIER LIST</div>
            <div
              className="supplier-add-style"
              onClick={() => {
                showModal();
              }}
            >
              ADD SUPPLIER
            </div>
          </div>
        </div>

        <div className="supplier-list-wrapper">
          <MUIDataTable data={tableData} columns={columns} options={options} />
        </div>
      </div>
    </Fragment>
  );
}

export default SupplierList;
