import React from "react";
import "../styles.scss";
import Loader from "../../Loader";

function MobileProject({ childrensProps, projectData, handleProject, loader }) {
  return (
    <div className="mob-project-container">
      {childrensProps}
      <div className="mob-proj-list-container">
        <div className="mob-proj-header">LIST OF PROJECTS</div>
        <div className="mob-proj-list-wrapper">
          {loader ? (
            <Loader />
          ) : (
            <table>
              <tbody>
                <tr className="mob-proj-list-title-wrapper">
                  <th className="mob-proj-list-title width-lg">PROJECT NAME</th>
                  <th className="mob-proj-list-title width-sm">MAN HOURS</th>
                </tr>
                {projectData &&
                  projectData.map((data, i) => {
                    return (
                      <tr
                        className="mob-proj-data-wrapper"
                        key={i}
                        onClick={() => handleProject(data._id, data.name)}
                      >
                        <td className="mob-proj-data">{data.name}</td>
                        <td className="mob-proj-data">{data.work.totalHrs}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileProject;
