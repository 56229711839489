import React, { useState, useEffect } from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

const withAuthentication = (Component) => {
  function WithAuthentication(props) {
    const [currentUser, setCurrentUser] = useState(null);
    const [claimsData, setClaimsData] = useState(null);

    useEffect(() => {
      const authListener = props.firebase.auth.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.
          props.firebase.getIdToken();

          setCurrentUser(user);
          props.firebase.getIdTokenResult().then((data) => {
            setClaimsData(data.claims);
          });
        } else {
          // User is signed out.
          setCurrentUser(null);
          setClaimsData(null);
        }
      });

      return () => authListener();
    }, [currentUser]);
    return (
      <AuthUserContext.Provider value={{ currentUser, claimsData }}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
